import React from "react";
import { useState } from "react";
import { AiFillSetting } from "react-icons/ai";
import { BiBell } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import VirtualCable from "./VirtualCable";

const Recurring = () => {
  const [screenLoading, setScreenLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("cable");
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [selectedStatus2, setSelectedStatus2] = useState("active");
  const [showEdit, setShowEdit] = useState(false);

  const handleChoice = (event, data) => {
    setSelectedOption(data);
  };
  const handleStatus = (event, data) => {
    setSelectedStatus(data);
  };
  const handleStatus2 = (event, data) => {
    setSelectedStatus2(data);
  };

  const handleEdit = () => {
    setShowEdit(!showEdit)
  }
  
  return (
    <>
      {screenLoading && <div className="loading"></div>}
      { showEdit && <VirtualCable onClose={() => setShowEdit(!showEdit)} />}

      <div className="md:p-8 px-2 ">
        <div className="flex justify-between mt-8 md:mt-0">
          <div>
            <h1 className=" mb-4  md:text-3xl text-2xl font-bold">
              Recurring Payment
            </h1>
          </div>

          <div className="flex md:space-x-6 space-x-1 md:mr-12 justify-center items-center">
            <div className="w-8 h-8  rounded-full flex justify-center items-center">
              <BiBell className="text-2xl hover:text-green-400" />
            </div>

            <div className="w-8 h-8  items-center justify-center flex rounded-full">
              <AiFillSetting className="text-2xl hover:text-green-400" />
            </div>
          </div>
        </div>

        <div className="flex mb-4 mt-8">
          <div className="justify-center items-center rounded-xl p-1 md:mr-8 mr-2 bg-green-700">
            <button
              onClick={(event) => handleChoice(event, "cable")}
              className={`py-2 md:px-4 px-1 mr-1 text-sm text-white font-semibold rounded-xl ${
                selectedOption === "cable"
                  ? "bg-white text-black"
                  : "bg-green-700"
              }`}
            >
              Cable
            </button>
            <button
              onClick={(event) => handleChoice(event, "energy")}
              className={`py-2 md:px-4 px-2 text-sm text-white font-semibold rounded-xl ${
                selectedOption === "energy"
                  ? "bg-white text-black"
                  : "bg-green-700"
              }`}
            >
              Energy
            </button>
          </div>
        </div>
        {selectedOption === "cable" && (
          <div>
            <div className="bg-white md:p-8 mb-12 p-3 rounded-lg">
              <h2 className="font-semibold">IUC Number: 2019716840</h2>
              <section className="">
                <div className="flex text-sm justify-between my-2 text-gray-500">
                  <div>
                    <p className="my-2 relative">
                      Package: DSTV Compact
                      <span onClick={handleEdit} className="cursor-pointer absolute right-6 top-[0] md:right-2">
                        <FaEdit className="text-xl hover:text-green-300 md:text-lg" />
                      </span>
                    </p>
                    <p className="my-2">Amount: ₦10,500 </p>
                    <p className="my-2">Created Date: 6th August, 2023</p>
                    <p className="my-2">Duration: Monthly</p>
                  </div>
                  <div className="text-sm">
                    <div className="justify-end flex">
                      <p className="flex items-center mb-2 text-black">
                        Account Number: 0534851054
                        <span>
                          <button className="bg-gray-200 text-gray-500 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>
                    <div className="justify-end flex">
                      <p className="flex items-center  mb-2">
                        Bank Name: Wema Bank
                        <span>
                          <button className="bg-gray-200 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>

                    <div className="justify-end flex">
                      <div className="w-[155px] rounded-xl bg-white shadow-md p-2">
                        <button
                          onClick={(event) => handleStatus(event, "active")}
                          className={`py-2 px-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus === "active"
                              ? "bg-green-700"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus === "disable" ? "Activate" : "Active"}
                        </button>
                        <button
                          onClick={(event) => handleStatus(event, "disable")}
                          className={`py-2 px-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus === "disable"
                              ? "bg-red-600"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus === "disable" ? "Disabled":"Disable"}
                        </button>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="bg-white md:p-8 mb-12 p-3 rounded-lg">
              <h2 className="font-semibold">IUC Number: 2019716840</h2>
              <section className="">
                <div className="flex text-sm justify-between my-2 text-gray-500">
                  <div>
                  <p className="my-2 relative">
                      Package: DSTV Compact
                      <span onClick={handleEdit} className="cursor-pointer absolute right-6 top-[0] md:right-2">
                        <FaEdit className="text-xl hover:text-green-300 md:text-lg" />
                      </span>
                    </p>
                    <p className="my-2">Amount: ₦10,500 </p>
                    <p className="my-2">Created Date: 6th August, 2023</p>
                    <p className="my-2">Duration: Monthly</p>
                  </div>
                  <div className="text-sm">
                    <div className="justify-end flex">
                      <p className="flex items-center mb-2 text-black">
                        Account Number: 0534851054
                        <span>
                          <button className="bg-gray-200 text-gray-600 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>
                    <div className="justify-end flex">
                      <p className="flex items-center  mb-2">
                        Bank Name: Wema Bank
                        <span>
                          <button className="bg-gray-200 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>

                    <div className="justify-end flex">
                      <div className="w-[155px] rounded-xl bg-white shadow-md p-2">
                        <button
                          onClick={(event) => handleStatus2(event, "active")}
                          className={`py-2 px-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus2 === "active"
                              ? "bg-green-700"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus2 === "disable" ? "Activate" : "Active"}
                        </button>
                        <button
                          onClick={(event) => handleStatus2(event, "disable")}
                          className={`py-2 px-1 mr-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus2 === "disable"
                              ? "bg-red-600"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus2 === "disable" ? "Disabled":"Disable"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="justify-center items-center flex">
              <Link to="/virtualaccounts">
                <button className="bg-green-700 hover:bg-green-600 py-4 px-32 my-6 text-white rounded-sm">
                  Add Account
                </button>
              </Link>
            </div>
          </div>
        )}

        {selectedOption === "energy" && (
          <div>
            <div className="bg-white md:p-8 mb-12 p-3 rounded-lg">
              <h2 className="font-semibold text-xl">
                Meter Number: 0256768990
              </h2>
              <section className="">
                <div className="flex text-sm justify-between my-2 text-gray-500">
                  <div>
                    <p className="my-2">Disco: Eko Disco</p>
                    <p className="my-2">Amount: ₦12,000 </p>
                    <p className="my-2">Created Date: 6th August, 2023</p>
                    <p className="my-2">Duration: Weekly</p>
                  </div>
                  <div className="text-sm">
                    <div className="justify-end flex">
                      <p className="flex items-center mb-2 text-black">
                        Account Number: 0534851054
                        <span>
                          <button className="bg-gray-200 text-gray-500 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>
                    <div className="justify-end flex">
                      <p className="flex items-center  mb-2">
                        Bank Name: Wema Bank
                        <span>
                          <button className="bg-gray-200 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>

                    <div className="justify-end flex">
                      <div className="w-[155px] rounded-xl bg-white shadow-md p-2">
                        <button
                          onClick={(event) => handleStatus(event, "active")}
                          className={`py-2 px-1 mr-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus === "active"
                              ? "bg-green-700"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus === "active" ? "Active":"Activate"}
                        </button>
                        <button
                          onClick={(event) => handleStatus(event, "disable")}
                          className={`py-2 px-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus === "disable"
                              ? "bg-red-600"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus === "disable" ? "Disabled":"Disable"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="bg-white md:p-8 mb-12 p-3 rounded-lg">
              <h2 className="font-semibold text-xl">
                Meter Number: 0256768990
              </h2>
              <section className="">
                <div className="flex text-sm justify-between my-2 text-gray-500">
                  <div>
                    <p className="my-2">Disco: Eko Disco</p>
                    <p className="my-2">Amount: ₦12,000 </p>
                    <p className="my-2">Created Date: 6th August, 2023</p>
                    <p className="my-2">Duration: Weekly</p>
                  </div>
                  <div className="text-sm">
                    <div className="justify-end flex">
                      <p className="flex items-center mb-2 text-black">
                        Account Number: 0534851054
                        <span>
                          <button className="bg-gray-200 text-gray-500 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>
                    <div className="justify-end flex">
                      <p className="flex items-center  mb-2">
                        Bank Name: Wema Bank
                        <span>
                          <button className="bg-gray-200 text-xs py-1 px-2 rounded-md ml-2 border-green-300">
                            copy
                          </button>
                        </span>
                      </p>
                    </div>

                    <div className="justify-end flex">
                      <div className="w-[155px] rounded-xl bg-white shadow-md p-2">
                        <button
                          onClick={(event) => handleStatus2(event, "active")}
                          className={`py-2 px-1 mr-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus2 === "active"
                              ? "bg-green-700"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus2 === "active" ? "Active":"Activate"}
                        </button>
                        <button
                          onClick={(event) => handleStatus2(event, "disable")}
                          className={`py-2 px-1 text-sm text-white font-semibold rounded-lg ${
                            selectedStatus2 === "disable"
                              ? "bg-red-600"
                              : "bg-white text-black"
                          }`}
                        >
                          {selectedStatus2 === "disable" ? "Disabled":"Disable"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="justify-center items-center flex">
              <button className="bg-green-700 hover:bg-green-600 py-4 px-32 my-6 text-white rounded-sm">
                Add Account
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Recurring;
