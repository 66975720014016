import React, { useState } from "react";
import { AiFillCaretDown, AiFillSetting, AiOutlineCalendar } from "react-icons/ai";
import { BiBell } from "react-icons/bi";
import { VscSearch } from "react-icons/vsc";
import mtn from "../assets/mtn.svg";
import airtel from "../assets/airtel.svg";
import glo from "../assets/glo.svg";
import etisalat from "../assets/9mobile.svg";
import ReviewCard from "./ReviewCard";
import TransactionCard from "./TransactionCard";
import { Link } from "react-router-dom";
import { IoAlert } from "react-icons/io5";
import { TiArrowSortedUp } from "react-icons/ti";
import DropBtn from "./DropBtn";

const AirtimeData = ({ darkMode }) => {
  const [selectedTransaction, setSelectedTransaction] = useState("airtime");
  const [selectedData, setSelectedData] = useState("hotdeals");

  const [selectedAmount, setSelectedAmount] = useState("");
  const [dataChoice, setDataChoice] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [product, setProduct] = useState("");
  const [showReview, setShowReview] = useState(false);
  const [dataReview, setDataReview] = useState(false);

  const [mtnAmount, setMtnAmount] = useState("");
  const [airtelAmount, setAirtelAmount] = useState("");
  const [gloAmount, setGloAmount] = useState("");
  const [etisalatAmount, setEtisalatAmount] = useState("");

  const [screenLoading, setScreenLoading] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);

  const [newNotification, setNewNotification] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [warning, setWarning] = useState(true);


  const payment = () => {
    if(phoneNumber){
      setShowReview(false);
      setScreenLoading(true);
      setTimeout(() => {
        setScreenLoading(false);
      }, 3000);
      setShowTransaction(true);
    }else{
      setWarning(true)
    }
   
  };

  const handleSelectedTransaction = (event, choice) => {
    setSelectedTransaction(choice);
  };

  const handleSelectedData = (event, choice) => {
    setSelectedData(choice);
  };

  const handleMtnClick = () => {
    const inputElement = document.getElementById("amountInputMtn");
    setSelectedAmount(inputElement.value);
    setProduct("Mtn Airtime");
    setShowReview(true);
  };
  const handleAirtelClick = () => {
    const inputElement = document.getElementById("amountInputAirtel");
    setSelectedAmount(inputElement.value);
    setProduct("Airtel Airtime");
    setShowReview(true);
  };
  const handleGloClick = () => {
    const inputElement = document.getElementById("amountInputGlo");
    setSelectedAmount(inputElement.value);
    setProduct("Glo Airtime");
    setShowReview(true);
  };
  const handleEtisalatClick = () => {
    const inputElement = document.getElementById("amountInputEtisalat");
    setSelectedAmount(inputElement.value);
    setProduct("9Mobile Airtime");
    setShowReview(true);
  };

  const handleAmountSelect = (amount) => {
    setSelectedAmount(amount);
    setShowReview(true);
  };

  const handleDataChoice = (amount) => {
    setDataChoice(amount);
    setDataReview(true);
  };

  const toggleNewNotification = () => {
    setNewNotification(!newNotification);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };



  return (
    <>
      {screenLoading && <div className="loading"></div>}

      <div className="md:p-8 px-2">
        <div className="flex  justify-between items-center mb-6 pb-2">
        <div className="flex mt-6 md:mt-0 flex-col md:flex-row md:items-center">
            <h1 className="md:mr-20 mx-2 mb-4  md:text-3xl text-2xl font-bold">
              Airtime & Data
            </h1>
            <div className="flex mb-4 md:mb-0 md:mr-8">
              <div className="relative mr-8">
                <input
                  type="text"
                  placeholder="Search..."
                  className="md:p-3 p-2 rounded-full md:w-96 pr-10"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <VscSearch className="font-bold text-xl" />
                </div>
              </div>

              <div className="flex md:space-x-6 space-x-1 md:mr-12 justify-center items-center">
                <button
                  onClick={toggleNewNotification}
                  type="button"
                  class={`relative flex items-center justify-center w-12 h-12 text-gray-700 rounded-full icon-button ${
                    newNotification === true ? "bg-gray-200" : ""
                  }`}
                >
                  <span class="material-icons">
                    <BiBell className="text-2xl hover:text-green-400 hover:text-3xl" />
                  </span>
                  <span class="absolute top-0 right-0 w-6 h-6 bg-green-500 text-white flex justify-center items-center rounded-full icon-button__badge">
                    2
                  </span>
                </button>

                <Link to="/settings">
                  <button
                    type="button"
                    class="relative flex items-center justify-center w-12 h-12 text-gray-700 rounded-full icon-button"
                  >
                    <span class="material-icons">
                      <AiFillSetting className="text-2xl hover:text-green-400" />
                    </span>
                    <span class="absolute top-0 right-0 w-6 h-6 bg-gray-500 text-white flex justify-center items-center rounded-full icon-button__badge">
                      <IoAlert />
                    </span>
                  </button>
                </Link>
              </div>
            </div>

            <div>
              <button
                className="bg-green-700 hover:bg-green-500 text-white text-sm px-6 py-2 rounded-lg relative flex"
                onClick={handleDropdownToggle}
              >
                Virtual Account
                {isDropdownOpen ? (
                  <TiArrowSortedUp className="ml-2 mt-1" />
                ) : (
                  <AiFillCaretDown className="ml-2 mt-1" />
                )}
              </button>
              {isDropdownOpen && <DropBtn />}
            </div>
          </div>
        </div>

        <div className="flex mb-4">
          <div className="justify-center items-center rounded-xl p-1 md:mr-8 mr-2 bg-green-700">
            <button
              onClick={(event) => handleSelectedTransaction(event, "airtime")}
              className={`py-2 md:px-4 px-1 mr-1 text-sm text-white font-semibold rounded-xl ${
                selectedTransaction === "airtime"
                  ? "bg-white text-black"
                  : "bg-green-700"
              }`}
            >
              Airtime
            </button>
            <button
              onClick={(event) => handleSelectedTransaction(event, "data")}
              className={`py-2 md:px-6 px-2 text-sm text-white font-semibold rounded-xl ${
                selectedTransaction === "data"
                  ? "bg-white text-black"
                  : "bg-green-700"
              }`}
            >
              Data
            </button>
          </div>

          <div className="flex items-center border-b border-gray-300 pb-2">
            <input
              type="number"
              id="phoneNumber"
              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-2 py-1 px-2 leading-tight focus:outline-none"
              placeholder="08000000000"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500 cursor-pointer"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M15.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-10 10a2 2 0 0 1-1.117.561l-5 1a1 1 0 0 1-1.247-1.247l1-5a2 2 0 0 1 .561-1.117l10-10zM16 2L3.414 14.586 3 17l2-1 .293-.293L16 3.414V2z"
              />
            </svg>
          </div>
        </div>

        {selectedTransaction === "airtime" && (
          <div
            className={`w-[370px] overflow-y-auto max-h-[600px] md:max-h-screen  md:w-[95%] p-4 pb-12 shadow-md rounded-3xl ${
              darkMode ? "bg-[#212130] text-white" : "bg-white"
            }`}
          >
            {showReview && (
              <ReviewCard
                selectedAmount={selectedAmount}
                phoneNumber={phoneNumber}
                product={product}
                onClose={() => setShowReview(false)}
                payment={payment}
              />
            )}
            {showTransaction && (
              <TransactionCard
                selectedAmount={selectedAmount}
                phoneNumber={phoneNumber}
                product={product}
                onClose={() => setShowReview(false)}
                payment={payment}
              />
            )}
            <div className="flex mb-4 pt-2">
              <img src={mtn} alt="mtn" className="mb-2 md:ml-8 md:mr-16 h-24" />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-4 py-2 md:mr-8 flex flex-col items-center">
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("50")}
                    >
                      ₦50
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("100")}
                    >
                      ₦100
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("150")}
                    >
                      ₦150
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("200")}
                    >
                      ₦200
                    </button>
                  </div>
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("500")}
                    >
                      ₦500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1000")}
                    >
                      ₦1000
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1500")}
                    >
                      ₦1500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("2000")}
                    >
                      ₦2000
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <div className="mb-2 border-b border-gray-300 pb-2 mt-4">
                    <input
                      type="number"
                      id="amountInputMtn"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-2 py-1 px-2 leading-tight focus:outline-none"
                      placeholder="₦ input amount"
                      value={mtnAmount}
                      onChange={(e) => setMtnAmount(e.target.value)}
                    />
                  </div>
                  <button
                    className="bg-green-700 px-8 py-2 rounded my-1 text-xl text-white font-semibold"
                    onClick={() => handleMtnClick()}
                  >
                    PAY
                  </button>
                </div>
              </div>
            </div>

            <div className="flex mb-4 pt-2">
              <img
                src={airtel}
                alt="mtn"
                className="mb-2 md:ml-8 md:mr-16 h-24"
              />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-4 py-2 md:mr-8 flex flex-col items-center">
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("50")}
                    >
                      ₦50
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("100")}
                    >
                      ₦100
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("150")}
                    >
                      ₦150
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("200")}
                    >
                      ₦200
                    </button>
                  </div>
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("500")}
                    >
                      ₦500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1000")}
                    >
                      ₦1000
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1500")}
                    >
                      ₦1500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("2000")}
                    >
                      ₦2000
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <div className="mb-2 border-b border-gray-300 pb-2 mt-4">
                    <input
                      type="number"
                      id="amountInputAirtel"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-2 py-1 px-2 leading-tight focus:outline-none"
                      placeholder="₦ input amount"
                      value={airtelAmount}
                      onChange={(e) => setAirtelAmount(e.target.value)}
                    />
                  </div>
                  <button
                    className="bg-green-700 px-8 py-2 rounded my-1 text-xl text-white font-semibold"
                    onClick={() => handleAirtelClick()}
                  >
                    PAY
                  </button>
                </div>
              </div>
            </div>

            <div className="flex mb-4 pt-2">
              <img src={glo} alt="mtn" className="mb-2 md:ml-8 md:mr-16 h-24" />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-4 py-2 md:mr-8 flex flex-col items-center">
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("50")}
                    >
                      ₦50
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("100")}
                    >
                      ₦100
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("150")}
                    >
                      ₦150
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("200")}
                    >
                      ₦200
                    </button>
                  </div>
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("500")}
                    >
                      ₦500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1000")}
                    >
                      ₦1000
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1500")}
                    >
                      ₦1500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("2000")}
                    >
                      ₦2000
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <div className="mb-2 border-b border-gray-300 pb-2 mt-4">
                    <input
                      type="number"
                      id="amountInputGlo"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-2 py-1 px-2 leading-tight focus:outline-none"
                      placeholder="₦ input amount"
                      value={gloAmount}
                      onChange={(e) => setGloAmount(e.target.value)}
                    />
                  </div>
                  <button
                    className="bg-green-700 px-8 py-2 rounded my-1 text-xl text-white font-semibold"
                    onClick={(gloAmount) => handleGloClick(gloAmount)}
                  >
                    PAY
                  </button>
                </div>
              </div>
            </div>

            <div className="flex mb-4 pt-2">
              <img
                src={etisalat}
                alt="mtn"
                className="mb-2 md:ml-8 md:mr-16 h-24"
              />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-4 py-2 md:mr-8 flex flex-col items-center">
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("50")}
                    >
                      ₦50
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("100")}
                    >
                      ₦100
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("150")}
                    >
                      ₦150
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("200")}
                    >
                      ₦200
                    </button>
                  </div>
                  <div className="button-row flex">
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("500")}
                    >
                      ₦500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1000")}
                    >
                      ₦1000
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("1500")}
                    >
                      ₦1500
                    </button>
                    <button
                      className="bg-gray-200 py-3 font-semibold w-[80px] mx-6 rounded-lg my-1 flex items-center justify-center"
                      onClick={() => handleAmountSelect("2000")}
                    >
                      ₦2000
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <div className="mb-2 border-b border-gray-300 pb-2 mt-4">
                    <input
                      type="number"
                      id="amountInputEtisalat"
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-2 py-1 px-2 leading-tight focus:outline-none"
                      placeholder="₦ input amount"
                      value={etisalatAmount}
                      onChange={(e) => setEtisalatAmount(e.target.value)}
                    />
                  </div>
                  <button
                    className="bg-green-700 px-8 py-2 rounded my-1 text-xl text-white font-semibold"
                    onClick={(etisalatAmount) =>
                      handleEtisalatClick(etisalatAmount)
                    }
                  >
                    PAY
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedTransaction === "data" && (
          <div
            className={`w-[370px] overflow-y-auto max-h-[600px] md:max-h-screen  md:w-[1100px] p-4 pb-12 shadow-md rounded-3xl ${
              darkMode ? "bg-[#212130] text-white" : "bg-white"
            }`}
          >
            {dataReview && (
              <ReviewCard
              warning={warning}
                selectedAmount={dataChoice}
                phoneNumber={phoneNumber}
                product={product}
                onClose={() => setDataReview(false)}
                payment={payment}
              />
            )}
            {showTransaction && (
              <TransactionCard
                selectedAmount={dataChoice}
                phoneNumber={phoneNumber}
                product={product}
                onClose={() => setShowReview(false)}
                payment={payment}
              />
            )}
            <div className="flex justify-end mb-1">
              <div className="border b-gray-200 px-2 py-1 shadow  justify-center items-center rounded-xl md:mr-24 bg-white overflow-scroll">
                <button
                  onClick={(event) => handleSelectedData(event, "hotdeals")}
                  className={`py-2 md:px-4 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white  font-semibold rounded-xl ${
                    selectedData === "hotdeals"
                      ? "bg-green-700 text-white"
                      : "bg-white "
                  }`}
                >
                  Hotdeals
                </button>
                <button
                  onClick={(event) => handleSelectedData(event, "daily")}
                  className={`py-2 md:px-6 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white font-semibold rounded-xl ${
                    selectedData === "daily"
                      ? "bg-green-700 text-white "
                      : "bg-white"
                  }`}
                >
                  Daily
                </button>
                <button
                  onClick={(event) => handleSelectedData(event, "weekly")}
                  className={`py-2 md:px-4 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white  font-semibold rounded-xl ${
                    selectedData === "weekly"
                      ? "bg-green-700 text-white"
                      : "bg-white "
                  }`}
                >
                  Weekly
                </button>
                <button
                  onClick={(event) => handleSelectedData(event, "monthly")}
                  className={`py-2 md:px-4 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white  font-semibold rounded-xl ${
                    selectedData === "monthly"
                      ? "bg-green-700 text-white"
                      : "bg-white "
                  }`}
                >
                  Monthly
                </button>
                <button
                  onClick={(event) => handleSelectedData(event, "2months")}
                  className={`py-2 md:px-4 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white  font-semibold rounded-xl ${
                    selectedData === "2months"
                      ? "bg-green-700 text-white"
                      : "bg-white "
                  }`}
                >
                  2-Months
                </button>
                <button
                  onClick={(event) => handleSelectedData(event, "3months")}
                  className={`py-2 md:px-4 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white  font-semibold rounded-xl ${
                    selectedData === "3months"
                      ? "bg-green-700 text-white"
                      : "bg-white "
                  }`}
                >
                  3-Months
                </button>
                <button
                  onClick={(event) => handleSelectedData(event, "6months")}
                  className={`py-2 md:px-4 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white  font-semibold rounded-xl ${
                    selectedData === "6months"
                      ? "bg-green-700 text-white"
                      : "bg-white "
                  }`}
                >
                  6-Months
                </button>
                <button
                  onClick={(event) => handleSelectedData(event, "yearly")}
                  className={`py-2 md:px-4 px-2 mr-2 text-xs hover:bg-green-600 hover:text-white  font-semibold rounded-xl ${
                    selectedData === "yearly"
                      ? "bg-green-700 text-white"
                      : "bg-white "
                  }`}
                >
                  Yearly
                </button>
              </div>
            </div>

            <div className="flex mb-4 pt-2">
              <img src={mtn} alt="mtn" className="mb-2 md:ml-8 md:mr-16 h-24" />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-2 py-1 md:mr-8 flex flex-col items-center">
                  <div className="button-row flex">
                    <button
                      onClick={() => handleDataChoice("100")}
                      className="bg-gray-200 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">100 MB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦100</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("200")}
                      className="bg-gray-200 p-2  py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">200 MB</p>
                      <p className="mb-1 text-xs">3 days</p>
                      <p className="mb-1 text-sm">₦200</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("300")}
                      className="bg-gray-200 p-2  py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">1 GB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦300</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("500")}
                      className="bg-gray-200 p-2  py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">2.5 GB</p>
                      <p className="mb-1 text-xs">2 days</p>
                      <p className="mb-1 text-sm">₦500</p>
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <button className="text-black px-6 py-1 rounded my-1 text-white font-semibold">
                    See more
                  </button>
                </div>
              </div>
            </div>

            <div className="flex mb-4 pt-2">
              <img
                src={airtel}
                alt="mtn"
                className="mb-2 md:ml-8 md:mr-16 h-24"
              />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-2 py-1 md:mr-8 flex flex-col items-center">
                <div className="button-row flex">
                    <button
                      onClick={() => handleDataChoice("100")}
                      className="bg-gray-200 p-2 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">100 MB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦100</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("200")}
                      className="bg-gray-200 p-2 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">200 MB</p>
                      <p className="mb-1 text-xs">3 days</p>
                      <p className="mb-1 text-sm">₦200</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("300")}
                      className="bg-gray-200 p-2 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">1 GB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦300</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("500")}
                      className="bg-gray-200 p-2 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">2.5 GB</p>
                      <p className="mb-1 text-xs">2 days</p>
                      <p className="mb-1 text-sm">₦500</p>
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <button className="text-black px-6 py-1 rounded my-1 text-white font-semibold">
                    See more
                  </button>
                </div>
              </div>
            </div>

            <div className="flex mb-4 pt-2">
              <img src={glo} alt="mtn" className="mb-2 md:ml-8 md:mr-16 h-24" />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-2 py-1 md:mr-8 flex flex-col items-center">
                <div className="button-row flex">
                    <button
                      onClick={() => handleDataChoice("100")}
                      className="bg-gray-200 p-2  py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">100 MB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦100</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("200")}
                      className="bg-gray-200 p-2  py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">200 MB</p>
                      <p className="mb-1 text-xs">3 days</p>
                      <p className="mb-1 text-sm">₦200</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("300")}
                      className="bg-gray-200 p-2  py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">1 GB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦300</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("500")}
                      className="bg-gray-200 p-2  py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">2.5 GB</p>
                      <p className="mb-1 text-xs">2 days</p>
                      <p className="mb-1 text-sm">₦500</p>
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <button className="text-black px-6 py-1 rounded my-1 text-white font-semibold">
                    See more
                  </button>
                </div>
              </div>
            </div>

            <div className="flex mb-4 pt-2">
              <img
                src={etisalat}
                alt="mtn"
                className="mb-2 md:ml-8 md:mr-16 h-24"
              />
              <div className="border b-gray-100 rounded-lg  flex justify-between">
                <div className="md:px-2 py-1 md:mr-8 flex flex-col items-center">
                <div className="button-row flex">
                    <button
                      onClick={() => handleDataChoice("100")}
                      className="bg-gray-200 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">100 MB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦100</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("200")}
                      className="bg-gray-200 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">200 MB</p>
                      <p className="mb-1 text-xs">3 days</p>
                      <p className="mb-1 text-sm">₦200</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("300")}
                      className="bg-gray-200 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">1 GB</p>
                      <p className="mb-1 text-xs">1 day</p>
                      <p className="mb-1 text-sm">₦300</p>
                    </button>
                    <button
                      onClick={() => handleDataChoice("500")}
                      className="bg-gray-200 p-2 py-3 w-[95px] font-semibold my-1 mx-6 rounded-lg text-xl flex flex-col items-center justify-center"
                    >
                      <p className="mb-1 text-lg">2.5 GB</p>
                      <p className="mb-1 text-xs">2 days</p>
                      <p className="mb-1 text-sm">₦500</p>
                    </button>
                  </div>
                </div>

                <div className="mx-4 py-2 flex flex-col justify-center items-center">
                  <button className="text-black px-6 py-1 rounded my-1 text-white font-semibold">
                    See more
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AirtimeData;
