import React from "react";
import CelebrateSvg from "./CelebrateSvg";
import { Link } from "react-router-dom";

const VrSuccess = ({ darkMode, handleShare }) => {
  return (
    <div
      className={`md:px-4 shadow-md rounded-3xl ${
        darkMode ? "bg-[#212130] text-white" : "bg-white"
      }`}
    >
      <div className="flex flex-col md:flex-row p-4">
        <div className="flex flex-col ">
          <p className="text-xl font-semibold mt-4">
            Your Virtual Account Has Been Created!🎉
          </p>

          <div className="p-6 md:px-8 md:mr-24 mt-4 text-sm bg-gray-100 md:w-[400px] rounded-lg">
            <div>
              <p>
                Money Transfers sent to this bank account number will
                automatically top up your Energy unit.
              </p>
              <hr class="h-px my-4 bg-gray-300 border-0 dark:bg-gray-700"></hr>
            </div>

            <div>
              <div className="flex justify-between text-sm">
                <div>
                  <p className="font-semibold">Account Number</p>
                  <p className="text-xs">0534851054</p>
                </div>
                <button className="bg-white text-gray-500 text-xs px-4 py-1 my-2 border-gray-200 rounded-md">
                  Copy
                </button>
              </div>

              <hr class="h-px my-4 bg-gray-300 border-0 dark:bg-gray-700"></hr>
            </div>

            <div>
              <div className="flex justify-between text-sm">
                <div>
                  <p className="font-semibold">Bank Name</p>
                  <p className="text-xs">Wema Bank</p>
                </div>
                <button className="bg-white text-gray-500 text-xs px-4 py-1 my-2 border-gray-200 rounded-md">
                  Copy
                </button>
              </div>

              <hr class="h-px my-4 bg-gray-300 border-0 dark:bg-gray-700"></hr>
            </div>

            <div className=" flex justify-center">
              <button
                onClick={handleShare}
                className="bg-green-700 py-2 px-6 text-center text-white rounded-lg"
              >
                Share Details
              </button>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          <CelebrateSvg />
        </div>
      </div>
      <div className="flex justify-center pb-6">
        <Link to='/dash'>
        <button className="bg-green-700 py-2 px-2 w-[200px] md:w-[400px] mx-auto text-center text-white rounded-lg">
          Dashboard
        </button>
        </Link>
      
      </div>
    </div>
  );
};

export default VrSuccess;
