import Electricity from "./Electricity";
import Sidenav from "../../components/Sidenav";
import Nav from "../../components/Nav";

const Layout = () => (
  <>
    <Nav />

    <div className="sm:flex">
      <div className="hidden sm:w-1/5 md:block sm:mr-2">
        <Sidenav />
      </div>
      <div className="sm:w-4/5 mx-auto px-2 sm:mr-2">
        <Electricity />
      </div>
    </div>
    {/* <div className=" md:hidden h-screen flex justify-center items-center -mt-10 px-4">
          <Electricity />
    </div> */}
  </>
);

export default Layout;
