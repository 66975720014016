import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { InputField, SelectField } from "../../components/ui/input";
import {
  handleChange,
  handleNumberChange,
  handleKeyPress,
  ensureIsArray,
  handle400,
  handle422,
  handle500,
} from "../../lib/util";

const baseURL = process.env.REACT_APP_BASE_URL;

const serviceUrl = `${baseURL}/services?type=Energy`;
const lookUpUrl = `${baseURL}/lookup`;
const verifyRequestUrl = `${baseURL}/bills-payment/energy/verify`;
const vendUrl = `${baseURL}/bills-payment/energy/vend`;

const Electricity = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formStep, setFormStep] = useState(0);

  const [state, setState] = useState({
    providers: [],
    meters: [],
  });

  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    meterNumber: "",
    meterType: "",
    disco: "",
    discoName: "",
    amount: "",
    phoneNumber: "",
    email: "",
    parsedAmount: "",
  });

  const [customerInformation, setCustomerInformation] = useState({
    customerName: "",
    customerAddress: "",
  });

  const validate = () => {
    let errors = {};
  
    if (!formData.meterNumber) {
      errors.meterNumber = "Meter number is required";
    }
  
    if (!formData.meterType) {
      errors.meterType = "Meter type is required";
    }
  
    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\d{11}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be 11 digits";
    }
  
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
  
    if (!formData.disco) {
      errors.disco = "DisCo is required";
    }
  
    if (!formData.amount) {
      errors.amount = "Amount is required";
    } else if (formData.amount < 500) {
        errors.amount = "Amount must be at least 500";
      }     
  
    return errors;
  };
  

  const back = () => {
    setFormStep((currentStep) => currentStep - 1);
  };

  function resetProductTree() {
    setState((state) => {
      return { ...state, products: [], pricingOptions: [] };
    });
  }

  useEffect(() => {
    axios
      .get(serviceUrl)
      .then((response) => {
        const providersData = response.data.data;
        setState((state) => {
          return { ...state, providers: ensureIsArray(providersData) };
        });
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(lookUpUrl)
      .then((response) => {
        const meterData = response.data.data;
        const meterType = meterData.meter_types;
        setState((state) => {
          return { ...state, meters: ensureIsArray(meterType) };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleServiceChange = (e) => {
    setErrors({});
    const serviceId = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const serviceName = selectedOption.getAttribute("data-provider-name");
    resetProductTree();
    setFormData((prevData) => ({
      ...prevData,
      disco: serviceId,
      discoName: serviceName,
    }));
  };

  const handleMeterChange = (e) => {
    setErrors({});
    const service = e.target.value;
    resetProductTree();
    setFormData((prevData) => ({
      ...prevData,
      meterType: service,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    const errors = validate();

    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      axios
        .post(verifyRequestUrl, {
          service_id: formData.disco,
          meter_number: formData.meterNumber,
          meter_type: formData.meterType,
          amount: formData.amount,
        })
        .then((response) => {
          const customerData = response.data;
          const customerInformation = customerData.data.customer_information;
          const customerName = customerInformation.name;
          const customerAddress = customerInformation.address;

          setCustomerInformation({
            ...customerInformation,
            customerName: customerName,
            customerAddress: customerAddress,
          });
          setFormStep(1);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          handle422(error, { formStep, back });
          handle400(error, { formStep, back });
          handle500(error, { formStep, back });
        });
    } else {
      setErrors(errors);
    }
  };

  const submitToBackend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const serviceId = formData.disco;
    axios
      .post(vendUrl, {
        service_id: serviceId,
        meter_number: formData.meterNumber,
        meter_type: formData.meterType,
        amount: formData.parsedAmount,
        phone_number: formData.phoneNumber,
        email: formData.email,
      })
      .then((response) => {
        const redirectUrl = response.data.data.url;
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        handle422(error, { formStep, back });
        handle400(error, { formStep, back });
        handle500(error, { formStep, back });
      })
      .finally(() => {
        setIsLoading(false);
      });
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "buy energy",
    });
  };

  const renderButton = () => {
    if (formStep === 0) {
      return (
        <div className="flex flex-col items-center">
          <button
            disabled={isLoading}
            type="submit"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            {isLoading ? <FontAwesomeIcon icon={faSpinner} spin /> : `Submit`}
          </button>
        </div>
      );
    } else if (formStep === 1) {
      return (
        <div className="flex flex-col items-center">
          <button
            disabled={isLoading}
            onClick={submitToBackend}
            type="button"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              `Pay   ₦ ${formData.amount}`
            )}
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full sm:w-4/5 md:4/5 lg:w-2/5 mx-auto">
        <div className="w-[25rem] flex justify-around mt-8 mx-auto">
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "green" : formStep === 1 ? "green" : "green",
            }}
            onClick={() => setFormStep(0)}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "green" : "green",
            }}
            onClick={onSubmit}
            disabled={formStep !== 0}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "gray" : "green",
            }}
          ></button>
        </div>

        <div className="w-[25rem] flex justify-around mx-auto">
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Enter Information
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Make Payment
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            View Receipt
          </p>
        </div>
        <div className="w-[25rem] mx-auto px-16 mb-16 -mt-9">
          <div
            className="w-full h-[1px] bg-green-500"
            style={{
              width: formStep === 0 ? "0%" : formStep === 1 ? "50%" : "100%",
            }}
          ></div>
          <div className="w-full h-[1px] bg-gray-200"></div>
        </div>

        <div className="max-w-xl mt-4 mb-4 rounded-lg bg-white overflow-hidden z-10">
          <form onSubmit={onSubmit}>
            {formStep === 0 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Buy Electricity
                </h2>
                <p className="text-black font-semibold text-sm text-center mb-6 mt-2">
                  Want to pay electricity bills? You can buy power units easily
                  here
                </p>
                <div>
                  <SelectField
                    label="Select DisCo"
                    name="disco"
                    id="disco"
                    onChange={handleServiceChange}
                    value={formData.disco}
                  >
                    <option disabled value="">
                      Select DisCo
                    </option>
                    {state.providers.map((provider) => (
                      <option
                        key={provider.id}
                        data-provider-name={provider.description}
                        value={provider.id}
                      >
                        {provider.description}
                      </option>
                    ))}
                  </SelectField>
                  {errors.disco && (
                    <span className="text-red-500 text-sm">{errors.disco}</span>
                  )}
                </div>

                <div className="flex gap-2">
                  <div className=" w-1/2">
                    <InputField
                      label="Meter Number"
                      type="text"
                      id="meterNumber"
                      name="meterNumber"
                      onChange={(e) =>
                        handleNumberChange(e, setErrors, setFormData)
                      }
                      value={formData.meterNumber}
                    />
                    {errors.meterNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.meterNumber}
                      </span>
                    )}
                  </div>
                  <div className=" w-1/2">
                    <SelectField
                      label="Meter Type"
                      id="meterType"
                      name="meterType"
                      onChange={handleMeterChange}
                      value={formData.meterType}
                      placeholder="Select meter type"
                      size="1"
                    >
                      <option disabled value="">
                        Select meter
                      </option>
                      {state.meters.map((meter) => (
                        <option key={meter} value={meter}>
                          {meter}
                        </option>
                      ))}
                    </SelectField>
                    {errors.meterType && (
                      <span className="text-red-500 text-sm">
                        {errors.meterType}
                      </span>
                    )}
                  </div>
                </div>

                <div className="flex gap-2">
                  <div className=" w-1/2">
                    <InputField
                      label="Amount"
                      type="text"
                      id="amount"
                      className="w-full outline-none text-xs"
                      name="amount"
                      onKeyUp={(e) => handleKeyPress(e, setFormData)}
                      onChange={(e) => handleChange(e, setErrors, setFormData)}
                      value={formData.amount}
                      autoComplete="off"
                    />
                    {errors.amount && (
                      <span className="text-red-500 text-sm">
                        {errors.amount}
                      </span>
                    )}
                  </div>
                  <div className=" w-1/2">
                    <InputField
                      label="Phone Number"
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      placeholder="08000000000"
                      onChange={(e) =>
                        handleNumberChange(e, setErrors, setFormData)
                      }
                      value={formData.phoneNumber}
                      maxLength={11}
                    />
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <InputField
                    label="Email Address"
                    placeholder="Email Address"
                    type="email"
                    id="email"
                    name="email"
                    onChange={(e) => handleChange(e, setErrors, setFormData)}
                    value={formData.email}
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">{errors.email}</span>
                  )}
                </div>
              </section>
            )}

            {formStep === 1 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Review Information
                </h2>
                <p className="text-black font-semibold text-sm text-center mt-2">
                  Review information and make payment
                </p>
                <div className="rounded mx-auto py-1 px-8 w-full h-full flex items-center align-items-center">
                  <div className="border mx-auto border-green-400 rounded-xl shadow-md px-24 py-6">
                    <div className="text-center">
                      <p className="font-bold text-xs mt-2"> NAME:</p>
                      <p className="text-xs">
                        {customerInformation.customerName}
                      </p>
                      <p className="font-bold text-xs mt-2">METER NUMBER:</p>
                      <p className="text-xs">{formData.meterNumber}</p>
                      <p className="font-bold text-xs mt-2">DISCO:</p>
                      <p className="text-xs">{formData.discoName}</p>
                      <p className="font-bold text-xs mt-2">ADDRESS:</p>
                      <p className="text-xs">
                        {customerInformation.customerAddress}
                      </p>
                      <p className="font-bold text-xs mt-2">AMOUNT:</p>
                      <p className="text-xs">₦ {formData.amount}</p>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {renderButton()}
          </form>
        </div>
      </div>
    </>
  );
};

export default Electricity;
