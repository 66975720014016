import React from "react";
import Sidenav from "../../components/Sidenav";
import BuyAirtime from "./BuyAirtime";
import Nav from "../../components/Nav";

const Airtime = () => (
  <>
  <Nav />
    <div className="sm:flex">
    <div className="hidden sm:w-1/5 md:block sm:mr-2">
    <Sidenav />
      </div>
      <div className="sm:w-4/5 mx-auto px-2 sm:mr-2">
        <BuyAirtime />
      </div>
    </div>
  </>
);

export default Airtime;
