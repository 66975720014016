import React from "react";
import { useState } from "react";
import BillReviewCard from "../components/BillReviewCard";
import TransactionCard from "../components/TransactionCard";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { AiOutlineCaretDown } from "react-icons/ai";
import { Link } from "react-router-dom";

const NewExams = ({ darkMode }) => {
  const [provider, setProvider] = useState("");
  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");

  const [examsReview, setExamsReview] = useState(false);
  const [examsTransactionReview, setExamsTransactionReview] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  const handleProviderChange = (event) => {
    setProvider(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setExamsReview(true);
    const formData = {
      provider,
      price,
      quantity,
      amount,
      phoneNumber,
      email,
    };
    console.log(formData);
  };

  const makePayment = () => {
    setExamsReview(false);
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
    setExamsTransactionReview(true);
  };

  return (
    <div>
      {screenLoading && <div className="loading"></div>}

      {examsReview && (
        <BillReviewCard
          selectedAmount={amount}
          phoneNumber={phoneNumber}
          email={email}
          provider={provider}
          price={price}
          quantity={quantity}
          buttonAction={makePayment}
          backAction={() => setExamsReview(false)}
        />
      )}
      {examsTransactionReview && (
        <TransactionCard
          selectedAmount={amount}
          phoneNumber={phoneNumber}
          email={email}
          provider={provider}
          price={price}
          quantity={quantity}
          onClose={() => setExamsTransactionReview(false)}
        />
      )}
      <div className="text-gray-600 mb-6 my-12 md:mx-16">
      <Link to="/paybills">
          <IoArrowBackCircleOutline className="text-5xl text-green-400 mb-6 cursor-pointer" />
        </Link>      </div>
      <div className="flex-1 pt-4 py-6 md:px-[3rem] mx-2">
        <div
          className={`p-4 md:px-16 shadow-md rounded-3xl ${
            darkMode ? "bg-[#212130] text-white" : "bg-gray-50"
          }`}
        >
          <form>
            <section className="md:mx-12 md:p-12">
              <div className="mb-1">
                <label
                  className="block text-gray-400 text-xs"
                  htmlFor="provider"
                >
                  Select Provider
                </label>
                <div className="flex items-center border border-black p-2 rounded-lg">
                  <input
                    type="text"
                    id="provider"
                    name="provider"
                    className="w-full outline-none text-xs py-1"
                    value={provider}
                    onChange={handleProviderChange}
                  />
                  <span className="mr-2 text-xs p-1">
                    <AiOutlineCaretDown />
                  </span>
                </div>
              </div>

              <div className="flex">
                <div className="mb-2 w-1/2 mr-4">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="price"
                  >
                    Price
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="number"
                      id="price"
                      className="w-full outline-none text-xs py-1"
                      name="price"
                      value={price}
                      onChange={handlePriceChange}
                    />
                  </div>
                </div>

                <div className="mb-2 w-1/2 ">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="quantity"
                  >
                    Quantity
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="number"
                      id="quantity"
                      className="w-full outline-none text-xs py-1"
                      name="quantity"
                      value={quantity}
                      onChange={handleQuantityChange}
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="mb-2 w-1/2 mr-4">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="amount"
                  >
                    Amount
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="number"
                      id="amount"
                      className="w-full outline-none text-xs py-1"
                      name="amount"
                      value={amount}
                      onChange={handleAmountChange}
                    />
                 
                  </div>
                </div>

                <div className="mb-2 w-1/2 ">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="phoneNumber"
                  >
                    Phone Number
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="number"
                      id="phoneNumber"
                      className="w-full outline-none text-xs py-1"
                      name="phoneNumber"
                      placeholder="080000000000"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-1">
                <label className="block text-gray-400 text-xs" htmlFor="email">
                  Email Address(optional)
                </label>
                <input
                  placeholder="Email Address"
                  type="email"
                  id="email"
                  name="email"
                  className="w-full border border-black text-xs p-3 rounded-lg"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>

              <div className="flex mt-6 justify-center">
                <div className="mb-1 w-1/2">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="w-full bg-green-700 py-3 mt-2 rounded-lg text-white"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewExams;
