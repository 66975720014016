import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";
import { Workbox } from "workbox-window";
import { ModalProvider } from "./ModalContext";

const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    const workbox = new Workbox("/service-worker.js");
    try {
      await workbox.register();
      console.log("Service Worker registered successfully.");
    } catch (error) {
      console.error("Service Worker registration failed:", error);
    }
  }
};

registerServiceWorker();

const measurementKey = process.env.REACT_APP_MEASUREMENT_KEY;

ReactGA.initialize(measurementKey);

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ModalProvider>
      <App />
    </ModalProvider>
  </React.StrictMode>
);
