import React from 'react'

const CelebrateSvg = () => {
  return (
    <div>
        <svg width="310" height="446" viewBox="0 0 510 546" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M389.978 255.521C388.86 262.629 391.803 270.235 397.415 274.738C403.027 279.241 411.091 280.467 417.787 277.835C426.367 274.463 432.057 265.629 440.851 262.862C444.647 261.667 448.757 261.708 452.523 260.421C457.058 258.871 460.989 255.147 462.011 250.465C463.034 245.783 460.624 240.361 456.154 238.631C453.811 237.724 450.88 237.667 449.383 235.649C447.231 232.747 449.893 228.845 450.535 225.29C451.454 220.2 447.508 215.025 442.526 213.637C437.544 212.249 432.049 214.133 428.167 217.55C424.844 220.475 421.529 223.263 417.152 223.925C414.451 224.334 409.086 223.755 406.016 218.052" fill="#263238"/>
<path d="M425.761 213.69C425.779 213.687 425.813 213.856 425.863 214.183C425.91 214.509 425.948 214.998 425.912 215.627C425.845 216.869 425.461 218.743 424.133 220.601C423.468 221.513 422.594 222.439 421.438 223.129C420.296 223.834 418.896 224.297 417.392 224.412C415.891 224.527 414.278 224.303 412.763 223.658C411.247 223.024 409.854 221.92 408.835 220.519C407.789 219.132 407.056 217.617 406.337 216.191C405.62 214.763 404.916 213.396 404.042 212.243C402.311 209.911 399.886 208.615 397.791 208.027C395.669 207.435 393.846 207.512 392.633 207.684C391.413 207.876 390.765 208.123 390.748 208.07C390.742 208.053 390.9 207.985 391.209 207.871C391.515 207.745 391.985 207.618 392.599 207.484C393.823 207.248 395.69 207.108 397.888 207.672C400.055 208.23 402.595 209.535 404.425 211.953C406.283 214.327 407.262 217.522 409.251 220.205C410.233 221.553 411.528 222.578 412.964 223.189C414.396 223.806 415.929 224.03 417.358 223.933C418.79 223.836 420.122 223.413 421.216 222.759C422.324 222.12 423.177 221.249 423.836 220.385C425.153 218.623 425.584 216.821 425.71 215.611C425.829 214.383 425.707 213.695 425.761 213.69Z" fill="#263238"/>
<path d="M448.12 206.615L444.288 254.722L411.435 275.945C411.435 275.945 416.208 304.782 416.456 305.408L456.7 274.586C461.763 270.802 464.825 264.918 465.02 258.6L463.326 203.013" fill="#936754"/>
<path d="M444.1 184.451C443.816 184.294 439.759 195.082 440.14 196.73C440.521 198.379 444.536 203.316 446.209 204.309C449.335 206.165 447.234 212.914 447.532 213.986L463.685 214.829L463.25 202.336L464.317 193.831C464.809 192.088 464.843 190.237 464.345 188.495C463.803 186.597 462.886 184.534 461.488 184.748C458.948 185.137 458.77 185.872 458.77 185.872C458.77 185.872 457.068 183.349 455.763 185.481C455.763 185.481 453.42 182.692 451.395 185.096C451.395 185.096 448.698 182.444 446.819 186.551L445.794 189.418C445.794 189.418 446.946 186.026 444.1 184.451Z" fill="#936754"/>
<path d="M451.175 198.389C451.094 198.434 450.717 197.643 449.805 196.632C449.345 196.135 448.726 195.606 447.941 195.197C447.151 194.801 446.199 194.534 445.169 194.491C444.881 194.465 444.594 194.485 444.323 194.482L443.979 194.478L444.06 194.16C444.54 192.3 444.991 190.656 445.338 189.481C445.684 188.306 445.925 187.6 445.99 187.617C446.055 187.634 445.931 188.369 445.674 189.567C445.417 190.765 445.028 192.424 444.562 194.288L444.3 193.966C444.586 193.966 444.889 193.945 445.196 193.972C446.29 194.018 447.325 194.32 448.165 194.774C449.002 195.24 449.637 195.839 450.083 196.392C450.967 197.517 451.221 198.371 451.175 198.389Z" fill="#7C5142"/>
<path d="M461.133 189.529C461.166 189.55 461.041 189.789 460.838 190.222C460.658 190.645 460.313 191.315 460.466 192.09C460.51 192.282 460.589 192.453 460.711 192.654C460.83 192.847 460.98 193.112 461.005 193.42C461.053 194.035 460.704 194.628 460.182 194.998C459.109 195.754 457.318 195.387 456.625 194.018C456.3 193.34 456.329 192.611 456.419 191.983C456.518 191.346 456.662 190.766 456.808 190.205C457.104 189.089 457.46 188.11 457.799 187.309C458.124 186.503 458.508 185.861 458.911 185.541C459.312 185.215 459.617 185.206 459.618 185.232C459.634 185.275 459.367 185.353 459.05 185.689C458.727 186.022 458.421 186.632 458.143 187.443C457.852 188.252 457.539 189.224 457.274 190.324C457.059 191.405 456.609 192.708 457.096 193.794C457.606 194.847 459.097 195.179 459.911 194.604C460.323 194.33 460.597 193.866 460.573 193.453C460.573 193.065 460.164 192.661 460.104 192.164C459.98 191.192 460.427 190.538 460.659 190.128C460.923 189.71 461.105 189.511 461.133 189.529Z" fill="#7C5142"/>
<path d="M456.999 194.084C457.023 194.091 456.997 194.315 456.734 194.6C456.481 194.883 455.949 195.18 455.245 195.154C454.531 195.148 453.734 194.633 453.292 193.837C452.823 193.045 452.723 191.999 452.864 191.009C453.093 189.01 454.002 187.348 454.782 186.33C455.57 185.287 456.249 184.824 456.287 184.874C456.342 184.934 455.768 185.481 455.084 186.541C454.398 187.6 453.601 189.163 453.38 191.077C453.25 192.012 453.331 192.913 453.711 193.605C454.076 194.299 454.693 194.738 455.273 194.787C455.858 194.853 456.337 194.655 456.596 194.45C456.868 194.246 456.959 194.063 456.999 194.084Z" fill="#7C5142"/>
<path d="M453.244 193.488C453.267 193.504 453.195 193.686 452.93 193.909C452.671 194.13 452.185 194.356 451.558 194.343C450.944 194.345 450.159 194.051 449.637 193.391C449.104 192.747 448.823 191.838 448.847 190.902C448.875 189.96 449.18 189.122 449.497 188.415C449.82 187.704 450.158 187.107 450.432 186.595C450.988 185.577 451.364 184.964 451.428 184.997C451.492 185.029 451.233 185.7 450.763 186.758C450.334 187.821 449.447 189.236 449.369 190.916C449.347 191.743 449.584 192.539 450.013 193.093C450.436 193.663 451.044 193.927 451.58 193.976C452.693 194.08 453.198 193.412 453.244 193.488Z" fill="#7C5142"/>
<path d="M449.028 192.135C449.059 192.146 449.036 192.349 448.912 192.696C448.784 193.036 448.563 193.548 448.05 194.018C447.557 194.483 446.626 194.834 445.71 194.406C444.768 193.923 444.518 192.658 444.811 191.683C445.269 189.746 445.962 188.079 446.552 186.926C447.143 185.769 447.627 185.124 447.683 185.159C447.817 185.227 446.212 188.008 445.318 191.802C445.052 192.674 445.3 193.643 445.931 193.981C446.584 194.318 447.37 194.106 447.813 193.736C448.287 193.365 448.55 192.913 448.726 192.612C448.903 192.303 448.992 192.122 449.028 192.135Z" fill="#7C5142"/>
<path d="M458.937 196.131C458.902 196.286 457.118 195.722 454.981 196.269C452.832 196.774 451.513 198.1 451.41 197.978C451.364 197.936 451.625 197.542 452.211 197.059C452.792 196.576 453.725 196.043 454.857 195.763C455.99 195.487 457.063 195.528 457.802 195.687C458.544 195.844 458.958 196.073 458.937 196.131Z" fill="#7C5142"/>
<path d="M357.97 288.067C348.279 288.966 339.754 281.852 338.351 277.6C336.948 273.348 338.424 268.301 341.896 265.474C344.654 263.229 348.658 262.086 349.981 258.785C352.285 253.038 344.269 246.571 347.129 241.079C349.155 237.19 355.075 237.248 357.852 233.855C360.303 230.86 359.414 226.454 360.184 222.661C361.491 216.225 368.058 211.693 374.621 211.476C381.185 211.26 387.471 214.693 392.003 219.445C396.535 224.198 399.549 230.176 402.197 236.185C404.341 241.05 406.329 246.323 405.423 251.562C404.082 259.321 396.944 264.57 390.177 268.596C379.149 275.159 370.761 289.125 357.97 288.067Z" fill="#263238"/>
<path d="M342.377 270.447C342.377 270.456 342.318 270.463 342.201 270.467C342.084 270.476 341.91 270.469 341.682 270.449C341.229 270.402 340.552 270.258 339.782 269.835C339.021 269.417 338.156 268.691 337.598 267.553C337.055 266.424 336.891 264.885 337.453 263.387C337.726 262.642 338.204 261.918 338.851 261.341C339.489 260.75 340.263 260.296 341.056 259.857C341.851 259.421 342.68 258.997 343.437 258.445C344.189 257.892 344.836 257.167 345.223 256.27C346.035 254.47 345.796 252.269 345.265 250.176C344.73 248.065 343.736 245.957 343.317 243.6C343.099 242.43 343.015 241.23 343.186 240.057C343.379 238.895 343.851 237.753 344.657 236.912C345.443 236.055 346.455 235.483 347.471 235.111C348.488 234.728 349.512 234.484 350.494 234.233C352.456 233.755 354.3 233.151 355.603 231.937C356.922 230.758 357.757 229.271 358.297 227.864C359.341 225.001 359.301 222.452 359.344 220.755C359.343 219.939 359.342 219.282 359.341 218.777C359.341 218.324 359.349 218.086 359.367 218.085C359.385 218.084 359.412 218.321 359.446 218.773C359.479 219.225 359.522 219.891 359.547 220.753C359.563 222.458 359.671 225.025 358.642 227.992C358.108 229.448 357.267 231.001 355.891 232.258C354.526 233.558 352.57 234.21 350.613 234.698C348.682 235.22 346.485 235.665 345.034 237.257C344.302 238.025 343.879 239.057 343.698 240.138C343.541 241.23 343.617 242.374 343.829 243.507C344.23 245.787 345.21 247.874 345.759 250.052C346.292 252.194 346.555 254.502 345.66 256.47C345.234 257.445 344.51 258.241 343.707 258.818C342.901 259.395 342.053 259.816 341.261 260.241C340.471 260.667 339.723 261.098 339.119 261.643C338.506 262.176 338.064 262.83 337.795 263.523C337.249 264.913 337.374 266.363 337.86 267.434C338.36 268.515 339.164 269.235 339.882 269.664C341.363 270.518 342.4 270.371 342.377 270.447Z" fill="#263238"/>
<path d="M430.501 453.754L449.57 444.92C449.57 444.92 464.954 461.336 468.637 460.047L452.836 422.457L422.558 435.003L430.501 453.754Z" fill="#455A64"/>
<g opacity="0.3">
<path d="M430.501 453.754L449.57 444.92C449.57 444.92 464.954 461.336 468.637 460.047L452.836 422.457L422.558 435.003L430.501 453.754Z" fill="black"/>
</g>
<path d="M443.055 433.98C442.948 433.169 443.555 432.322 444.361 432.182C445.167 432.042 446.033 432.651 446.154 433.461C446.275 434.27 445.578 435.309 444.761 435.358C443.944 435.407 442.967 434.636 443.043 433.821" fill="#E0E0E0"/>
<path d="M452.837 422.456L449.835 423.84L466.107 459.363C466.107 459.363 467.539 460.73 468.638 460.046L452.837 422.456Z" fill="#E0E0E0"/>
<path d="M449.635 445.609C449.816 445.526 449.513 444.573 449.724 443.422C449.904 442.265 450.504 441.465 450.36 441.327C450.253 441.173 449.271 441.908 449.04 443.306C448.798 444.702 449.483 445.72 449.635 445.609Z" fill="#263238"/>
<path d="M452.923 448.866C453.118 448.833 453.097 447.907 453.611 446.957C454.097 445.992 454.871 445.482 454.786 445.303C454.742 445.118 453.614 445.443 452.997 446.636C452.369 447.824 452.747 448.936 452.923 448.866Z" fill="#263238"/>
<path d="M458.582 449.122C458.551 448.939 457.527 449.069 456.72 449.963C455.906 450.851 455.874 451.883 456.059 451.897C456.253 451.937 456.559 451.153 457.232 450.429C457.889 449.69 458.641 449.312 458.582 449.122Z" fill="#263238"/>
<path d="M445.065 447.14C445.2 446.993 444.593 446.257 444.227 445.19C443.84 444.13 443.848 443.177 443.651 443.147C443.474 443.097 443.125 444.17 443.574 445.421C444.012 446.675 444.959 447.291 445.065 447.14Z" fill="#263238"/>
<path d="M441.186 448.851C441.263 448.821 441.071 448.048 440.385 446.972C440.043 446.437 439.568 445.837 438.952 445.257C438.644 444.968 438.301 444.684 437.924 444.417C437.74 444.291 437.541 444.132 437.242 444.035C436.965 443.912 436.43 444.001 436.23 444.361C436.022 444.675 436.04 445.068 436.149 445.342C436.256 445.623 436.407 445.824 436.543 446.007C436.821 446.383 437.124 446.717 437.436 447.008C438.065 447.589 438.723 447.999 439.305 448.265C440.479 448.798 441.29 448.807 441.299 448.729C441.325 448.621 440.572 448.438 439.532 447.83C439.014 447.527 438.434 447.106 437.88 446.549C437.605 446.269 437.338 445.955 437.092 445.608C436.82 445.254 436.675 444.916 436.809 444.74C436.861 444.674 436.886 444.658 437.037 444.693C437.181 444.734 437.354 444.851 437.536 444.975C437.893 445.216 438.222 445.472 438.522 445.731C439.123 446.25 439.609 446.784 439.985 447.258C440.74 448.209 441.088 448.896 441.186 448.851Z" fill="#263238"/>
<path d="M441.349 448.502C441.35 448.425 440.539 448.337 439.322 448.77C438.719 448.986 438.03 449.348 437.369 449.893C437.04 450.167 436.72 450.487 436.428 450.853C436.149 451.229 435.765 451.707 435.982 452.453C436.125 452.831 436.545 453.018 436.838 453.018C437.144 453.036 437.373 452.969 437.609 452.921C438.077 452.806 438.504 452.616 438.877 452.389C439.627 451.935 440.155 451.327 440.484 450.768C441.149 449.624 441.047 448.766 440.978 448.777C440.86 448.755 440.775 449.543 440.075 450.499C439.73 450.97 439.225 451.472 438.556 451.838C438.223 452.021 437.852 452.171 437.454 452.26C437.03 452.369 436.644 452.355 436.622 452.188C436.541 452.003 436.708 451.558 436.962 451.27C437.218 450.935 437.498 450.637 437.786 450.375C438.366 449.854 438.974 449.478 439.511 449.221C440.593 448.706 441.363 448.613 441.349 448.502Z" fill="#263238"/>
<path d="M432.696 445.51L426.579 431.992L413.902 437.965L402.017 461.476L432.696 445.51Z" fill="#263238"/>
<path d="M451.709 461.683L471.04 453.437C471.04 453.437 485.914 470.317 489.635 469.14L474.992 431.084L444.344 442.697L451.709 461.683Z" fill="#455A64"/>
<path d="M464.863 442.303C464.781 441.489 465.413 440.662 466.223 440.546C467.033 440.431 467.88 441.066 467.977 441.879C468.073 442.691 467.345 443.708 466.527 443.732C465.709 443.756 464.755 442.955 464.857 442.143" fill="#E0E0E0"/>
<path d="M474.993 431.084L471.95 432.375L487.127 468.38C487.127 468.38 488.516 469.79 489.636 469.14L474.993 431.084Z" fill="#E0E0E0"/>
<path d="M471.084 454.128C471.268 454.05 470.994 453.089 471.24 451.944C471.456 450.793 472.079 450.012 471.94 449.87C471.838 449.713 470.834 450.417 470.561 451.808C470.275 453.195 470.929 454.233 471.084 454.128Z" fill="#263238"/>
<path d="M474.271 457.484C474.468 457.457 474.474 456.531 475.017 455.597C475.533 454.648 476.321 454.161 476.242 453.979C476.204 453.793 475.066 454.083 474.413 455.257C473.749 456.425 474.092 457.548 474.271 457.484Z" fill="#263238"/>
<path d="M479.92 457.913C479.895 457.729 478.867 457.828 478.033 458.696C477.192 459.559 477.128 460.59 477.313 460.609C477.506 460.655 477.835 459.881 478.531 459.178C479.21 458.459 479.973 458.104 479.92 457.913Z" fill="#263238"/>
<path d="M466.47 455.518C466.609 455.375 466.025 454.621 465.692 453.543C465.337 452.472 465.375 451.519 465.179 451.483C465.003 451.427 464.622 452.49 465.032 453.754C465.432 455.021 466.359 455.666 466.47 455.518Z" fill="#263238"/>
<path d="M462.539 457.11C462.617 457.083 462.449 456.304 461.796 455.208C461.47 454.663 461.014 454.048 460.416 453.45C460.117 453.152 459.782 452.857 459.414 452.579C459.234 452.447 459.04 452.283 458.744 452.176C458.471 452.045 457.933 452.117 457.723 452.471C457.505 452.778 457.512 453.172 457.611 453.449C457.71 453.733 457.854 453.938 457.985 454.126C458.251 454.51 458.544 454.853 458.847 455.154C459.458 455.754 460.103 456.184 460.677 456.468C461.834 457.036 462.645 457.07 462.656 456.993C462.685 456.885 461.938 456.68 460.917 456.041C460.409 455.722 459.841 455.283 459.305 454.71C459.039 454.422 458.781 454.1 458.547 453.746C458.286 453.384 458.151 453.042 458.291 452.869C458.345 452.805 458.37 452.79 458.521 452.829C458.663 452.874 458.833 452.997 459.011 453.126C459.36 453.378 459.681 453.643 459.974 453.911C460.559 454.448 461.028 454.996 461.389 455.482C462.113 456.454 462.44 457.152 462.539 457.11Z" fill="#263238"/>
<path d="M462.713 456.765C462.716 456.688 461.908 456.576 460.679 456.971C460.07 457.169 459.37 457.509 458.692 458.034C458.355 458.298 458.025 458.608 457.722 458.965C457.432 459.333 457.033 459.798 457.227 460.551C457.358 460.933 457.772 461.133 458.065 461.142C458.37 461.169 458.601 461.109 458.839 461.069C459.31 460.968 459.743 460.792 460.123 460.576C460.887 460.144 461.433 459.554 461.779 459.004C462.479 457.881 462.403 457.02 462.334 457.029C462.217 457.003 462.107 457.789 461.379 458.722C461.02 459.182 460.5 459.669 459.82 460.014C459.482 460.187 459.106 460.325 458.705 460.403C458.278 460.499 457.893 460.473 457.876 460.305C457.8 460.117 457.981 459.678 458.244 459.398C458.51 459.071 458.799 458.781 459.095 458.528C459.691 458.025 460.31 457.668 460.855 457.427C461.951 456.946 462.723 456.877 462.713 456.765Z" fill="#263238"/>
<path d="M355.097 387.931C355.097 387.931 402.949 381.333 412.716 374.062C422.483 366.79 422.088 364.593 422.088 364.593C422.088 364.593 423.614 357.094 417.635 356.124C411.656 355.154 383.377 362.911 383.377 362.911L366.733 358.642L352.997 366.79L343.843 378.102C343.843 378.102 346.371 384.727 349.926 385.859C353.481 386.99 355.097 387.152 355.097 387.152" fill="#F4F7F7"/>
<g opacity="0.3">
<path d="M355.097 387.931C355.097 387.931 402.949 381.333 412.716 374.062C422.483 366.79 422.088 364.593 422.088 364.593C422.088 364.593 423.614 357.094 417.635 356.124C411.656 355.154 383.377 362.911 383.377 362.911L366.733 358.642L352.997 366.79L343.843 378.102C343.843 378.102 346.371 384.727 349.926 385.859C353.481 386.99 355.097 387.152 355.097 387.152" fill="black"/>
</g>
<path d="M357.3 379.175C356.94 378.24 356.48 365.941 356.48 365.941C356.48 365.941 359.855 360.438 366.452 360.364C373.048 360.291 382.53 363.206 382.53 363.206L410.48 354.002L412.714 364.593C412.714 364.593 391.687 382.433 380.488 382.378C367.486 382.315 357.3 379.175 357.3 379.175Z" fill="#936754"/>
<path d="M330.713 350.408C327.608 351.52 324.459 351.325 321.339 350.251C317.525 348.937 314.272 346.364 311.822 343.16L309.375 339.961C306.536 337.148 288.744 312.739 288.744 312.739L298.219 297.421L319.593 322.361L325.715 305.411L343.842 318.109L338.921 338.45C337.72 343.809 335.817 348.581 330.713 350.408Z" fill="#936754"/>
<path d="M287.155 274.693C287.155 274.693 292.255 282.241 293.559 284.766C294.615 286.81 298.571 293.352 298.348 300.253L288.744 312.738C288.744 312.738 284.133 309.216 283.396 307.89C282.66 306.564 283.338 304.669 283.338 304.669C283.338 304.669 281.923 303.471 281.186 303.177C281.186 303.177 281.628 305.534 279.86 305.975C278.093 306.417 272.201 300.231 272.201 300.231C272.201 300.231 271.759 297.58 273.379 297.433C273.379 297.433 272.201 293.751 273.379 292.72C273.379 292.72 272.053 289.774 273.674 288.449C273.674 288.449 274.41 285.061 275.589 283.883C276.767 282.705 288.743 286.24 288.743 286.24C288.743 286.24 285.906 279.936 285.611 278.169C285.318 276.4 287.155 274.693 287.155 274.693Z" fill="#936754"/>
<path d="M279.784 297.959C279.81 297.934 279.978 298.057 280.264 298.298C280.542 298.53 280.989 298.918 281.307 299.535C281.46 299.819 281.674 300.177 281.713 300.622C281.76 301.051 281.694 301.514 281.497 301.961C281.329 302.427 280.8 302.841 280.26 302.899C279.716 302.996 279.131 302.827 278.673 302.538C277.758 301.93 277.14 301.215 276.471 300.629C275.873 300.058 275.309 299.518 274.778 299.011C273.825 298.078 273.259 297.475 273.308 297.422C273.356 297.369 274.01 297.876 275.026 298.739C275.58 299.22 276.17 299.732 276.794 300.275C277.464 300.832 278.156 301.577 278.941 302.092C279.707 302.616 280.782 302.482 281.062 301.757C281.232 301.402 281.305 301.011 281.282 300.66C281.273 300.311 281.117 300.012 280.978 299.703C280.721 299.106 280.366 298.732 280.12 298.442C279.878 298.156 279.758 297.984 279.784 297.959Z" fill="#7C5142"/>
<path d="M279.136 287.391C279.185 287.338 280.023 288.056 281.344 289.264C281.668 289.572 282.047 289.886 282.406 290.27C282.766 290.653 283.174 291.082 283.431 291.686C283.707 292.301 283.587 293.096 283.189 293.672C282.813 294.261 282.099 294.75 281.286 294.677C280.483 294.594 279.909 294.092 279.43 293.672C278.96 293.242 278.505 292.826 278.065 292.424C277.236 291.651 276.469 290.935 275.776 290.289C274.483 289.062 273.708 288.277 273.756 288.223C273.804 288.169 274.666 288.858 276.022 290.015C276.737 290.636 277.529 291.324 278.385 292.067C278.831 292.461 279.292 292.869 279.768 293.29C280.236 293.699 280.752 294.107 281.332 294.158C282.506 294.257 283.419 292.778 282.984 291.86C282.553 290.882 281.67 290.153 281.079 289.519C279.835 288.255 279.088 287.443 279.136 287.391Z" fill="#7C5142"/>
<path d="M281.118 294.681C281.119 294.656 281.356 294.626 281.729 294.805C282.091 294.976 282.599 295.395 282.8 296.139C283.034 296.876 282.651 297.936 281.733 298.419C281.293 298.669 280.693 298.76 280.158 298.595C279.619 298.442 279.161 298.119 278.74 297.789C277.067 296.468 275.694 295.111 274.764 294.074C273.834 293.037 273.336 292.332 273.388 292.288C273.506 292.183 275.758 294.778 279.061 297.379C279.467 297.699 279.874 297.979 280.299 298.104C280.722 298.237 281.144 298.179 281.509 297.994C282.251 297.62 282.585 296.827 282.441 296.219C282.32 295.596 281.923 295.181 281.627 294.979C281.325 294.768 281.108 294.726 281.118 294.681Z" fill="#7C5142"/>
<path d="M283.089 306.657C283.062 306.654 283.059 306.492 283.079 306.19C283.098 305.889 283.165 305.445 283.348 304.912C283.691 303.861 284.605 302.356 286.436 301.607L286.503 302.081L286.475 302.08H286.474H286.473L286.49 301.593H286.492H286.496H286.503H286.518L286.548 301.594L286.532 302.082H286.531H286.529H286.525H286.517H286.502L286.368 302.079L286.298 301.959C285.767 301.044 285.334 299.95 285.102 298.763C284.562 296.057 285.12 293.526 285.878 291.926C286.241 291.11 286.656 290.515 286.953 290.12C287.272 289.739 287.46 289.542 287.488 289.564C287.574 289.625 286.843 290.455 286.217 292.07C285.569 293.655 285.099 296.08 285.613 298.66C285.834 299.793 286.236 300.836 286.724 301.718L286.52 301.594L286.535 301.595H286.543H286.547H286.549H286.55L286.533 302.083L286.502 302.082L286.488 302.081H286.481H286.477L286.475 302.08L286.491 301.594L286.492 301.593L286.52 301.595L287.683 301.647L286.587 302.069C284.969 302.692 284.018 304.049 283.628 305.017C283.221 306.013 283.165 306.669 283.089 306.657Z" fill="#7C5142"/>
<path d="M321.945 332.606C321.82 332.656 320.855 330.416 320.237 327.485C319.608 324.555 319.576 322.117 319.711 322.111C319.863 322.1 320.126 324.489 320.747 327.376C321.355 330.265 322.088 332.555 321.945 332.606Z" fill="#7C5142"/>
<path d="M357.301 379.175C357.009 380.05 354.384 402.216 354.384 402.216C354.384 402.216 335.502 448.798 332.802 462.88C330.11 476.921 331.927 491.462 347.093 495.545C362.259 499.628 374.663 488.045 374.663 488.045C374.663 488.045 380.015 495.544 394.399 493.784C408.975 492.001 460.255 460.546 460.255 460.546L451.505 437.881L411.549 452.963C411.549 452.963 431.643 413.369 432.548 403.09C433.246 395.16 421.541 369.693 412.716 364.592C412.716 364.592 387.634 383.714 379.176 382.319C370.717 380.925 357.301 379.175 357.301 379.175Z" fill="#263238"/>
<path d="M385.988 402.244C385.997 402.245 385.996 402.328 385.984 402.49C385.967 402.682 385.946 402.917 385.921 403.206C385.871 403.836 385.744 404.759 385.563 405.946C385.185 408.317 384.422 411.718 383.097 415.804C381.787 419.895 379.866 424.637 377.618 429.839C375.36 435.032 372.953 440.777 370.98 446.998C369.985 450.105 369.162 453.185 368.515 456.191C367.873 459.197 367.372 462.127 367.22 464.94C366.873 470.568 367.806 475.672 369.443 479.602C371.086 483.541 373.221 486.328 374.835 488.076C375.655 488.945 376.306 489.606 376.78 490.022C376.989 490.222 377.159 490.385 377.299 490.518C377.414 490.632 377.47 490.694 377.464 490.701C377.458 490.708 377.391 490.66 377.264 490.559C377.115 490.436 376.933 490.286 376.71 490.101C376.212 489.709 375.535 489.069 374.687 488.215C373.017 486.499 370.812 483.717 369.104 479.744C367.401 475.782 366.416 470.608 366.741 464.913C366.882 462.063 367.378 459.108 368.016 456.085C368.659 453.06 369.483 449.963 370.483 446.841C372.465 440.592 374.895 434.833 377.177 429.649C379.448 424.461 381.397 419.749 382.747 415.689C384.111 411.635 384.925 408.263 385.363 405.912C385.574 404.735 385.734 403.819 385.817 403.194C385.863 402.908 385.901 402.676 385.932 402.485C385.96 402.324 385.979 402.243 385.988 402.244Z" fill="#455A64"/>
<path d="M451.505 437.881C451.511 437.898 451.332 437.98 450.986 438.121C450.584 438.277 450.081 438.473 449.464 438.712C448.067 439.229 446.16 439.934 443.834 440.794C439.082 442.556 432.5 444.945 425.312 447.784C418.129 450.622 411.654 453.347 407.373 456.021C406.86 456.38 406.371 456.723 405.905 457.049C405.486 457.423 405.048 457.737 404.712 458.101C404.015 458.807 403.522 459.512 403.191 460.133C402.877 460.714 402.62 461.189 402.415 461.567C402.23 461.893 402.126 462.059 402.11 462.05C402.094 462.041 402.168 461.858 402.321 461.519C402.485 461.185 402.686 460.672 403.011 460.037C403.318 459.394 403.803 458.645 404.501 457.899C404.839 457.515 405.275 457.184 405.698 456.79C406.154 456.439 406.653 456.091 407.17 455.714C411.456 452.925 417.926 450.144 425.12 447.3C432.319 444.457 438.924 442.122 443.71 440.448C446.06 439.652 447.986 438.999 449.397 438.521C450.027 438.319 450.542 438.154 450.952 438.022C451.309 437.913 451.499 437.864 451.505 437.881Z" fill="#455A64"/>
<path d="M432.548 403.091C432.566 403.092 432.552 403.285 432.509 403.65C432.453 404.074 432.382 404.603 432.296 405.251C432.019 406.622 431.54 408.598 430.741 410.969C429.184 415.726 426.567 422.127 423.393 429.056C420.217 435.989 417.14 442.168 414.858 446.611C413.717 448.832 412.77 450.618 412.104 451.846C411.787 452.415 411.529 452.88 411.322 453.252C411.137 453.57 411.032 453.733 411.016 453.725C411 453.717 411.074 453.537 411.229 453.204C411.413 452.82 411.643 452.341 411.924 451.754C412.571 450.435 413.453 448.638 414.529 446.447C416.726 441.965 419.748 435.765 422.92 428.841C426.091 421.92 428.753 415.558 430.393 410.852C431.234 408.506 431.762 406.56 432.098 405.211C432.222 404.575 432.324 404.055 432.405 403.638C432.481 403.278 432.53 403.089 432.548 403.091Z" fill="#455A64"/>
<path d="M382.893 404.574C382.751 404.599 381.759 399.619 380.676 393.452C379.593 387.283 378.83 382.264 378.972 382.239C379.114 382.214 380.106 387.193 381.189 393.362C382.272 399.529 383.035 404.549 382.893 404.574Z" fill="#455A64"/>
<path d="M422.377 379.013C422.397 379.035 422.151 379.3 421.644 379.71C421.139 380.122 420.347 380.65 419.284 381.144C417.194 382.14 413.812 382.855 410.312 381.855C406.815 380.838 404.333 378.432 403.093 376.476C402.457 375.492 402.067 374.623 401.859 374.006C401.648 373.389 401.581 373.034 401.61 373.026C401.71 372.986 402.078 374.4 403.398 376.269C404.682 378.12 407.111 380.383 410.457 381.355C413.806 382.312 417.066 381.69 419.138 380.806C421.249 379.927 422.314 378.925 422.377 379.013Z" fill="#455A64"/>
<path d="M370.979 382.095C371.011 382.096 371.034 382.439 371.008 383.058C370.983 383.677 370.884 384.572 370.647 385.658C370.188 387.821 369.035 390.774 366.837 393.397C364.639 396.022 361.934 397.675 359.885 398.506C358.857 398.93 357.993 399.184 357.388 399.317C356.783 399.452 356.442 399.489 356.435 399.458C356.411 399.366 357.755 399.086 359.736 398.169C361.709 397.267 364.303 395.611 366.437 393.062C368.571 390.514 369.746 387.671 370.288 385.57C370.844 383.46 370.884 382.088 370.979 382.095Z" fill="#455A64"/>
<path d="M384.917 383.543C384.953 383.625 384.192 383.682 383.667 384.551C383.418 384.966 383.297 385.578 383.589 386.134C383.858 386.683 384.504 387.159 385.201 387.131C385.899 387.096 386.499 386.562 386.716 385.991C386.956 385.411 386.779 384.813 386.493 384.422C385.89 383.605 385.128 383.618 385.156 383.532C385.155 383.502 385.327 383.452 385.641 383.507C385.949 383.56 386.402 383.751 386.783 384.195C387.162 384.615 387.45 385.388 387.166 386.157C386.91 386.897 386.184 387.594 385.225 387.652C384.264 387.682 383.479 387.055 383.156 386.341C382.803 385.601 383.019 384.805 383.358 384.352C383.697 383.875 384.13 383.644 384.432 383.563C384.74 383.479 384.916 383.514 384.917 383.543Z" fill="#455A64"/>
<path d="M368.293 272.436L347.797 273.082C347.797 273.082 341.179 299.747 341.681 317.447C341.989 328.285 348.698 343.617 350.319 349.259C351.448 353.19 347.435 358.541 346.307 362.472C346.291 362.528 346.274 362.585 346.258 362.642C344.589 368.318 336.78 387.93 356.144 387.93C356.144 387.93 346.07 382.377 351.146 372.516C356.038 363.012 365.492 358.884 373.505 363.558C381.518 368.232 384.189 366.229 391.534 363.224C398.879 360.219 424.921 352.54 421.583 365.895C421.583 365.895 425.588 358.216 417.576 352.541C411.489 348.23 406.369 336.447 406.161 324.447C406.051 318.094 408.745 311.269 413.777 307.39L422.584 300.874C422.584 300.874 413.428 301.413 416.099 287.391C418.77 273.369 420.646 270.584 420.646 270.584L403.217 276.335C402.609 276.491 401.981 276.554 401.354 276.52L392.52 276.04L368.293 272.436Z" fill="#DDFFA1"/>
<path d="M323.901 306.813C323.901 306.813 333.433 282.437 337.398 278.46C340.996 274.851 347.797 273.082 347.797 273.082L342.397 318.979L323.901 306.813Z" fill="#DDFFA1"/>
<path d="M348.548 344.606C348.531 344.612 348.456 344.452 348.327 344.141C348.182 343.773 348.004 343.32 347.788 342.772C347.347 341.573 346.647 339.861 345.937 337.688C344.452 333.376 342.481 327.337 341.467 320.403C340.984 316.936 340.916 313.573 341.216 310.553C341.508 307.531 342.063 304.859 342.488 302.619C342.923 300.379 343.261 298.566 343.466 297.307C343.567 296.727 343.651 296.249 343.719 295.859C343.782 295.529 343.824 295.356 343.842 295.358C343.86 295.36 343.853 295.537 343.823 295.872C343.781 296.266 343.729 296.749 343.667 297.335C343.523 298.605 343.24 300.434 342.851 302.684C342.472 304.934 341.957 307.604 341.695 310.595C341.424 313.585 341.506 316.897 341.984 320.331C342.987 327.199 344.894 333.237 346.287 337.571C346.951 339.753 347.598 341.484 347.98 342.702C348.159 343.263 348.306 343.726 348.427 344.103C348.522 344.427 348.565 344.6 348.548 344.606Z" fill="#263238"/>
<path d="M402.834 331.003C402.798 331.009 402.707 330.581 402.572 329.8C402.428 329.021 402.291 327.88 402.189 326.466C401.978 323.64 402.047 319.688 402.833 315.42C403.631 311.153 404.987 307.439 406.198 304.877C406.801 303.594 407.338 302.578 407.752 301.903C408.158 301.223 408.397 300.856 408.429 300.874C408.511 300.92 407.647 302.442 406.534 305.027C405.418 307.608 404.135 311.295 403.345 315.515C402.568 319.735 402.442 323.637 402.557 326.446C402.667 329.258 402.927 330.989 402.834 331.003Z" fill="#263238"/>
<g opacity="0.3">
<path d="M402.974 327.29C403.688 323.354 404.412 319.386 405.917 315.68C407.422 311.974 409.787 308.502 413.164 306.358C413.898 305.892 414.686 305.482 415.288 304.854C415.89 304.226 416.282 303.304 416.01 302.478C415.554 301.088 413.654 300.847 412.241 301.23C409.356 302.011 407.043 304.277 405.609 306.899C404.174 309.521 403.514 312.491 403.011 315.437C402.408 318.965 402.005 322.572 402.482 326.12" fill="black"/>
</g>
<path d="M421.832 359.543C421.825 359.549 421.768 359.495 421.664 359.384C421.541 359.249 421.393 359.086 421.212 358.887C420.815 358.451 420.202 357.832 419.347 357.112C417.636 355.709 414.817 353.903 410.884 353.151C408.934 352.763 406.72 352.704 404.423 353.113C402.125 353.55 399.766 354.444 397.376 355.578C394.978 356.7 392.516 358.028 389.813 359.117C388.456 359.645 387.041 360.115 385.554 360.413C384.065 360.68 382.513 360.798 380.962 360.656C377.854 360.359 374.994 359.356 372.189 358.757C369.392 358.112 366.595 358.043 364.003 358.496C361.411 358.936 359.043 359.846 357.019 361.013C355 362.193 353.322 363.628 352.02 365.134C349.387 368.147 348.161 371.243 347.563 373.379C347.258 374.455 347.107 375.314 347.009 375.896C346.968 376.162 346.935 376.38 346.908 376.56C346.882 376.71 346.865 376.786 346.856 376.785C346.856 376.785 346.819 375.522 347.367 373.327C347.911 371.155 349.097 367.993 351.742 364.893C353.049 363.345 354.748 361.862 356.801 360.64C358.858 359.432 361.272 358.487 363.922 358.023C366.565 357.546 369.453 357.609 372.296 358.258C375.141 358.861 377.989 359.852 381.009 360.137C382.514 360.274 384.008 360.161 385.456 359.904C386.901 359.617 388.288 359.159 389.625 358.643C392.292 357.577 394.754 356.261 397.174 355.143C399.585 354.014 401.992 353.117 404.347 352.688C406.706 352.288 408.971 352.371 410.955 352.79C414.958 353.604 417.789 355.491 419.479 356.958C421.166 358.468 421.832 359.543 421.832 359.543Z" fill="#263238"/>
<path d="M395.428 318.109C395.446 318.109 395.459 318.276 395.467 318.595C395.467 318.914 395.496 319.386 395.459 319.991C395.425 321.203 395.262 322.959 394.831 325.089C393.998 329.329 391.79 335.135 387.434 340.204C383.076 345.275 377.667 348.33 373.6 349.791C371.559 350.538 369.848 350.962 368.655 351.178C368.062 351.305 367.591 351.348 367.276 351.395C366.96 351.435 366.792 351.447 366.79 351.43C366.783 351.387 367.44 351.259 368.612 350.98C369.785 350.704 371.466 350.229 373.469 349.447C377.46 347.913 382.751 344.853 387.039 339.864C391.325 334.876 393.554 329.186 394.471 325.01C394.944 322.913 395.161 321.179 395.257 319.978C395.356 318.777 395.384 318.108 395.428 318.109Z" fill="#263238"/>
<g opacity="0.3">
<path d="M393.756 328.326C392.654 328.113 391.537 328.761 390.833 329.635C390.13 330.509 389.743 331.588 389.29 332.614C386.957 337.91 382.52 342.245 377.171 344.455C375.735 345.048 374.219 345.504 372.943 346.391C371.667 347.278 370.642 348.733 370.789 350.279C370.789 350.279 380.315 348.431 386.643 340.697C392.972 332.964 393.756 328.326 393.756 328.326Z" fill="black"/>
</g>
<path d="M357.881 289.562C357.226 288.641 356.602 287.584 355.546 287.181C353.682 286.47 351.753 288.185 349.758 288.197C347.402 288.212 345.771 285.973 343.882 284.564C341.417 282.725 338.173 282.197 335.13 281.752L334.099 283.637C336.786 285.423 340.952 282.94 343.665 284.685C344.89 285.473 345.485 286.913 346.163 288.202C346.841 289.491 347.848 290.82 349.294 290.992C350.683 291.157 351.946 290.191 353.326 289.965C357.65 289.256 360.715 295.672 365.037 294.955C362.176 293.946 359.64 292.035 357.881 289.562Z" fill="#087D4C"/>
<path d="M341.122 314.51C342.446 312.777 344.874 312.243 347.04 312.495C349.206 312.747 351.237 313.641 353.328 314.261C355.419 314.881 357.73 315.214 359.738 314.362C360.713 313.948 361.582 313.268 362.614 313.036C364.033 312.717 365.477 313.292 366.863 313.732C369.778 314.656 372.873 315.007 375.921 314.76C377.096 314.664 378.273 314.481 379.449 314.571C380.625 314.661 381.832 315.064 382.611 315.949C384.075 315.096 385.703 314.524 387.379 314.274C385.459 315.619 383.434 317.006 381.106 317.284C378.426 317.603 375.83 316.404 373.188 315.847C366.006 314.333 358.468 317.596 351.274 316.143C349.599 315.805 347.965 315.213 346.26 315.112C344.554 315.011 342.677 315.516 341.703 316.92" fill="#087D4C"/>
<path d="M346.126 334.997C348.977 335.612 351.519 333.214 354.315 332.387C356.781 331.658 359.435 332.18 361.906 332.892C364.377 333.603 366.844 334.508 369.415 334.539C370.997 334.558 372.617 334.247 374.14 334.677C375.334 335.014 376.356 335.778 377.479 336.305C381.13 338.019 385.414 337.097 389.333 336.144C386.497 337.871 383.082 338.451 379.763 338.358C376.444 338.265 373.179 337.537 369.939 336.813C367.318 336.227 364.696 335.641 362.075 335.056C360.098 334.614 358.054 334.171 356.062 334.541C352.692 335.168 349.815 338.06 346.403 337.73" fill="#087D4C"/>
<path d="M376.337 348.808C378.421 349.012 380.51 349.163 382.601 349.26C384.289 349.339 386.003 349.381 387.645 348.98C390.381 348.311 392.792 346.443 395.602 346.25C398.491 346.051 401.211 347.665 404.099 347.864C406.764 348.047 409.488 346.937 411.265 344.944C407.091 346.176 402.59 346.285 398.361 345.256C396.397 344.778 394.376 344.055 392.406 344.507C390.821 344.871 389.513 345.946 388.079 346.713C384.559 348.596 380.37 348.582 376.378 348.522" fill="#087D4C"/>
<path d="M393.191 312.624C393.395 310.339 401.459 308.313 404.779 305.407C404.476 307.742 402.705 309.822 400.448 310.494C399.208 310.863 397.888 310.831 396.605 310.999C395.321 311.167 393.977 311.596 393.191 312.624Z" fill="#087D4C"/>
<path d="M378.765 296.519C380.116 297.561 382.049 297.268 383.672 296.744C385.295 296.219 386.977 295.502 388.646 295.857C390.277 296.204 391.521 297.503 393.026 298.221C395.036 299.18 397.485 299.029 399.475 298.03C401.465 297.031 403.004 295.248 403.926 293.221C404.354 292.281 404.702 291.227 405.54 290.623C406.478 289.946 407.751 290.035 408.854 289.686C410.631 289.124 411.824 287.488 412.797 285.898C413.77 284.308 414.725 282.589 416.333 281.646C414.934 281.219 413.48 282.159 412.503 283.248C411.526 284.337 410.732 285.655 409.457 286.372C408.183 287.089 406.611 287.095 405.317 287.776C403.019 288.985 402.273 291.861 400.686 293.916C398.888 296.246 395.48 297.5 392.866 296.148C391.457 295.419 390.291 294.004 388.705 293.982C387.676 293.968 386.755 294.562 385.838 295.029C383.679 296.128 381.205 296.597 378.794 296.362C378.975 296.399 379.156 296.435 379.338 296.472" fill="#087D4C"/>
<path d="M343.317 376.297C343.131 374.958 343.703 373.624 344.445 372.493C345.187 371.363 346.11 370.352 346.762 369.167C347.677 367.506 348.011 365.597 348.681 363.823C349.351 362.05 350.518 360.283 352.328 359.72C353.555 359.338 354.893 359.569 356.151 359.306C358.271 358.862 359.926 357.074 362.054 356.671C363.789 356.342 365.564 356.985 367.328 356.899C368.919 356.821 370.471 356.123 371.584 354.984C370.197 355.816 368.448 355.675 366.857 355.384C365.266 355.093 363.628 354.68 362.055 355.054C361.027 355.298 360.1 355.865 359.079 356.138C357.161 356.651 355.085 356.089 353.157 356.564C350.338 357.258 348.507 359.969 347.367 362.639C346.227 365.309 346.09 367.415 344.178 369.599C344.178 369.599 342.72 375.316 343.317 376.297Z" fill="#087D4C"/>
<path d="M360.293 275.894C359.494 275.555 358.673 275.244 357.965 274.742C357.258 274.24 356.691 273.664 356.566 272.805L353.535 272.901C354.505 275.221 356.927 277.31 359.43 277.558C360.146 277.629 360.887 277.592 361.562 277.841C362.334 278.124 362.93 278.747 363.652 279.138C364.375 279.53 365.412 279.614 365.903 278.954C364.14 277.753 362.258 276.727 360.293 275.894Z" fill="#087D4C"/>
<path d="M341.122 299.813C339.955 299.287 338.606 299.455 337.344 299.675C336.083 299.895 334.769 300.154 333.542 299.787C332.486 299.47 331.623 298.725 330.71 298.108C329.796 297.491 328.934 296.935 327.855 297.163L327.271 298.544C327.283 299.137 327.818 298.836 328.388 299.001C328.958 299.166 329.604 299.26 329.99 299.711C330.313 300.089 330.365 300.623 330.586 301.068C331.038 301.978 332.155 302.394 333.169 302.328C334.183 302.261 335.125 301.808 336.043 301.373C336.961 300.938 337.92 300.507 338.935 300.488C340.472 300.46 341.97 301.495 342.487 302.942C343.113 301.824 342.289 300.339 341.122 299.813Z" fill="#087D4C"/>
<path d="M406.924 272.369C408.096 274.016 410.422 274.447 412.403 274.049C414.385 273.651 416.159 272.595 418.008 271.778C419.857 270.961 421.953 270.373 423.884 270.969C426.266 271.704 427.795 274.03 429.997 275.199C433.015 276.801 437.174 275.729 439.042 272.868C440.028 271.357 440.384 269.536 440.969 267.83C441.554 266.123 442.505 264.382 444.152 263.645C446.073 262.785 448.359 263.535 450.387 262.974C453.205 262.195 454.764 259.113 455.172 256.218C455.915 250.937 458.877 240.35 438.228 238.948" fill="#263238"/>
<path d="M399.732 231.149C401.098 220.245 393.211 210.281 382.139 209.226C371.703 208.232 362.429 216.002 360.865 226.367C359.494 235.453 358.316 244.626 358.358 247.957C358.486 258.003 364.364 261.914 366.747 263.088C367.291 263.356 368.79 263.66 369.39 263.746C369.428 263.752 369.453 263.787 369.446 263.824L367.215 275.356C366.204 282.911 370.421 289.65 377.965 290.75C385.534 291.853 391.45 286.05 392.605 278.489L399.732 231.149Z" fill="#936754"/>
<path d="M362.595 232.303C362.761 232.538 364.087 231.826 365.787 232.079C367.488 232.307 368.609 233.363 368.824 233.182C368.928 233.103 368.783 232.702 368.297 232.227C367.82 231.755 366.963 231.239 365.903 231.089C364.843 230.94 363.895 231.2 363.325 231.525C362.744 231.85 362.512 232.197 362.595 232.303Z" fill="#263238"/>
<path d="M378.352 234.917C378.518 235.152 379.846 234.441 381.545 234.693C383.246 234.921 384.367 235.978 384.582 235.796C384.686 235.717 384.542 235.316 384.055 234.841C383.578 234.369 382.721 233.853 381.661 233.703C380.601 233.555 379.653 233.814 379.083 234.139C378.502 234.464 378.269 234.81 378.352 234.917Z" fill="#263238"/>
<path d="M371.842 243.558C371.864 243.463 370.834 243.126 369.152 242.657C368.724 242.549 368.326 242.403 368.296 242.096C368.239 241.767 368.489 241.326 368.774 240.851C369.335 239.86 369.923 238.821 370.54 237.732C373.009 233.284 374.865 229.601 374.685 229.504C374.505 229.406 372.357 232.932 369.888 237.38C369.29 238.478 368.718 239.525 368.173 240.527C367.937 240.996 367.573 241.515 367.699 242.198C367.768 242.539 368.053 242.821 368.32 242.937C368.586 243.063 368.829 243.103 369.042 243.147C370.753 243.49 371.821 243.653 371.842 243.558Z" fill="#263238"/>
<path d="M369.457 263.756C369.457 263.756 375.944 265.252 384.876 261.513C384.876 261.513 381.02 269.815 368.856 266.864L369.457 263.756Z" fill="#7C5142"/>
<path d="M371.962 247.761C372.692 247.041 373.828 246.754 374.875 247.023C375.601 247.21 376.295 247.675 376.656 248.341C377.017 249.007 376.986 249.874 376.495 250.388C375.942 250.966 374.961 250.976 374.183 250.627C373.405 250.278 372.785 249.642 372.199 249.013C372.037 248.839 371.872 248.658 371.787 248.439C371.702 248.22 371.716 247.953 371.882 247.806" fill="#7C5142"/>
<path d="M376.675 245.464C376.401 245.408 376.128 247.253 374.357 248.333C372.588 249.418 370.651 248.923 370.599 249.179C370.556 249.291 370.985 249.602 371.812 249.745C372.623 249.892 373.85 249.803 374.934 249.143C376.015 248.48 376.586 247.469 376.759 246.72C376.942 245.959 376.803 245.471 376.675 245.464Z" fill="#263238"/>
<path d="M379.52 228.757C379.62 229.244 381.35 229.272 383.31 229.804C385.281 230.296 386.805 231.114 387.129 230.738C387.276 230.557 387.059 230.081 386.474 229.538C385.894 228.997 384.927 228.423 383.749 228.115C382.571 227.809 381.446 227.84 380.676 228.029C379.901 228.218 379.479 228.527 379.52 228.757Z" fill="#263238"/>
<path d="M364.314 225.709C364.557 226.139 365.787 225.91 367.198 226.095C368.616 226.232 369.762 226.734 370.095 226.37C370.244 226.191 370.112 225.777 369.644 225.335C369.184 224.896 368.368 224.473 367.396 224.361C366.424 224.251 365.534 224.479 364.986 224.803C364.431 225.128 364.21 225.502 364.314 225.709Z" fill="#263238"/>
<path d="M413.506 258.698C414.134 257.262 414.684 255.93 414.969 255.066C416.089 251.666 416.097 246.269 414.483 243.073C412.983 240.101 409.182 237.147 408.083 234.266C406.392 229.83 407.641 228.676 406.344 219.423C405.609 214.176 398.608 206.898 391.264 208.03C388.954 205.352 385.684 203.968 382.445 203.37C374.502 201.903 365.851 204.982 361.099 212.164C358.907 215.477 357.591 219.939 358.891 223.909C360.97 227.082 367.568 225.122 371.553 222.942C375.75 225.724 381.582 224.516 384.489 220.264C385.32 221.323 386.466 221.863 387.666 221.969C387.793 223.144 387.939 224.13 388.027 224.761C388.427 227.654 392.777 231.843 392.826 234.762C392.919 240.262 388.692 240.535 387.525 245.91C386.646 249.958 389.718 252.329 390.3 256.43C390.389 257.057 383.028 270.454 392.519 276.037C392.519 276.037 392.767 276.242 393.143 276.62C396.506 280.001 396.868 285.283 394.214 289.245C391.729 292.954 389.063 299.067 395.427 302.363C395.427 302.363 397.27 296.495 399.884 295.357C406.512 292.471 413.29 291.917 410.559 277.554C407.83 263.193 411.467 264.848 413.506 258.698Z" fill="#263238"/>
<path d="M154.258 199.467L158.904 253.349L185.89 251.194C186.058 248.043 185.833 235.854 185.833 235.854C185.833 235.854 197.061 234.108 197.2 222.882C197.267 217.43 196.487 206.162 195.668 196.012C194.759 184.751 185.025 176.282 173.746 176.941C162.067 177.623 153.253 187.811 154.258 199.467Z" fill="#FFBE9D"/>
<path d="M185.852 235.691C185.852 235.691 178.757 236.268 171.216 231.532C171.216 231.532 174.676 239.611 185.878 238.6L185.852 235.691Z" fill="#EB996E"/>
<path d="M191.712 203.472C191.742 204.319 191.05 205.042 190.166 205.09C189.284 205.137 188.543 204.491 188.513 203.644C188.483 202.797 189.175 202.073 190.057 202.026C190.941 201.978 191.682 202.625 191.712 203.472Z" fill="#263238"/>
<path d="M193.268 201.669C193.069 201.878 191.855 200.979 190.129 200.984C188.406 200.964 187.14 201.851 186.953 201.64C186.861 201.547 187.064 201.169 187.615 200.768C188.158 200.368 189.083 199.98 190.158 199.984C191.233 199.99 192.137 200.385 192.656 200.79C193.187 201.197 193.366 201.576 193.268 201.669Z" fill="#263238"/>
<path d="M174.423 203.877C174.453 204.724 173.76 205.447 172.877 205.494C171.995 205.541 171.254 204.895 171.224 204.049C171.194 203.202 171.886 202.478 172.768 202.431C173.653 202.383 174.393 203.03 174.423 203.877Z" fill="#263238"/>
<path d="M176.311 201.991C176.112 202.2 174.896 201.302 173.172 201.306C171.449 201.286 170.182 202.173 169.995 201.962C169.903 201.869 170.104 201.491 170.657 201.09C171.2 200.69 172.125 200.302 173.2 200.306C174.275 200.312 175.179 200.708 175.698 201.113C176.227 201.52 176.408 201.898 176.311 201.991Z" fill="#263238"/>
<path d="M182.572 214.785C182.564 214.687 183.636 214.502 185.374 214.279C185.814 214.234 186.232 214.146 186.307 213.845C186.411 213.527 186.225 213.052 186.012 212.539C185.598 211.473 185.165 210.356 184.71 209.185C182.901 204.409 181.591 200.482 181.784 200.411C181.977 200.34 183.6 204.153 185.409 208.929C185.844 210.107 186.26 211.229 186.656 212.304C186.823 212.805 187.11 213.372 186.885 214.033C186.767 214.362 186.443 214.601 186.161 214.677C185.879 214.763 185.631 214.768 185.413 214.781C183.664 214.875 182.578 214.883 182.572 214.785Z" fill="#263238"/>
<path d="M177.495 215.979C177.776 215.963 177.779 217.835 179.382 219.164C180.982 220.497 182.978 220.287 182.993 220.549C183.019 220.667 182.548 220.913 181.706 220.936C180.88 220.964 179.673 220.699 178.692 219.886C177.714 219.071 177.294 217.983 177.23 217.215C177.157 216.432 177.367 215.968 177.495 215.979Z" fill="#263238"/>
<path d="M176.757 195.23C176.587 195.699 174.864 195.477 172.841 195.721C170.812 195.924 169.18 196.517 168.911 196.096C168.791 195.895 169.076 195.453 169.736 194.999C170.39 194.546 171.434 194.115 172.649 193.979C173.864 193.846 174.977 194.039 175.714 194.339C176.456 194.639 176.83 195.008 176.757 195.23Z" fill="#263238"/>
<path d="M192.634 196.676C192.331 197.068 191.142 196.661 189.713 196.642C188.285 196.573 187.074 196.905 186.795 196.495C186.673 196.296 186.863 195.904 187.392 195.533C187.913 195.164 188.785 194.862 189.766 194.891C190.748 194.922 191.599 195.277 192.096 195.679C192.602 196.082 192.768 196.485 192.634 196.676Z" fill="#263238"/>
<path d="M166.447 187.157C166.426 187.173 166.406 187.19 166.386 187.206C164.598 188.618 163.417 190.661 163.103 192.917C163.092 192.996 163.082 193.072 163.072 193.144C162.633 196.346 162.712 199.603 162.242 202.801C161.772 205.999 160.664 209.252 158.3 211.455C157.327 212.361 155.607 212.985 154.808 211.923C154.499 211.512 154.448 210.969 154.408 210.456C153.81 202.724 151.698 195.865 151.712 187.78C151.716 185.579 152.752 183.514 154.54 182.232C156.233 181.019 158.3 179.761 160.18 180.651C162.066 181.544 164.498 185.063 165.957 186.556" fill="#263238"/>
<path d="M158.043 204.547C157.852 204.464 150.329 202.266 150.574 209.94C150.819 217.615 158.474 215.786 158.48 215.565C158.487 215.345 158.043 204.547 158.043 204.547Z" fill="#FFBE9D"/>
<path d="M156.045 212.733C156.01 212.709 155.914 212.831 155.691 212.942C155.474 213.05 155.097 213.132 154.692 212.995C153.872 212.723 153.148 211.458 153.066 210.076C153.024 209.378 153.149 208.708 153.379 208.15C153.595 207.581 153.946 207.177 154.352 207.092C154.755 206.985 155.052 207.22 155.153 207.433C155.263 207.644 155.219 207.799 155.26 207.811C155.282 207.829 155.421 207.677 155.349 207.362C155.312 207.211 155.217 207.033 155.032 206.891C154.843 206.747 154.57 206.68 154.286 206.719C153.696 206.779 153.18 207.34 152.927 207.954C152.642 208.571 152.485 209.324 152.532 210.108C152.631 211.653 153.468 213.075 154.588 213.36C155.132 213.484 155.578 213.306 155.804 213.118C156.037 212.919 156.072 212.746 156.045 212.733Z" fill="#EB996E"/>
<path d="M159.137 183.056C156.793 180.317 161.223 175.157 164.779 173.388C168.336 171.62 173.551 172.669 177.47 173.318C179.942 173.728 182.455 174.447 184.912 173.956C186.56 173.627 188.048 172.775 189.526 171.975C191.004 171.176 192.552 170.402 194.226 170.255C195.9 170.107 197.744 170.72 198.614 172.158C199.484 173.596 198.915 175.842 197.307 176.33C199.224 174.847 202.498 175.885 203.211 178.201C203.925 180.517 201.804 183.217 199.385 183.074C200.335 182.087 202.2 183.177 202.279 184.544C202.358 185.911 201.291 187.083 200.138 187.821C197.195 189.707 193.439 189.843 189.992 189.257C186.546 188.67 183.241 187.432 179.811 186.759C176.381 186.086 170.613 184.819 167.537 186.479C165.272 187.701 164.058 190.852 162.604 190.428C161.198 190.018 160.153 188.778 159.668 187.396C159.182 186.015 159.157 184.52 159.137 183.056Z" fill="#263238"/>
<path d="M156.089 204.212C156.117 204.238 156.42 204.001 156.71 203.392C157.003 202.792 157.232 201.804 157.093 200.61C156.923 198.137 154.737 195.483 154.458 192.067C154.288 190.38 154.584 188.735 155.307 187.486C156.024 186.241 157.148 185.43 158.159 185.022C159.181 184.597 160.108 184.593 160.721 184.673C161.338 184.771 161.669 184.915 161.698 184.854C161.716 184.823 161.426 184.583 160.783 184.384C160.147 184.205 159.122 184.119 157.973 184.518C156.837 184.901 155.558 185.736 154.705 187.13C153.858 188.51 153.519 190.327 153.702 192.138C153.858 193.959 154.521 195.606 155.182 196.976C155.839 198.352 156.388 199.584 156.56 200.684C156.966 202.933 155.976 204.175 156.089 204.212Z" fill="#455A64"/>
<path d="M189.345 164.787C189.358 164.776 189.463 164.877 189.648 165.09C189.812 165.315 190.052 165.664 190.224 166.177C190.568 167.174 190.642 168.901 189.569 170.561C188.49 172.205 186.261 173.386 183.728 173.562C181.171 173.766 178.459 173.001 175.706 172.294C174.322 171.946 172.962 171.657 171.637 171.558C170.312 171.454 169.033 171.583 167.857 171.9C165.503 172.542 163.621 173.829 162.334 175.148C161.017 176.459 160.293 177.811 159.863 178.751C159.431 179.7 159.315 180.276 159.255 180.259C159.237 180.255 159.261 180.111 159.323 179.84C159.376 179.566 159.484 179.169 159.675 178.674C160.055 177.693 160.747 176.276 162.068 174.892C163.36 173.502 165.282 172.133 167.73 171.437C168.948 171.094 170.3 170.948 171.673 171.05C173.047 171.146 174.438 171.44 175.832 171.79C178.606 172.505 181.249 173.256 183.693 173.084C186.116 172.94 188.225 171.855 189.264 170.354C190.302 168.836 190.302 167.202 190.03 166.236C189.742 165.241 189.295 164.819 189.345 164.787Z" fill="#263238"/>
<path d="M148.836 436.676L133.697 417.354C133.697 417.354 108.973 426.253 105.827 422.954L142.237 392.255L167.061 421.393L148.836 436.676Z" fill="#455A64"/>
<path d="M146.286 409.439C146.828 408.652 146.647 407.449 145.884 406.875C145.12 406.301 143.895 406.474 143.339 407.252C142.783 408.029 142.956 409.48 143.779 409.965C144.602 410.45 146.029 410.167 146.383 409.28" fill="#E0E0E0"/>
<path d="M142.237 392.255L144.624 395.29L108.822 423.589C108.822 423.589 106.607 424.25 105.827 422.954L142.237 392.255Z" fill="#E0E0E0"/>
<path d="M133.263 418.037C133.119 417.854 133.94 417.024 134.333 415.715C134.76 414.416 134.562 413.265 134.785 413.198C134.978 413.094 135.609 414.381 135.106 415.958C134.616 417.538 133.362 418.233 133.263 418.037Z" fill="#263238"/>
<path d="M128.113 419.677C127.927 419.539 128.442 418.587 128.412 417.326C128.419 416.064 127.886 415.123 128.069 414.981C128.212 414.812 129.214 415.75 129.221 417.318C129.243 418.887 128.26 419.844 128.113 419.677Z" fill="#263238"/>
<path d="M122.091 416.935C122.221 416.761 123.217 417.441 123.581 418.799C123.955 420.155 123.441 421.246 123.241 421.161C123.017 421.1 123.117 420.122 122.8 419.011C122.51 417.895 121.929 417.102 122.091 416.935Z" fill="#263238"/>
<path d="M137.202 422.057C137.14 421.832 138.162 421.39 139.11 420.474C140.076 419.578 140.574 418.581 140.795 418.655C141.006 418.697 140.798 419.999 139.667 421.061C138.543 422.133 137.232 422.271 137.202 422.057Z" fill="#263238"/>
<path d="M140.328 425.899C140.264 425.827 140.874 425.125 142.159 424.371C142.799 423.997 143.612 423.624 144.561 423.348C145.035 423.211 145.543 423.098 146.077 423.021C146.336 422.987 146.626 422.928 146.989 422.986C147.342 423.005 147.852 423.382 147.868 423.863C147.917 424.3 147.69 424.699 147.432 424.926C147.171 425.161 146.908 425.29 146.669 425.408C146.181 425.651 145.687 425.838 145.207 425.975C144.245 426.245 143.342 426.321 142.595 426.289C141.091 426.219 140.243 425.798 140.274 425.712C140.305 425.586 141.185 425.795 142.59 425.716C143.289 425.676 144.117 425.546 144.989 425.261C145.424 425.116 145.869 424.932 146.308 424.701C146.779 424.477 147.11 424.203 147.064 423.948C147.045 423.852 147.028 423.822 146.852 423.777C146.681 423.743 146.439 423.773 146.183 423.805C145.684 423.867 145.206 423.957 144.756 424.067C143.855 424.287 143.067 424.584 142.423 424.878C141.134 425.468 140.406 425.998 140.328 425.899Z" fill="#263238"/>
<path d="M140.345 425.449C140.385 425.369 141.275 425.708 142.311 426.805C142.823 427.351 143.347 428.093 143.746 429.011C143.942 429.471 144.106 429.974 144.215 430.51C144.305 431.05 144.451 431.751 143.828 432.411C143.479 432.728 142.943 432.699 142.638 432.544C142.31 432.399 142.108 432.208 141.887 432.034C141.461 431.665 141.118 431.241 140.85 430.807C140.311 429.935 140.085 429.023 140.04 428.267C139.956 426.724 140.519 425.886 140.585 425.933C140.719 425.972 140.389 426.838 140.609 428.204C140.718 428.877 140.976 429.668 141.477 430.404C141.726 430.771 142.032 431.125 142.399 431.429C142.782 431.768 143.191 431.958 143.302 431.796C143.485 431.646 143.547 431.095 143.436 430.661C143.348 430.177 143.215 429.717 143.054 429.292C142.727 428.442 142.296 427.728 141.873 427.175C141.022 426.063 140.271 425.558 140.345 425.449Z" fill="#263238"/>
<path d="M111.187 449.499L96.0477 430.177C96.0477 430.177 71.3237 439.076 68.1777 435.777L104.588 405.078L129.412 434.216L111.187 449.499Z" fill="#455A64"/>
<path d="M108.637 422.262C109.179 421.475 108.998 420.272 108.234 419.698C107.47 419.124 106.245 419.297 105.689 420.075C105.133 420.852 105.306 422.303 106.129 422.788C106.952 423.273 108.379 422.99 108.733 422.103" fill="#E0E0E0"/>
<path d="M104.588 405.079L106.975 408.114L71.1727 436.413C71.1727 436.413 68.9577 437.074 68.1777 435.778L104.588 405.079Z" fill="#E0E0E0"/>
<path d="M95.6134 430.86C95.4694 430.677 96.2904 429.847 96.6834 428.538C97.1104 427.239 96.9124 426.089 97.1354 426.021C97.3284 425.917 97.9594 427.204 97.4564 428.781C96.9664 430.362 95.7124 431.056 95.6134 430.86Z" fill="#263238"/>
<path d="M90.4631 432.501C90.2771 432.363 90.7921 431.411 90.7621 430.15C90.7691 428.888 90.2361 427.947 90.4191 427.805C90.5621 427.636 91.5641 428.574 91.5711 430.142C91.5931 431.711 90.6101 432.667 90.4631 432.501Z" fill="#263238"/>
<path d="M84.4411 429.759C84.5711 429.585 85.5671 430.265 85.9311 431.623C86.3051 432.98 85.7911 434.07 85.5911 433.986C85.3671 433.924 85.4671 432.947 85.1501 431.836C84.8601 430.718 84.2791 429.925 84.4411 429.759Z" fill="#263238"/>
<path d="M99.5525 434.881C99.4905 434.656 100.512 434.213 101.46 433.297C102.426 432.4 102.924 431.404 103.145 431.478C103.356 431.52 103.148 432.823 102.017 433.885C100.893 434.957 99.5825 435.094 99.5525 434.881Z" fill="#263238"/>
<path d="M102.679 438.722C102.615 438.65 103.225 437.948 104.51 437.194C105.15 436.82 105.963 436.447 106.912 436.171C107.386 436.034 107.894 435.921 108.428 435.844C108.687 435.811 108.977 435.751 109.34 435.809C109.693 435.828 110.203 436.205 110.219 436.686C110.268 437.123 110.041 437.522 109.783 437.749C109.522 437.984 109.259 438.113 109.019 438.231C108.531 438.474 108.037 438.661 107.558 438.798C106.595 439.068 105.693 439.144 104.946 439.112C103.442 439.042 102.594 438.621 102.625 438.535C102.656 438.408 103.536 438.618 104.941 438.539C105.64 438.499 106.468 438.369 107.34 438.084C107.775 437.939 108.22 437.755 108.659 437.524C109.13 437.3 109.46 437.026 109.415 436.771C109.396 436.675 109.379 436.645 109.203 436.601C109.032 436.567 108.79 436.597 108.534 436.629C108.035 436.691 107.557 436.781 107.107 436.891C106.206 437.111 105.417 437.408 104.774 437.702C103.484 438.29 102.757 438.821 102.679 438.722Z" fill="#263238"/>
<path d="M102.694 438.272C102.734 438.191 103.624 438.531 104.66 439.628C105.172 440.173 105.696 440.916 106.095 441.834C106.291 442.294 106.454 442.797 106.564 443.333C106.654 443.873 106.8 444.574 106.177 445.234C105.828 445.551 105.292 445.522 104.986 445.367C104.658 445.222 104.456 445.031 104.235 444.857C103.809 444.488 103.466 444.064 103.198 443.63C102.659 442.758 102.433 441.846 102.389 441.09C102.305 439.547 102.868 438.709 102.933 438.756C103.068 438.795 102.737 439.661 102.957 441.027C103.066 441.7 103.324 442.491 103.825 443.227C104.074 443.594 104.38 443.948 104.747 444.252C105.13 444.591 105.539 444.781 105.65 444.619C105.833 444.469 105.896 443.918 105.784 443.484C105.696 443 105.563 442.54 105.402 442.115C105.075 441.265 104.644 440.551 104.221 439.998C103.372 438.887 102.62 438.381 102.694 438.272Z" fill="#263238"/>
<path d="M128.32 128.553C128.688 128.452 130.356 141.916 129.465 143.698C128.574 145.48 122.72 150.698 120.429 150.698C118.138 150.698 115.084 154.007 114.447 155.152L99.6836 152.098L102.993 143.953L102.595 133.714C102.512 131.583 102.985 129.456 104.034 127.599C105.178 125.577 106.796 123.469 108.337 124.099C111.137 125.244 111.137 126.135 111.137 126.135C111.137 126.135 113.781 123.717 114.686 126.517C114.686 126.517 118.137 123.972 119.791 127.281C119.791 127.281 123.609 124.99 124.627 130.208L125.009 133.771C125.011 133.772 124.629 129.572 128.32 128.553Z" fill="#FFBE9D"/>
<path d="M116.353 144.776C116.308 144.742 116.815 143.945 117.939 142.925C119.052 141.911 120.819 140.693 123.036 139.981C123.826 139.722 124.611 139.543 125.344 139.432L125.133 139.682C125.145 137.98 124.948 136.521 124.92 135.455C124.891 134.396 124.955 133.772 125.009 133.771C125.073 133.77 125.118 134.393 125.233 135.432C125.346 136.464 125.612 137.941 125.632 139.675L125.634 139.888L125.42 139.924C124.714 140.045 123.958 140.225 123.196 140.475C121.057 141.162 119.323 142.286 118.175 143.204C117.022 144.127 116.419 144.833 116.353 144.776Z" fill="#EB996E"/>
<path d="M107.427 129.667C107.458 129.657 107.606 129.937 107.788 130.484C107.935 131.018 108.258 131.889 107.85 132.949C107.648 133.493 107.071 133.833 106.951 134.297C106.804 134.78 106.993 135.399 107.396 135.837C108.188 136.741 110.013 136.775 110.909 135.688C111.355 135.146 111.521 134.42 111.593 133.719C111.659 133.012 111.667 132.302 111.669 131.638C111.667 130.303 111.574 129.103 111.46 128.098C111.361 127.093 111.172 126.306 110.887 125.835C110.609 125.361 110.32 125.204 110.347 125.165C110.352 125.142 110.694 125.241 111.056 125.724C111.421 126.201 111.678 127.038 111.824 128.049C111.986 129.058 112.119 130.276 112.148 131.633C112.159 132.314 112.162 133.018 112.1 133.77C112.03 134.511 111.861 135.343 111.312 136.018C110.164 137.37 108.04 137.299 107.039 136.16C106.551 135.603 106.32 134.841 106.539 134.168C106.648 133.831 106.885 133.575 107.075 133.385C107.27 133.189 107.412 133.009 107.515 132.797C107.905 131.935 107.686 131.072 107.592 130.538C107.473 129.986 107.389 129.679 107.427 129.667Z" fill="#EB996E"/>
<path d="M110.903 136.02C110.949 136.008 110.996 136.246 111.246 136.559C111.484 136.871 111.98 137.24 112.677 137.332C113.366 137.442 114.21 137.107 114.827 136.406C115.463 135.712 115.809 134.692 115.918 133.58C116.194 131.321 115.706 129.302 115.204 127.903C114.704 126.5 114.188 125.721 114.256 125.675C114.303 125.636 114.941 126.359 115.546 127.769C116.149 129.151 116.724 131.293 116.435 133.637C116.324 134.804 115.926 135.961 115.18 136.731C114.464 137.513 113.427 137.879 112.619 137.696C111.815 137.538 111.292 137.061 111.078 136.674C110.854 136.282 110.879 136.021 110.903 136.02Z" fill="#EB996E"/>
<path d="M115.363 136.374C115.402 136.354 115.505 136.543 115.77 136.793C116.03 137.042 116.493 137.331 117.128 137.422C117.761 137.519 118.545 137.386 119.197 136.845C119.852 136.324 120.349 135.47 120.554 134.507C120.93 132.543 120.297 130.679 120.092 129.347C119.837 128.008 119.714 127.171 119.784 127.155C119.854 127.139 120.104 127.946 120.451 129.266C120.62 129.928 120.838 130.706 121.009 131.606C121.174 132.501 121.289 133.539 121.063 134.615C120.835 135.683 120.268 136.638 119.492 137.222C118.723 137.828 117.759 137.949 117.063 137.783C116.349 137.63 115.858 137.247 115.62 136.928C115.377 136.608 115.338 136.383 115.363 136.374Z" fill="#EB996E"/>
<path d="M120.561 135.988C120.6 135.984 120.646 136.216 120.756 136.619C120.87 137.015 121.042 137.609 121.483 138.174C121.89 138.729 122.741 139.203 123.605 138.995C124.451 138.776 125.008 137.71 124.944 136.63C124.955 134.341 124.689 132.271 124.437 130.789C124.186 129.304 123.947 128.399 124.023 128.375C124.084 128.354 124.45 129.229 124.798 130.716C125.146 132.199 125.475 134.295 125.464 136.634C125.531 137.821 124.902 139.174 123.721 139.461C122.579 139.696 121.62 139.052 121.187 138.393C120.731 137.723 120.615 137.08 120.557 136.658C120.505 136.229 120.528 135.991 120.561 135.988Z" fill="#EB996E"/>
<path d="M262.068 271.966C262.068 271.966 257.07 279.364 255.791 281.839C254.756 283.842 250.707 292.221 250.523 297.63L260.51 309.255C260.51 309.255 265.029 305.803 265.751 304.504C266.473 303.205 265.808 301.347 265.808 301.347C265.808 301.347 267.195 300.173 267.917 299.885C267.917 299.885 267.484 302.195 269.216 302.628C270.948 303.061 276.722 296.998 276.722 296.998C276.722 296.998 277.155 294.4 275.567 294.255C275.567 294.255 276.722 290.646 275.567 289.636C275.567 289.636 276.866 286.749 275.278 285.45C275.278 285.45 274.556 282.13 273.401 280.975C272.246 279.82 260.509 283.285 260.509 283.285C260.509 283.285 263.29 277.107 263.578 275.374C263.866 273.641 262.068 271.966 262.068 271.966Z" fill="#FFBE9D"/>
<path d="M269.293 294.769C269.319 294.794 269.201 294.963 268.964 295.243C268.723 295.528 268.376 295.893 268.125 296.479C267.989 296.782 267.837 297.075 267.828 297.416C267.806 297.759 267.878 298.142 268.044 298.488C268.317 299.196 269.366 299.327 270.116 298.814C270.885 298.31 271.564 297.579 272.22 297.034C272.831 296.504 273.409 296.003 273.954 295.53C274.951 294.685 275.592 294.189 275.641 294.242C275.689 294.295 275.136 294.887 274.202 295.802C273.681 296.301 273.128 296.83 272.544 297.389C271.889 297.963 271.284 298.663 270.386 299.26C269.936 299.543 269.361 299.71 268.827 299.614C268.297 299.557 267.776 299.149 267.612 298.692C267.418 298.253 267.354 297.799 267.4 297.378C267.438 296.941 267.649 296.59 267.799 296.312C268.111 295.707 268.55 295.327 268.823 295.1C269.102 294.864 269.267 294.744 269.293 294.769Z" fill="#EB996E"/>
<path d="M269.928 284.411C269.977 284.464 269.245 285.26 268.026 286.501C267.447 287.122 266.582 287.838 266.161 288.795C265.735 289.691 266.629 291.137 267.775 291.04C268.342 290.99 268.847 290.592 269.305 290.191C269.772 289.779 270.224 289.379 270.66 288.993C271.498 288.266 272.275 287.592 272.977 286.983C274.306 285.85 275.152 285.176 275.2 285.23C275.248 285.283 274.489 286.054 273.223 287.257C272.543 287.891 271.792 288.593 270.981 289.35C270.55 289.744 270.103 290.153 269.643 290.574C269.173 290.985 268.61 291.479 267.821 291.56C267.022 291.632 266.321 291.15 265.951 290.572C265.56 290.006 265.443 289.225 265.714 288.62C265.967 288.027 266.367 287.607 266.72 287.231C267.072 286.854 267.444 286.547 267.762 286.245C269.056 285.061 269.879 284.358 269.928 284.411Z" fill="#EB996E"/>
<path d="M267.985 291.557C267.995 291.601 267.783 291.643 267.487 291.852C267.198 292.05 266.81 292.457 266.692 293.066C266.551 293.66 266.879 294.435 267.604 294.8C267.961 294.981 268.372 295.037 268.786 294.907C269.201 294.784 269.6 294.51 269.998 294.197C273.235 291.648 275.444 289.107 275.562 289.212C275.614 289.256 275.127 289.947 274.216 290.965C273.306 291.982 271.96 293.312 270.32 294.607C269.907 294.93 269.457 295.247 268.928 295.397C268.402 295.559 267.812 295.469 267.38 295.224C266.479 294.749 266.103 293.708 266.333 292.984C266.531 292.254 267.03 291.843 267.385 291.675C267.751 291.501 267.984 291.531 267.985 291.557Z" fill="#EB996E"/>
<path d="M266.052 303.294C265.976 303.306 265.92 302.664 265.523 301.688C265.141 300.74 264.208 299.411 262.624 298.802L261.528 298.38L262.691 298.328L262.718 298.327L262.735 298.813H262.733H262.73H262.723L262.709 298.814L262.68 298.815L262.663 298.327H262.665H262.669H262.676L262.691 298.326L262.487 298.45C262.965 297.586 263.358 296.565 263.575 295.455C264.079 292.928 263.619 290.552 262.984 288.999C262.371 287.417 261.656 286.602 261.741 286.541C261.769 286.518 261.954 286.711 262.267 287.085C262.559 287.472 262.966 288.055 263.322 288.855C264.067 290.423 264.614 292.905 264.084 295.558C263.857 296.723 263.432 297.795 262.911 298.692L262.841 298.812L262.707 298.815H262.692H262.684H262.68H262.678H262.677L262.661 298.327L262.69 298.326H262.704H262.711H262.715H262.717L262.734 298.812H262.733L262.706 298.813L262.773 298.339C264.57 299.074 265.465 300.551 265.801 301.582C265.98 302.105 266.045 302.54 266.063 302.836C266.083 303.132 266.079 303.291 266.052 303.294Z" fill="#EB996E"/>
<path d="M139.855 366.724L138.365 380.134C137.657 386.508 137.678 392.943 138.429 399.312L144.284 448.999L125.544 426.766C125.544 426.766 107.503 437.578 107.267 447.943C107.267 447.943 142.635 488.101 151.586 493.754C160.537 499.407 183.621 493.754 184.563 476.088C184.563 476.088 186.683 492.341 207.176 491.87C227.669 491.399 227.904 442.405 227.904 442.405L218.953 368.678L139.855 366.724Z" fill="#263238"/>
<path d="M184.642 476.539C184.624 476.538 184.617 476.303 184.621 475.857C184.631 475.358 184.644 474.71 184.661 473.906C184.721 472.151 184.803 469.717 184.904 466.736C185.162 460.629 185.513 452.296 185.902 443.09C186.119 438.475 186.325 434.081 186.514 430.074C186.75 426.083 186.742 422.455 187.427 419.454C188.077 416.442 189.624 414.312 190.965 413.26C191.63 412.717 192.23 412.41 192.645 412.242C192.85 412.149 193.017 412.106 193.126 412.07C193.236 412.037 193.294 412.025 193.297 412.033C193.331 412.097 192.37 412.313 191.096 413.415C189.827 414.493 188.378 416.579 187.786 419.534C187.161 422.47 187.202 426.105 186.993 430.1C186.817 434.108 186.625 438.502 186.422 443.118C185.974 452.321 185.569 460.651 185.272 466.756C185.102 469.733 184.964 472.164 184.864 473.918C184.807 474.72 184.762 475.366 184.727 475.864C184.688 476.307 184.66 476.54 184.642 476.539Z" fill="#455A64"/>
<path d="M190.131 367.789C190.167 367.784 190.262 368.212 190.404 368.992C190.548 369.771 190.729 370.903 190.929 372.306C191.33 375.11 191.781 379.001 192.097 383.313C192.41 387.627 192.529 391.542 192.539 394.375C192.545 395.792 192.53 396.938 192.501 397.73C192.473 398.522 192.441 398.96 192.405 398.959C192.369 398.959 192.333 398.521 192.298 397.731C192.264 396.833 192.221 395.713 192.171 394.383C192.067 391.557 191.89 387.654 191.578 383.351C191.263 379.049 190.871 375.163 190.564 372.351C190.421 371.028 190.301 369.913 190.204 369.02C190.123 368.233 190.095 367.795 190.131 367.789Z" fill="#455A64"/>
<path d="M182.961 371.114C182.947 371.092 183.108 370.943 183.484 370.861C183.852 370.777 184.453 370.815 185.043 371.197C185.332 371.389 185.622 371.664 185.81 372.048C185.998 372.429 186.067 372.91 185.959 373.375C185.76 374.291 184.937 375.205 183.788 375.289C182.643 375.337 181.72 374.555 181.365 373.701C180.975 372.811 181.256 371.886 181.661 371.349C182.069 370.787 182.578 370.51 182.934 370.4C183.296 370.289 183.507 370.313 183.51 370.341C183.555 370.427 182.64 370.509 181.967 371.554C181.65 372.055 181.47 372.809 181.804 373.508C182.111 374.196 182.894 374.814 183.758 374.769C184.616 374.717 185.318 373.988 185.491 373.266C185.697 372.521 185.306 371.843 184.831 371.499C184.355 371.139 183.844 371.046 183.509 371.063C183.168 371.075 182.979 371.154 182.961 371.114Z" fill="#455A64"/>
<path d="M144.699 448.839C144.789 448.863 144.388 450.35 144.032 452.798C143.672 455.242 143.442 458.668 143.8 462.421C144.169 466.175 145.052 469.493 145.874 471.823C146.693 474.157 147.371 475.539 147.288 475.58C147.256 475.596 147.062 475.264 146.737 474.646C146.404 474.033 145.986 473.112 145.529 471.95C144.61 469.631 143.656 466.278 143.282 462.471C142.919 458.665 143.212 455.191 143.669 452.739C143.895 451.511 144.129 450.527 144.338 449.861C144.537 449.193 144.664 448.83 144.699 448.839Z" fill="#455A64"/>
<path d="M144.344 470.751C144.332 470.714 144.708 470.661 145.307 470.316C145.897 469.965 146.644 469.215 147.149 467.999C147.662 466.789 147.962 465.185 148.037 463.31C148.13 461.428 148.224 459.285 148.237 456.914C148.292 452.173 148.099 446.523 147.549 440.277C147.003 434.03 146.099 427.191 144.918 420.049C143.744 412.903 142.496 406.106 141.717 399.866C140.902 393.633 140.548 387.963 140.661 383.205C140.763 378.447 141.244 374.613 141.76 372.004C141.975 370.692 142.275 369.699 142.423 369.016C142.503 368.701 142.568 368.448 142.619 368.245C142.666 368.072 142.694 367.985 142.703 367.987C142.712 367.989 142.701 368.081 142.671 368.258C142.633 368.464 142.585 368.721 142.526 369.04C142.411 369.73 142.145 370.727 141.961 372.04C141.507 374.651 141.085 378.474 141.03 383.211C140.965 387.949 141.355 393.591 142.194 399.805C142.997 406.025 144.258 412.811 145.433 419.965C146.615 427.116 147.507 433.973 148.028 440.237C148.553 446.5 148.709 452.167 148.606 456.92C148.569 459.297 148.45 461.445 148.328 463.329C148.225 465.218 147.89 466.852 147.335 468.081C146.788 469.319 145.984 470.078 145.359 470.408C144.725 470.732 144.344 470.751 144.344 470.751Z" fill="#455A64"/>
<path d="M119.339 431.293C119.363 431.266 119.762 431.59 120.462 432.199C121.157 432.813 122.147 433.721 123.32 434.897C125.679 437.232 128.719 440.697 131.624 444.853C133.086 446.924 134.38 448.963 135.53 450.829C136.67 452.695 137.753 454.333 138.872 455.53C139.971 456.746 141.107 457.464 141.936 457.84C142.776 458.202 143.287 458.287 143.275 458.341C143.272 458.359 143.144 458.346 142.901 458.306C142.655 458.278 142.306 458.176 141.859 458.028C140.983 457.701 139.769 457 138.605 455.784C137.422 454.588 136.288 452.938 135.123 451.083C133.952 449.234 132.652 447.213 131.198 445.152C128.309 441.019 125.338 437.544 123.056 435.153C121.922 433.95 120.975 433.004 120.324 432.346C119.667 431.694 119.315 431.32 119.339 431.293Z" fill="#455A64"/>
<path d="M147.335 233.963C147.335 233.963 135.795 229.636 130.506 225.308C125.217 220.981 110.131 196.846 110.131 196.846L117.757 153.32L99.1744 144.087C99.1744 144.087 77.5964 195.88 80.0004 205.497C82.4044 215.114 121.407 268.614 121.407 268.614L130.987 306.568L141.084 340.707C141.084 340.707 136.757 342.63 139.161 349.362C141.565 356.094 144.661 359.017 141.565 360.421C136.528 362.706 139.853 366.725 139.853 366.725L220.992 368.716C220.992 368.716 210.602 302.845 212.392 290.813C214.217 278.545 212.454 257.623 212.454 257.623L193.976 251.994L147.335 233.963Z" fill="#DDFFA1"/>
<path d="M212.454 257.623C220.875 261.489 229.836 316.364 229.836 316.364L249.486 295.462L261.49 310.593C261.49 310.593 244.513 340.404 240.617 343.441C237.326 346.006 227.405 353.749 220.723 351.102C214.041 348.455 209.869 300.989 209.869 300.989L212.454 257.623Z" fill="#DDFFA1"/>
<path d="M156.052 238.688C156.052 238.688 155.064 232.279 157.172 233.245C157.259 233.285 186.968 242.619 186.761 244.688C186.586 246.44 186.968 251.995 186.968 251.995H167.361L156.052 238.688Z" fill="#DDFFA1"/>
<path d="M137.589 280.412C137.553 280.409 137.552 279.84 137.58 278.818C137.6 278.307 137.617 277.681 137.599 276.955C137.587 276.229 137.608 275.402 137.544 274.49C137.482 272.664 137.277 270.497 136.926 268.111C136.543 265.729 135.993 263.131 135.209 260.459C134.402 257.793 133.44 255.317 132.457 253.114C131.445 250.924 130.429 248.998 129.482 247.436C129.036 246.638 128.566 245.958 128.179 245.343C127.796 244.725 127.44 244.211 127.143 243.794C126.56 242.953 126.25 242.477 126.278 242.455C126.306 242.433 126.671 242.868 127.306 243.675C127.629 244.075 128.011 244.574 128.419 245.18C128.831 245.782 129.326 246.453 129.795 247.244C130.789 248.792 131.847 250.715 132.891 252.913C133.907 255.124 134.892 257.619 135.706 260.31C136.497 263.007 137.038 265.633 137.398 268.04C137.727 270.451 137.894 272.64 137.91 274.479C137.95 275.398 137.903 276.23 137.888 276.96C137.878 277.69 137.831 278.317 137.78 278.829C137.692 279.852 137.625 280.415 137.589 280.412Z" fill="#087D4C"/>
<path d="M153.11 351.247C153.085 351.304 152.193 350.99 150.844 350.28C149.495 349.572 147.708 348.429 145.947 346.9C144.189 345.366 142.81 343.753 141.923 342.515C141.033 341.276 140.6 340.437 140.652 340.404C140.79 340.304 142.805 343.502 146.289 346.507C149.744 349.542 153.19 351.097 153.11 351.247Z" fill="#087D4C"/>
<path d="M177.963 360.603C177.974 360.637 177.511 360.804 176.662 361.077C175.817 361.361 174.567 361.689 173.007 362.017C171.448 362.354 169.564 362.629 167.461 362.811C165.356 362.965 163.031 363.01 160.598 362.856C158.165 362.683 155.866 362.328 153.801 361.894C151.741 361.433 149.911 360.908 148.411 360.366C146.909 359.832 145.714 359.34 144.915 358.946C144.111 358.562 143.674 358.335 143.69 358.303C143.705 358.27 144.17 358.435 144.995 358.76C145.815 359.095 147.023 359.532 148.528 360.017C150.032 360.511 151.855 360.994 153.9 361.425C155.951 361.829 158.227 362.166 160.633 362.338C163.04 362.49 165.34 362.46 167.426 362.334C169.51 362.18 171.381 361.946 172.938 361.657C174.495 361.377 175.75 361.106 176.608 360.884C177.469 360.669 177.952 360.568 177.963 360.603Z" fill="#087D4C"/>
<path d="M204.993 310.593C205.011 310.591 205.039 310.758 205.077 311.077C205.107 311.397 205.18 311.868 205.2 312.479C205.279 313.698 205.278 315.476 205.035 317.653C204.58 321.983 202.861 328.013 198.88 333.45C194.896 338.888 189.663 342.344 185.672 344.084C183.669 344.971 181.975 345.508 180.789 345.801C180.201 345.966 179.73 346.038 179.416 346.106C179.1 346.166 178.933 346.189 178.929 346.171C178.919 346.128 179.572 345.959 180.734 345.605C181.896 345.254 183.557 344.669 185.519 343.749C189.429 341.942 194.541 338.49 198.461 333.142C202.377 327.794 204.127 321.88 204.671 317.607C204.957 315.459 205.015 313.699 204.998 312.485C204.985 311.27 204.949 310.596 204.993 310.593Z" fill="#087D4C"/>
<path d="M212.579 275.64C212.597 275.641 212.595 275.943 212.574 276.514C212.545 277.13 212.507 277.962 212.458 279.01C212.385 280.092 212.349 281.418 212.327 282.959C212.284 284.499 212.254 286.252 212.29 288.191C212.283 292.068 212.503 296.682 212.816 301.798C213.172 306.912 213.699 312.526 214.358 318.407C215.71 330.17 217.256 340.783 218.274 348.478C218.786 352.325 219.191 355.44 219.438 357.597C219.555 358.64 219.647 359.468 219.716 360.081C219.774 360.65 219.795 360.949 219.778 360.952C219.76 360.954 219.704 360.659 219.612 360.095C219.52 359.485 219.395 358.661 219.238 357.624C218.917 355.427 218.466 352.333 217.91 348.529C216.799 340.848 215.195 330.242 213.842 318.466C213.182 312.578 212.669 306.955 212.338 301.83C212.051 296.703 211.869 292.078 211.923 288.192C211.91 286.249 211.966 284.493 212.037 282.951C212.087 281.408 212.152 280.081 212.256 278.999C212.346 277.953 212.417 277.122 212.47 276.507C212.524 275.936 212.561 275.638 212.579 275.64Z" fill="#087D4C"/>
<path d="M186.771 249.209C186.779 249.238 186.34 249.391 185.521 249.579C184.703 249.768 183.493 249.95 181.985 249.981C180.478 250.018 178.679 249.84 176.733 249.417C174.789 248.982 172.707 248.292 170.597 247.403C166.365 245.611 162.884 243.321 160.416 241.644C159.183 240.798 158.201 240.092 157.53 239.594C156.859 239.096 156.498 238.806 156.518 238.776C156.538 238.746 156.938 238.98 157.644 239.427C158.434 239.934 159.431 240.574 160.619 241.337C163.133 242.933 166.622 245.156 170.798 246.923C172.887 247.803 174.935 248.497 176.84 248.95C178.747 249.391 180.505 249.603 181.985 249.613C184.953 249.644 186.751 249.098 186.771 249.209Z" fill="#087D4C"/>
<path d="M227.052 330.759C226.911 330.732 227.432 327.357 228.215 323.221C228.999 319.083 229.749 315.752 229.89 315.779C230.031 315.806 229.51 319.18 228.727 323.318C227.943 327.454 227.193 330.786 227.052 330.759Z" fill="#087D4C"/>
<g opacity="0.3">
<path d="M180.399 345.753C177.664 346.632 174.836 347.222 171.978 347.509C170.724 347.635 169.337 347.666 168.347 346.887C167.289 346.054 167.032 344.461 167.527 343.209C168.023 341.957 169.138 341.032 170.362 340.471C174.758 338.46 180.411 340.617 184.489 338.021C186.537 336.717 187.739 334.469 189.186 332.519C191.663 329.18 195.072 326.541 198.925 324.98C199.471 324.759 200.085 324.558 200.636 324.766C201.874 325.233 201.613 327.057 201.049 328.254C196.886 337.073 189.961 343.84 180.399 345.753Z" fill="black"/>
</g>
<g opacity="0.3">
<path d="M212.342 290.944C218.321 305.556 216.218 322.865 223.927 336.643C225.39 339.258 227.226 341.809 227.567 344.786C227.909 347.763 225.96 351.282 222.963 351.263C221.111 351.252 219.184 350.112 218.33 348.468C217.476 346.824 217.546 344.704 217.334 342.864C216.031 331.58 214.17 320.286 213.129 308.975C212.579 302.995 212.755 297.006 212.278 291.02" fill="black"/>
</g>
<g opacity="0.3">
<path d="M157.722 239.66C162.251 244.945 167.724 249.418 173.805 252.804C175.308 253.641 176.872 254.42 178.561 254.743C180.25 255.065 182.096 254.886 183.521 253.923C184.146 253.5 184.692 252.907 184.884 252.178C185.077 251.449 184.842 250.579 184.205 250.175C183.762 249.894 183.211 249.869 182.688 249.841C173.657 249.356 164.991 244.637 157.722 239.66Z" fill="black"/>
</g>
<path d="M165.703 244.494C165.598 244.571 164.614 242.762 164.719 240.305C164.8 237.846 165.918 236.117 166.017 236.202C166.164 236.267 165.31 238.005 165.239 240.324C165.135 242.641 165.854 244.44 165.703 244.494Z" fill="#087D4C"/>
<path d="M175.828 248.6C175.675 248.563 176.141 246.572 176.075 244.086C176.031 241.598 175.477 239.631 175.629 239.587C175.748 239.535 176.548 241.497 176.596 244.075C176.662 246.651 175.95 248.647 175.828 248.6Z" fill="#087D4C"/>
<path d="M182.971 250.283C182.826 250.223 183.435 248.703 183.542 246.73C183.673 244.758 183.252 243.176 183.403 243.133C183.522 243.077 184.197 244.674 184.061 246.761C183.945 248.849 183.082 250.353 182.971 250.283Z" fill="#087D4C"/>
<path d="M158.825 240.455C158.717 240.543 157.824 239.072 158.008 237.048C158.11 236.066 158.363 235.173 158.836 234.641C159.066 234.381 159.351 234.242 159.556 234.239C159.76 234.232 159.854 234.3 159.848 234.329C159.839 234.402 159.433 234.369 159.125 234.868C158.814 235.332 158.619 236.182 158.527 237.092C158.351 238.948 158.967 240.4 158.825 240.455Z" fill="#087D4C"/>
<path d="M101.969 200.008C101.887 199.905 103.568 198.576 106.025 197.678C108.477 196.764 110.618 196.681 110.622 196.813C110.642 196.968 108.585 197.278 106.205 198.167C103.82 199.037 102.055 200.138 101.969 200.008Z" fill="#087D4C"/>
<path d="M211.447 294.951C211.447 294.951 211.155 294.891 210.605 294.756C210.01 294.608 209.209 294.409 208.2 294.159C207.158 293.892 205.88 293.574 204.388 293.243C202.897 292.906 201.207 292.491 199.32 292.117C195.559 291.306 191.054 290.471 186.031 289.673C181.003 288.907 175.455 288.197 169.609 287.672C163.759 287.171 158.17 286.904 153.085 286.783C147.999 286.694 143.417 286.732 139.572 286.877C137.649 286.917 135.912 287.032 134.385 287.104C132.858 287.17 131.545 287.261 130.472 287.343C129.435 287.414 128.611 287.471 128 287.512C127.435 287.548 127.137 287.557 127.137 287.557C127.137 287.557 131.873 287.038 139.567 286.73C143.413 286.567 147.999 286.513 153.089 286.592C158.179 286.702 163.772 286.964 169.627 287.466C175.478 287.991 181.031 288.707 186.061 289.484C191.086 290.293 195.592 291.144 199.351 291.974C206.873 293.615 211.447 294.951 211.447 294.951Z" fill="#FAFAFA"/>
<path d="M203.195 324.679C203.195 324.679 202.962 324.657 202.521 324.596C202.028 324.525 201.388 324.433 200.594 324.319C199.759 324.184 198.732 324.07 197.539 323.931C196.348 323.779 194.988 323.64 193.482 323.512C187.464 322.942 179.114 322.581 169.89 322.608C160.663 322.652 152.315 323.031 146.283 323.43C143.266 323.617 140.827 323.816 139.142 323.953C138.342 324.018 137.698 324.071 137.202 324.112C136.758 324.146 136.524 324.153 136.524 324.153C136.524 324.153 136.755 324.117 137.198 324.07C137.694 324.019 138.337 323.954 139.135 323.872C140.818 323.71 143.256 323.489 146.273 323.283C152.305 322.847 160.656 322.444 169.889 322.4C179.118 322.373 187.475 322.757 193.495 323.365C199.519 323.946 203.195 324.679 203.195 324.679Z" fill="#FAFAFA"/>
<path d="M215.192 330.699C215.191 330.713 214.993 330.71 214.637 330.693C214.158 330.663 213.66 330.632 213.13 330.599C211.644 330.482 209.99 330.353 208.167 330.209C206.345 330.044 204.693 329.893 203.208 329.758C202.681 329.702 202.185 329.649 201.707 329.598C201.353 329.556 201.158 329.525 201.159 329.511C201.16 329.497 201.358 329.5 201.714 329.517C202.193 329.547 202.691 329.578 203.22 329.611C204.706 329.728 206.36 329.857 208.184 330.001C210.005 330.166 211.657 330.317 213.142 330.451C213.669 330.507 214.165 330.56 214.643 330.611C214.998 330.654 215.193 330.684 215.192 330.699Z" fill="#FAFAFA"/>
<path d="M219.744 364.398C219.744 364.398 219.464 364.351 218.936 364.243C218.407 364.141 217.635 363.95 216.622 363.803C214.608 363.454 211.66 363.099 208.002 363.036C204.344 362.95 199.989 363.17 195.169 363.595C190.349 364.011 185.056 364.611 179.472 364.895C168.301 365.508 158.163 364.989 150.879 364.26C143.589 363.528 139.132 362.691 139.132 362.691C139.132 362.691 139.414 362.719 139.948 362.793C140.529 362.875 141.306 362.984 142.282 363.122C144.311 363.394 147.249 363.77 150.893 364.113C158.176 364.804 168.303 365.299 179.461 364.687C185.037 364.403 190.327 363.81 195.152 363.404C199.976 362.989 204.34 362.784 208.005 362.889C211.67 362.971 214.622 363.349 216.636 363.724C217.649 363.884 218.419 364.088 218.946 364.203C219.471 364.324 219.744 364.398 219.744 364.398Z" fill="#FAFAFA"/>
<path d="M217.374 270.829C217.374 270.829 217.067 270.75 216.487 270.58C215.866 270.395 215.021 270.144 213.955 269.827C211.71 269.16 208.54 268.219 204.642 267.062C196.773 264.744 185.88 261.617 173.798 258.35C161.711 255.085 150.725 252.298 142.759 250.337C138.808 249.373 135.596 248.589 133.321 248.034C132.24 247.771 131.384 247.562 130.755 247.409C130.169 247.264 129.863 247.177 129.863 247.177C129.863 247.177 130.175 247.236 130.764 247.368C131.396 247.512 132.255 247.708 133.339 247.955C135.617 248.494 138.836 249.256 142.793 250.193C150.768 252.118 161.762 254.882 173.851 258.148C185.935 261.416 196.824 264.565 204.683 266.92C208.574 268.104 211.738 269.067 213.978 269.749C215.039 270.082 215.88 270.345 216.499 270.539C217.075 270.723 217.374 270.829 217.374 270.829Z" fill="#FAFAFA"/>
<path d="M134.703 254.8C134.705 254.807 134.644 254.829 134.528 254.865C134.363 254.913 134.194 254.961 134.016 255.012C133.447 255.164 132.82 255.331 132.122 255.517C130.344 255.968 128.212 256.508 125.855 257.105C124.632 257.417 123.454 257.677 122.41 257.981C121.367 258.287 120.469 258.696 119.816 259.188C118.475 260.177 118.229 261.279 118.192 261.24C118.192 261.24 118.229 260.98 118.443 260.563C118.653 260.147 119.063 259.586 119.727 259.071C120.386 258.55 121.303 258.116 122.355 257.797C123.41 257.479 124.581 257.214 125.805 256.902C128.167 256.327 130.305 255.807 132.086 255.373C132.79 255.211 133.422 255.065 133.996 254.933C134.177 254.895 134.35 254.859 134.518 254.824C134.637 254.801 134.701 254.793 134.703 254.8Z" fill="#FAFAFA"/>
<path d="M125.672 220.854C125.672 220.861 125.561 220.875 125.349 220.895C125.075 220.918 124.769 220.943 124.42 220.972C123.613 221.026 122.447 221.15 121.016 221.352C118.154 221.75 114.229 222.594 110.092 224.136C105.958 225.69 102.444 227.632 100.024 229.212C98.8115 230 97.8505 230.671 97.2075 231.161C96.9245 231.369 96.6775 231.55 96.4565 231.713C96.2835 231.837 96.1905 231.9 96.1855 231.894C96.1855 231.894 97.5315 230.703 99.9435 229.089C102.35 227.473 105.869 225.502 110.02 223.941C114.174 222.392 118.121 221.568 120.997 221.206C123.874 220.841 125.672 220.854 125.672 220.854Z" fill="#FAFAFA"/>
<path d="M111.279 191.826C111.275 191.832 111.188 191.779 111.027 191.675C110.817 191.535 110.588 191.381 110.329 191.208C109.732 190.791 108.839 190.229 107.715 189.575C105.468 188.271 102.193 186.717 98.3442 185.729C94.4902 184.752 90.8692 184.562 88.2732 184.669C86.9742 184.722 85.9222 184.807 85.2002 184.904C84.8912 184.939 84.6172 184.97 84.3662 184.998C84.1752 185.017 84.0742 185.024 84.0732 185.017C84.0732 185.017 85.6562 184.666 88.2662 184.522C90.8712 184.377 94.5182 184.544 98.3952 185.528C102.268 186.522 105.553 188.107 107.789 189.449C110.03 190.793 111.279 191.826 111.279 191.826Z" fill="#FAFAFA"/>
<path d="M114.781 162.024C114.776 162.029 114.72 161.978 114.62 161.878C114.483 161.738 114.341 161.591 114.188 161.434C113.822 161.041 113.264 160.497 112.55 159.857C111.126 158.573 108.986 156.974 106.365 155.663C103.739 154.36 101.173 153.617 99.2898 153.254C98.3488 153.07 97.5788 152.952 97.0448 152.897C96.8268 152.87 96.6248 152.844 96.4298 152.82C96.2888 152.8 96.2158 152.786 96.2158 152.779C96.2158 152.772 96.2908 152.772 96.4328 152.778C96.6288 152.79 96.8328 152.803 97.0528 152.816C97.5908 152.846 98.3678 152.942 99.3178 153.109C101.218 153.437 103.812 154.164 106.459 155.477C109.1 156.798 111.244 158.43 112.65 159.748C113.355 160.406 113.9 160.968 114.248 161.379C114.391 161.546 114.524 161.701 114.652 161.851C114.742 161.96 114.787 162.019 114.781 162.024Z" fill="#FAFAFA"/>
<path d="M227.674 306.651C227.674 306.665 227.465 306.689 227.087 306.719C226.709 306.745 226.163 306.804 225.493 306.899C224.154 307.087 222.319 307.493 220.398 308.251C218.479 309.015 216.863 309.975 215.759 310.756C215.206 311.145 214.768 311.476 214.475 311.716C214.179 311.953 214.01 312.079 214 312.068C213.99 312.057 214.141 311.911 214.422 311.654C214.7 311.394 215.127 311.043 215.673 310.636C216.763 309.82 218.384 308.828 220.322 308.057C222.262 307.292 224.121 306.904 225.475 306.753C226.152 306.676 226.703 306.639 227.084 306.638C227.463 306.632 227.674 306.636 227.674 306.651Z" fill="#FAFAFA"/>
<path d="M249.781 329.01C249.781 329.01 248.448 328.573 246.537 327.377C245.578 326.788 244.512 325.966 243.417 324.957C242.344 323.926 241.255 322.693 240.283 321.274C239.329 319.842 238.592 318.372 238.036 316.991C237.509 315.599 237.144 314.303 236.956 313.193C236.56 310.974 236.652 309.574 236.652 309.574C236.659 309.574 236.67 309.661 236.685 309.825C236.702 310.047 236.72 310.284 236.739 310.544C236.754 310.857 236.782 311.238 236.855 311.677C236.922 312.116 236.965 312.621 237.1 313.164C237.305 314.262 237.682 315.543 238.214 316.919C238.775 318.284 239.51 319.737 240.455 321.156C241.418 322.562 242.492 323.786 243.549 324.816C244.627 325.824 245.674 326.651 246.617 327.251C247.071 327.579 247.523 327.808 247.905 328.034C248.285 328.266 248.629 328.434 248.913 328.565C249.147 328.68 249.36 328.785 249.56 328.884C249.708 328.961 249.784 329.003 249.781 329.01Z" fill="#FAFAFA"/>
<path d="M358.828 244.254L359.15 245.288C359.293 245.747 359.652 246.107 360.112 246.25L361.148 246.573L360.107 246.927C359.68 247.073 359.343 247.407 359.196 247.834L358.829 248.892L358.421 247.786C358.267 247.368 357.93 247.043 357.507 246.903L356.509 246.573L357.493 246.24C357.903 246.101 358.23 245.788 358.387 245.385L358.828 244.254Z" fill="#087D4C"/>
<path d="M325.501 197.156L325.823 198.19C325.966 198.649 326.325 199.009 326.785 199.152L327.821 199.475L326.78 199.829C326.353 199.974 326.016 200.309 325.869 200.736L325.502 201.794L325.094 200.688C324.94 200.27 324.604 199.945 324.18 199.805L323.182 199.475L324.166 199.142C324.576 199.003 324.903 198.69 325.06 198.287L325.501 197.156Z" fill="#087D4C"/>
<path d="M211.932 401.095L212.254 402.129C212.397 402.588 212.756 402.948 213.216 403.091L214.252 403.414L213.211 403.768C212.784 403.914 212.447 404.248 212.3 404.675L211.934 405.733L211.526 404.627C211.372 404.209 211.036 403.884 210.612 403.744L209.614 403.414L210.598 403.081C211.008 402.942 211.335 402.629 211.492 402.226L211.932 401.095Z" fill="#087D4C"/>
<path d="M325.501 368.236L325.823 369.27C325.966 369.729 326.325 370.089 326.785 370.232L327.821 370.555L326.78 370.909C326.353 371.054 326.016 371.389 325.869 371.816L325.502 372.874L325.094 371.768C324.94 371.35 324.604 371.025 324.18 370.885L323.182 370.555L324.166 370.222C324.576 370.083 324.903 369.77 325.06 369.367L325.501 368.236Z" fill="#087D4C"/>
<path d="M349.357 203.668L350.675 207.906C351.261 209.789 352.735 211.265 354.618 211.852L358.865 213.177L354.596 214.629C352.844 215.225 351.464 216.597 350.859 218.346L349.356 222.685L347.684 218.151C347.052 216.437 345.673 215.104 343.938 214.53L339.847 213.177L343.881 211.812C345.562 211.243 346.904 209.96 347.548 208.307L349.357 203.668Z" fill="#087D4C"/>
<path d="M251.661 447.355L252.979 451.593C253.565 453.477 255.039 454.952 256.922 455.539L261.169 456.864L256.9 458.317C255.148 458.913 253.769 460.285 253.163 462.034L251.66 466.373L249.988 461.839C249.356 460.125 247.977 458.792 246.242 458.218L242.151 456.865L246.185 455.5C247.866 454.931 249.208 453.648 249.852 451.995L251.661 447.355Z" fill="#087D4C"/>
<path d="M234.172 242.57L234.716 244.32C234.958 245.098 235.567 245.707 236.344 245.949L238.097 246.496L236.335 247.096C235.611 247.342 235.042 247.909 234.792 248.631L234.172 250.422L233.482 248.55C233.221 247.842 232.652 247.292 231.936 247.055L230.247 246.496L231.912 245.932C232.606 245.697 233.16 245.167 233.426 244.485L234.172 242.57Z" fill="#087D4C"/>
<path d="M280.275 438.618L280.819 440.368C281.061 441.146 281.669 441.755 282.447 441.997L284.2 442.544L282.438 443.144C281.714 443.39 281.145 443.957 280.895 444.679L280.275 446.47L279.585 444.598C279.324 443.89 278.755 443.34 278.039 443.103L276.35 442.544L278.015 441.98C278.709 441.745 279.263 441.215 279.529 440.533L280.275 438.618Z" fill="#087D4C"/>
<path d="M149.214 135.098L149.536 136.132C149.679 136.591 150.038 136.951 150.498 137.094L151.534 137.417L150.493 137.771C150.066 137.917 149.729 138.251 149.582 138.678L149.215 139.736L148.807 138.63C148.653 138.212 148.316 137.887 147.893 137.747L146.895 137.417L147.879 137.084C148.289 136.945 148.616 136.632 148.773 136.229L149.214 135.098Z" fill="#087D4C"/>
<path d="M115.886 88L116.208 89.034C116.351 89.493 116.71 89.853 117.17 89.996L118.206 90.319L117.165 90.673C116.738 90.818 116.401 91.153 116.254 91.58L115.887 92.638L115.479 91.532C115.325 91.114 114.989 90.789 114.565 90.649L113.567 90.319L114.551 89.986C114.961 89.847 115.288 89.534 115.445 89.131L115.886 88Z" fill="#087D4C"/>
<path d="M2.31799 291.939L2.63998 292.973C2.78298 293.432 3.14199 293.792 3.60199 293.935L4.638 294.258L3.59698 294.612C3.16998 294.758 2.833 295.092 2.686 295.519L2.32001 296.577L1.91199 295.471C1.75799 295.053 1.42199 294.728 0.997986 294.588L0 294.258L0.983978 293.925C1.39398 293.786 1.72099 293.473 1.87799 293.07L2.31799 291.939Z" fill="#087D4C"/>
<path d="M115.886 259.08L116.208 260.114C116.351 260.573 116.71 260.933 117.17 261.076L118.206 261.399L117.165 261.753C116.738 261.898 116.401 262.233 116.254 262.66L115.887 263.718L115.479 262.612C115.325 262.194 114.989 261.869 114.565 261.729L113.567 261.399L114.551 261.066C114.961 260.927 115.288 260.614 115.445 260.211L115.886 259.08Z" fill="#087D4C"/>
<path d="M139.742 94.5122L141.06 98.7502C141.646 100.633 143.12 102.109 145.003 102.696L149.25 104.021L144.981 105.473C143.229 106.069 141.849 107.441 141.244 109.19L139.741 113.529L138.069 108.995C137.437 107.281 136.058 105.948 134.323 105.374L130.232 104.021L134.266 102.656C135.947 102.087 137.289 100.804 137.933 99.1512L139.742 94.5122Z" fill="#087D4C"/>
<path d="M42.0471 338.199L43.3651 342.437C43.9511 344.321 45.4251 345.796 47.3081 346.383L51.5551 347.708L47.2861 349.161C45.5341 349.757 44.1551 351.129 43.5491 352.878L42.0461 357.217L40.3741 352.683C39.7421 350.969 38.3631 349.636 36.6281 349.062L32.5371 347.709L36.5711 346.344C38.2521 345.775 39.5941 344.492 40.2381 342.839L42.0471 338.199Z" fill="#087D4C"/>
<path d="M24.5578 133.414L25.1018 135.164C25.3438 135.942 25.9528 136.551 26.7298 136.793L28.4828 137.34L26.7208 137.94C25.9968 138.186 25.4278 138.753 25.1778 139.475L24.5578 141.266L23.8678 139.394C23.6068 138.686 23.0378 138.136 22.3218 137.899L20.6328 137.34L22.2978 136.776C22.9918 136.541 23.5458 136.011 23.8118 135.329L24.5578 133.414Z" fill="#087D4C"/>
<path d="M70.6604 329.462L71.2043 331.212C71.4463 331.99 72.0544 332.599 72.8324 332.841L74.5854 333.388L72.8234 333.988C72.0994 334.234 71.5304 334.801 71.2804 335.523L70.6604 337.314L69.9704 335.442C69.7094 334.734 69.1403 334.184 68.4243 333.947L66.7354 333.388L68.4004 332.824C69.0944 332.589 69.6484 332.059 69.9144 331.377L70.6604 329.462Z" fill="#087D4C"/>
<path d="M259.881 37.0387L260.852 36.5589C261.283 36.3458 261.583 35.9349 261.652 35.4582L261.809 34.3844L262.321 35.3573C262.532 35.7562 262.915 36.0367 263.36 36.1151L264.462 36.3121L263.434 36.8881C263.045 37.1056 262.777 37.4893 262.704 37.929L262.535 38.9663L262.052 38.0465C261.85 37.6633 261.49 37.3893 261.067 37.2973L259.881 37.0387Z" fill="#087D4C"/>
<path d="M218.577 77.3238L219.548 76.844C219.979 76.631 220.278 76.2201 220.347 75.7434L220.504 74.6696L221.017 75.6424C221.227 76.0415 221.61 76.3219 222.055 76.4003L223.157 76.5973L222.129 77.1733C221.74 77.3907 221.472 77.7735 221.4 78.2141L221.23 79.2515L220.747 78.3317C220.546 77.9485 220.185 77.6745 219.763 77.5824L218.577 77.3238Z" fill="#087D4C"/>
<path d="M437.77 157.59L438.741 157.111C439.172 156.898 439.471 156.487 439.541 156.01L439.698 154.936L440.21 155.909C440.421 156.308 440.804 156.589 441.249 156.667L442.351 156.863L441.322 157.439C440.933 157.656 440.665 158.039 440.593 158.48L440.423 159.517L439.94 158.597C439.739 158.214 439.379 157.94 438.956 157.848L437.77 157.59Z" fill="#087D4C"/>
<path d="M387.55 50.5611L388.521 50.0813C388.952 49.8683 389.252 49.4574 389.321 48.9807L389.478 47.9069L389.99 48.8797C390.2 49.2788 390.584 49.5592 391.029 49.6376L392.131 49.8346L391.103 50.4106C390.714 50.628 390.445 51.0108 390.373 51.4514L390.204 52.4888L389.721 51.569C389.519 51.1858 389.159 50.9118 388.736 50.8197L387.55 50.5611Z" fill="#087D4C"/>
<path d="M221.276 52.7428L225.256 50.7781C227.024 49.9047 228.251 48.218 228.536 46.2663L229.181 41.8643L231.282 45.8537C232.145 47.4909 233.716 48.6392 235.538 48.9632L240.059 49.7689L235.842 52.1296C234.248 53.0219 233.147 54.5925 232.852 56.3959L232.156 60.6482L230.176 56.8774C229.351 55.3061 227.874 54.1814 226.141 53.8039L221.276 52.7428Z" fill="#087D4C"/>
<path d="M477.246 111.114L481.225 109.149C482.994 108.276 484.221 106.589 484.506 104.637L485.15 100.235L487.253 104.225C488.116 105.862 489.687 107.009 491.509 107.334L496.03 108.14L491.813 110.501C490.219 111.393 489.118 112.963 488.823 114.767L488.126 119.019L486.147 115.248C485.322 113.677 483.845 112.552 482.112 112.175L477.246 111.114Z" fill="#087D4C"/>
<path d="M277.718 160.423L279.361 159.612C280.092 159.251 280.598 158.555 280.716 157.749L280.982 155.932L281.85 157.579C282.206 158.255 282.855 158.729 283.607 158.863L285.473 159.195L283.732 160.169C283.074 160.538 282.62 161.186 282.498 161.93L282.21 163.686L281.392 162.129C281.052 161.481 280.441 161.016 279.726 160.86L277.718 160.423Z" fill="#087D4C"/>
<path d="M464.141 84.22L465.784 83.4089C466.515 83.0482 467.021 82.3524 467.138 81.5461L467.405 79.7292L468.273 81.3756C468.629 82.0522 469.278 82.5255 470.03 82.6595L471.896 82.9917L470.155 83.966C469.497 84.3346 469.043 84.9826 468.92 85.7269L468.633 87.4825L467.815 85.9262C467.474 85.2775 466.864 84.8133 466.149 84.6572L464.141 84.22Z" fill="#087D4C"/>
</svg>

    </div>
  )
}

export default CelebrateSvg