import React, { useState } from "react";
import { BiBell } from "react-icons/bi";
import {
  AiFillCaretDown,
  AiFillSetting,
  AiOutlineCalendar,
} from "react-icons/ai";
import { VscSearch } from "react-icons/vsc";
import Sidebar from "../components/Sidebar";
import { FaEllipsisV, FaMoon, FaSun } from "react-icons/fa";
import { useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { IoAlert } from "react-icons/io5";
import DropBtn from "./DropBtn";
import { TiArrowSortedUp } from "react-icons/ti";
import DateRangeComp from "./DateRangeComp";
import NotificationBtn from "./NotificationBtn";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ["Energy", "Cable", "Airtime/Data", "Betting", "Exam"],
  datasets: [
    {
      label: "# of Transactions",
      data: [3, 2, 4, 1, 0],
      backgroundColor: [
        "rgba(22, 91, 170, 1)",
        "rgba(29, 221, 141, 1)",
        "rgba(161, 85, 185, 1)",
        "rgba(247, 101, 163, 1)",
        "rgba(22, 191, 214, 1)",
      ],
      borderColor: [
        "rgba(22, 91, 170, 1)",
        "rgba(29, 221, 141, 1)",
        "rgba(161, 85, 185, 1)",
        "rgba(247, 101, 163, 1)",
        "rgba(22, 191, 214, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        boxRadius: 10,
      },
    },
  },
  layout: {
    padding: {
      top: 0,
      bottom: 0,
      right: 0,
    },
  },
};

const Dash = ({ darkMode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedChoice, setSelectedChoice] = useState("all");
  const [newNotification, setNewNotification] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleNewNotification = () => {
    setNewNotification(!newNotification);
  };

  const handleSelectedChoice = (event, choice) => {
    setSelectedChoice(choice);
  };

  const renderTable = () => {
    if (selectedChoice === "all") {
      return (
        <table className="min-w-full custom-scrollbar">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#12345</td>
              <td className="py-2 px-4 text-sm">2023-08-15</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">123456789</td>
              <td className="py-2 px-4 text-sm">₦10000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 w-16 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#67890</td>
              <td className="py-2 px-4 text-sm">2023-08-16</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Airtime</td>
              <td className="py-2 px-4 text-sm">Airtel</td>
              <td className="py-2 px-4 text-sm">987654321</td>
              <td className="py-2 px-4 text-sm">₦7500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#13579</td>
              <td className="py-2 px-4 text-sm">2023-08-17</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Airtime</td>
              <td className="py-2 px-4 text-sm">Glo</td>
              <td className="py-2 px-4 text-sm">246813579</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#24680</td>
              <td className="py-2 px-4 text-sm">2023-08-18</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Betting</td>
              <td className="py-2 px-4 text-sm">betNaija</td>
              <td className="py-2 px-4 text-sm">135792468</td>
              <td className="py-2 px-4 text-sm">₦5000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#35791</td>
              <td className="py-2 px-4 text-sm">2023-08-19</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Data</td>
              <td className="py-2 px-4 text-sm">MTN</td>
              <td className="py-2 px-4 text-sm">579135791</td>
              <td className="py-2 px-4 text-sm">₦8500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#46802</td>
              <td className="py-2 px-4 text-sm">2023-08-20</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">246802468</td>
              <td className="py-2 px-4 text-sm">₦9500</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#57913</td>
              <td className="py-2 px-4 text-sm">2023-08-21</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Cable</td>
              <td className="py-2 px-4 text-sm">DSTV </td>
              <td className="py-2 px-4 text-sm">357913579</td>
              <td className="py-2 px-4 text-sm">₦6000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#68024</td>
              <td className="py-2 px-4 text-sm">2023-08-22</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">802468024</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#79135</td>
              <td className="py-2 px-4 text-sm">2023-08-23</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Airtime</td>
              <td className="py-2 px-4 text-sm">Airtel</td>
              <td className="py-2 px-4 text-sm">913579135</td>
              <td className="py-2 px-4 text-sm">₦15000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#80246</td>
              <td className="py-2 px-4 text-sm">2023-08-24</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Cable</td>
              <td className="py-2 px-4 text-sm">DSTV</td>
              <td className="py-2 px-4 text-sm">135791357</td>
              <td className="py-2 px-4 text-sm">₦8000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 w-16 text-center text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else if (selectedChoice === "completed") {
      return (
        <table className="min-w-full ">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#12345</td>
              <td className="py-2 px-4 text-sm">2023-08-15</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">123456789</td>
              <td className="py-2 px-4 text-sm">₦10000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#13579</td>
              <td className="py-2 px-4 text-sm">2023-08-17</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">246813579</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#46802</td>
              <td className="py-2 px-4 text-sm">2023-08-20</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">246802468</td>
              <td className="py-2 px-4 text-sm">₦9500</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#79135</td>
              <td className="py-2 px-4 text-sm">2023-08-23</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">913579135</td>
              <td className="py-2 px-4 text-sm">₦15000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else if (selectedChoice === "pending") {
      return (
        <table className="min-w-full ">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#67890</td>
              <td className="py-2 px-4 text-sm">2023-08-16</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">987654321</td>
              <td className="py-2 px-4 text-sm">₦7500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#35791</td>
              <td className="py-2 px-4 text-sm">2023-08-19</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">579135791</td>
              <td className="py-2 px-4 text-sm">₦8500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#68024</td>
              <td className="py-2 px-4 text-sm">2023-08-22</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">802468024</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#80246</td>
              <td className="py-2 px-4 text-sm">2023-08-24</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">135791357</td>
              <td className="py-2 px-4 text-sm">₦8000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else if (selectedChoice === "cancelled") {
      return (
        <table className="min-w-full ">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#24680</td>
              <td className="py-2 px-4 text-sm">2023-08-18</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">135792468</td>
              <td className="py-2 px-4 text-sm">₦5000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 text-xs text-white px-2 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#57913</td>
              <td className="py-2 px-4 text-sm">2023-08-21</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">357913579</td>
              <td className="py-2 px-4 text-sm">₦6000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 text-xs text-white px-2 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  return (
    <div>
      <div className="flex-1 pt-8 pb-1 md:px-[3rem] mx-2">
        <div className="flex  justify-between items-center mb-8 pb-8">
          <div className="flex flex-col md:flex-row md:items-center">
            <h1 className="md:mr-20 mx-2 mb-4  md:text-3xl text-2xl font-bold">
              Dashboard
            </h1>
            <div className="flex mb-4 md:mb-0 md:mr-8">
              <div className="relative mr-8">
                <input
                  type="text"
                  placeholder="Search..."
                  className="md:p-3 p-2 rounded-full md:w-96 pr-10"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <VscSearch className="font-bold text-xl" />
                </div>
              </div>

              <div className="flex md:space-x-6 space-x-1 md:mr-12 justify-center items-center">
                <button
                  onClick={toggleNewNotification}
                  type="button"
                  class={`relative flex items-center justify-center w-12 h-12 text-gray-700 rounded-full icon-button ${
                    newNotification === true ? "bg-gray-200" : ""
                  }`}
                >
                  <span class="material-icons">
                    <BiBell className="text-2xl hover:text-green-400 hover:text-3xl" />
                  </span>
                  <span class="absolute top-0 right-0 w-6 h-6 bg-green-500 text-white flex justify-center items-center rounded-full icon-button__badge">
                    2
                  </span>
                  {newNotification && <NotificationBtn />}
                </button>

                <Link to="/settings">
                  <button
                    type="button"
                    class="relative flex items-center justify-center w-12 h-12 text-gray-700 rounded-full icon-button"
                  >
                    <span class="material-icons">
                      <AiFillSetting className="text-2xl hover:text-green-400" />
                    </span>
                    <span class="absolute top-0 right-0 w-6 h-6 bg-gray-500 text-white flex justify-center items-center rounded-full icon-button__badge">
                      <IoAlert />
                    </span>
                  </button>
                </Link>
              </div>
            </div>

            <div>
              <button
                className="bg-green-700 hover:bg-green-500 text-white text-sm px-6 py-2 rounded-lg relative flex"
                onClick={handleDropdownToggle}
              >
                Virtual Account
                {isDropdownOpen ? (
                  <TiArrowSortedUp className="ml-2 mt-1" />
                ) : (
                  <AiFillCaretDown className="ml-2 mt-1" />
                )}
              </button>
              {isDropdownOpen && <DropBtn />}
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:space-x-4 md:mb-16">
          <div
            className={`mb-4 md:mb-0 p-8  h-[300px] shadow-md rounded-3xl md:w-1/2 ${
              darkMode ? "bg-[#212130] text-white" : "bg-white"
            }`}
          >
            <h2 className="text-2xl font-semibold mb-4">
              Welcome Back Laguda!
            </h2>
            <p>
              Phasellus dui nunc, finibus vitae tincidunt eget convallis non
              dolor. Donec tortor ex.
            </p>
          </div>
          <div
            className={`flex justify-center items-center mb-4 md:mb-0 p-2 h-[300px] shadow-md rounded-3xl md:w-1/2 ${
              darkMode ? "bg-[#212130] text-white" : "bg-white"
            }`}
          >
            {/* Colorful Graph */}
            <Doughnut data={data} options={options} />
          </div>
        </div>

        <div className="flex flex-col md:flex-row  md:justify-between">
          <div className="md:mr-4 mb-4 md:mb-0">
            <div
              className={`flex items-center justify-center mb-2 rounded-3xl md:px-3 py-2 ${
                darkMode ? "bg-[#212130] text-white" : "bg-white "
              }`}
            >
              <button
                onClick={(event) => handleSelectedChoice(event, "all")}
                className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                  selectedChoice === "all" ? "bg-green-700 text-white" : ""
                }`}
              >
                All Transactions
              </button>
              <button
                onClick={(event) => handleSelectedChoice(event, "completed")}
                className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                  selectedChoice === "completed"
                    ? "bg-green-700 text-white"
                    : ""
                }`}
              >
                Completed
              </button>
              <button
                onClick={(event) => handleSelectedChoice(event, "pending")}
                className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                  selectedChoice === "pending" ? "bg-green-700 text-white" : ""
                }`}
              >
                Pending
              </button>
              <button
                onClick={(event) => handleSelectedChoice(event, "cancelled")}
                className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                  selectedChoice === "cancelled"
                    ? "bg-green-700 text-white"
                    : ""
                }`}
              >
                Failed
              </button>
            </div>
          </div>
          <div className="md:justify-end justify-center md:items-center mb-4 flex md:w-1/2">
            <button
              className={`flex items-center text-xs px-4 py-2 md:py-3 rounded-3xl  mr-2 ${
                darkMode
                  ? "bg-[#212130] text-white hover:bg-gray-700"
                  : "bg-white hover:bg-gray-100"
              }`}
            >
              {" "}
              <span className="flex-grow">Filter By Products</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-2 text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              onClick={() => setOpenDate(!openDate)}
              className={`flex items-center text-xs px-4 md:py-3 py-2 rounded-3xl   ${
                darkMode
                  ? "bg-[#212130] text-white hover:bg-gray-700"
                  : "bg-white hover:bg-gray-100"
              }`}
            >
              <AiOutlineCalendar className="mr-2" />
              <span className="flex-grow">Filter By Date</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-2 text-green-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            {openDate && <DateRangeComp />}
          </div>
        </div>

        <div
          className={`w-[370px] overflow-y-auto max-h-[600px] md:w-full p-4 shadow-md rounded-3xl ${
            darkMode ? "bg-[#212130] text-white" : "bg-white"
          }`}
        >
          <div className="overflow-auto">{renderTable()}</div>
        </div>
      </div>
    </div>
  );
};

export default Dash;
