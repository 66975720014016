import React from "react";
import { AiFillEdit, AiOutlineCloseCircle } from "react-icons/ai";

const Details = ({ close }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-[400px] p-4 shadow-md relative">
        <section className="px-4 py-3 ">
          <div className="mb-4 flex">
            <div onClick={close} className="text-gray-600 absolute right-0">
              <AiOutlineCloseCircle className="text-3xl text-red-500 mr-2 cursor-pointer" />
            </div>
          </div>
        </section>
        <div className="mb-4 flex justify-between mt-4">
          <p className="text-gray-600 mb-2 mr-2">Transaction ID:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Transaction ID:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Date:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Payment Type:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Product:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Type:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Recipient ID:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Amount:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
        <div className="mb-4 flex justify-between">
          <p className="text-gray-600 mb-2 mr-2">Status:</p>
          <p className="text-gray-800  font-bold">*****</p>
        </div>
      </div>
    </div>
  );
};

export default Details;
