import { Link, useLocation } from "react-router-dom";
import "../stylesheets/Sidenav.css";
import EnergyIcon from "./icons/EnergyIcon";
import AirtimeIcon from "./icons/AirtimeIcon";
import { FaHome } from "react-icons/fa";
import DataIcon from "./icons/DataIcon";
import CableIcon from "./icons/CableIcon";
import { IoSchoolSharp } from "react-icons/io5";
import { MdCasino } from "react-icons/md";


const navItems = [
  { id: "home", label: "Home", icon: <FaHome />, path: "/" },
  { id: " Buy Electricity", label: " Buy Electricity", icon: <EnergyIcon />, path: "/energy" },
  { id: "Buy Airtime", label: "Buy Airtime", icon: <AirtimeIcon />, path: "/airtime" },
  { id: "Buy Data", label: "Buy Data", icon: <DataIcon />, path: "/data" },
  { id: " Tv Subscription", label: " Tv Subscription", icon: <CableIcon />, path: "/cable" },
  { id: "Bet", label: "Bet", icon: <MdCasino />, path: "/bet" },
  {
    id: "Exams",
    label: "Exams",
    icon: <IoSchoolSharp />,
    path: "/education",
  },
];

const Sidenav = () => {
  const location = useLocation();

  return (
    <nav className="sidenav">
      <ul className="sidenav-list">
        {navItems.map((item) => (
          <li key={item.id} className="sidenav-item">
            <Link
              to={item.path}
              className={`sidenav-link ${
                location.pathname === item.path ? "active" : ""
              }`}
            >
              <span className="icon">{item.icon}</span>
              <span className="label">{item.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Sidenav;
