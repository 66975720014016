import { useEffect, useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;
const postUrl = `${baseURL}/register`;
const getInfoUrl = `${baseURL}/lookup`;
const uploadEndpointUrl = `${baseURL}/register/upload-docs`;

const GetStarted = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [lga, setLga] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [bvn, setBvn] = useState("");
  const [nin, setNin] = useState("");
  const [rcNumber, setRcNumber] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [uploadDocument, setUploadDocument] = useState(null);

  const [merchantDocuments, setMerchantDocuments] = useState([]);
  const [firstCAC, setFirstCAC] = useState(null);
  const [firstProofOfAddress, setFirstProofOfAddress] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);

  const [errors, setErrors] = useState({});
  const [infoData, setInfoData] = useState({});

  const bvnRegex = /^\d{11}$/;

  useEffect(() => {
    getLookup();
  }, []);

  const getLookup = async () => {
    try {
      const response = await axios.get(getInfoUrl);
      const data = response.data.data;
      setInfoData(data);

      const merchantDocs = data.document_types.filter(
        (doc) => doc.customer_type.name === "Merchant"
      );
      setMerchantDocuments(merchantDocs);

      const firstCACDoc = merchantDocs.find(
        (doc) => doc.name === "CAC Document"
      );
      const firstProofDoc = merchantDocs.find(
        (doc) => doc.name === "Proof of address"
      );

      setFirstCAC(firstCACDoc);
      setFirstProofOfAddress(firstProofDoc);

    } catch (error) {
      console.error("Error fetching lookup data:", error);
    }
  };

  const genderOptions = infoData.gender || [];
  const businessTypeOptions = infoData.business_types || [];
  const countryOptions = infoData.countries || [];
  const stateOptions = infoData.states || [];
  const lgaOptions = infoData.lgas || [];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validate();
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);

    if (!uploadDocument) {
      console.error("No file selected.");
      alert("Please select a file before submitting.");
      return;
    }

    try {
      const fileName = uploadDocument.name;

      const documentData = new FormData();
      documentData.append("file", uploadDocument);

      const uploadResponse = await axios.post(uploadEndpointUrl, documentData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const fileUrl = uploadResponse.data.data.file_url;

      const payload = {
        first_name: firstname,
        last_name: lastname,
        dob: dob,
        gender: gender,
        email: email,
        business_name: businessName,
        business_address: businessAddress,
        phone_number: phoneNumber,
        lga_id: lga,
        state_id: state,
        country_id: country,
        bvn: bvn,
        nin: nin,
        rc_number: rcNumber,
        business_type_id: businessType,
        merchant_document_type_id: selectedDocumentType, 
        file_name: fileName,
        file_url: fileUrl,
      };

      const response = await axios.post(postUrl, payload);
      window.location.href = "/personal?fromGetStarted=true";
    } catch (error) {
      console.error("Error occurred during submission:", error);
      handle422(error);
      handle400(error);
      handle500(error);
    } finally {
      setIsLoading(false);
    }
    } else {
      setErrors(errors);
    }
  };

  function handle422(e) {
    let r = e.response;
    if (r.status === 422) {
      let error_bag = r.data.data;

      const keys = Object.keys(error_bag);
      let first_error_name = keys[0];
      let first_error_value = error_bag[first_error_name];

      toast.error(first_error_value[0], {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle400(e) {
    let r = e.response;
    if (r.status === 400) {
      var str = r.data.message;
      str = str.replace(/_/g, " ");

      toast.error(str, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  function handle500(e) {
    let r = e.response;
    if (r.status === 500) {
      console.error("Internal Server Error: ", r.data);
      toast.error("An error occurred on the server. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "light",
      });
    }
  }

  const validate = () => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{11}$/;
    if (!firstname.trim()) {
      errors.firstname = "Your first name is required";
    }
    if (!lastname.trim()) {
      errors.lastname = "Your last name is required";
    }

    if (!emailRegex.test(email)) {
      errors.email = "Please enter a valid email";
    }
    if (!phoneRegex.test(phoneNumber)) {
      errors.phoneNumber = "Please enter a valid phone number";
    }
    if (!message.trim()) {
      errors.message = "Message is required";
    }
    if (!lga) errors.lga = "LGA is required";
    if (!state) errors.state = "State is required";
    if (!country) errors.country = "Country is required";
    if (!bvn) {
      errors.bvn = "BVN is required";
    } else if (!bvnRegex.test(bvn)) {
      errors.bvn = "BVN must be 11 digits";
    }    if (!nin) errors.nin = "NIN is required";
    if (!rcNumber) errors.rcNumber = "Rc Number is required";
    if (!businessType) errors.businessType = "Business type is required";
    if (!dob) errors.dob = "Date of birth is required";
    if (!gender) errors.gender = "Gender is required";
    if (!businessName.trim()) errors.businessName = "Business name is required";
    if (!businessAddress.trim())
      errors.businessAddress = "Business address is required";
    if (!uploadDocument) errors.uploadDocument = "Document is required";
    return errors;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setUploadDocument(selectedFile);
    } else {
      console.warn("No file selected");
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    switch (id) {
      case "gender":
        setGender(value);
        break;
      case "lga":
        setLga(value);
        break;
      case "state":
        setState(value);
        break;
      case "country":
        setCountry(value);
        break;
      case "businessType":
        setBusinessType(value);
      default:
        break;
    }
  };

  return (
    <div className="bg-gray-200 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl font-bold mb-6">Contact Us</h2>
        <h2 className="font-semibold mb-6">
          To get started as a BillerHQ merchant, fill out the form below and a
          member of our team will reach out to you.
        </h2>
        <div className="bg-white shadow-md rounded-xl px-8 pt-6 pb-8 mb-4">
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="firstname"
                >
                  First Name
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.firstname ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="firstname"
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstname && (
                  <span className="text-red-500 text-xs">
                    {errors.firstname}
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="lastname"
                >
                  Last Name
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.lastname ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="lastname"
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastname && (
                  <span className="text-red-500 text-xs">
                    {errors.lastname}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.email ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <span className="text-red-500 text-xs">{errors.email}</span>
                )}
              </div>

              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.phoneNumber ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="phoneNumber"
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
                {errors.phoneNumber && (
                  <span className="text-red-500 text-xs">
                    {errors.phoneNumber}
                  </span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="dob"
                >
                  Date of Birth
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.dob ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="dob"
                  type="date"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
                {errors.dob && (
                  <span className="text-red-500 text-xs">{errors.dob}</span>
                )}
              </div>

              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="gender"
                >
                  Gender
                </label>
                <select
                  id="gender"
                  value={gender}
                  onChange={handleInputChange}
                  className={`shadow appearance-none border ${
                    errors.gender ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                >
                  <option value="">Select Gender</option>
                  {genderOptions.map((gender) => (
                    <option key={gender} value={gender}>
                      {gender}
                    </option>
                  ))}
                </select>
                {errors.gender && (
                  <span className="text-red-500 text-xs">{errors.gender}</span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="lga"
                >
                  LGA
                </label>
                <select
                  id="lga"
                  value={lga}
                  onChange={handleInputChange}
                  className={`shadow appearance-none border ${
                    errors.lga ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                >
                  <option value="">Select LGA</option>
                  {lgaOptions.map((lga) => (
                    <option key={lga.id} value={lga.id}>
                      {lga.name}
                    </option>
                  ))}
                </select>
                {errors.lga && (
                  <span className="text-red-500 text-xs">{errors.lga}</span>
                )}
              </div>

              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="state"
                >
                  State
                </label>
                <select
                  id="state"
                  value={state}
                  onChange={handleInputChange}
                  className={`shadow appearance-none border ${
                    errors.state ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                >
                  <option value="">Select State</option>
                  {stateOptions.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <span className="text-red-500 text-xs">{errors.state}</span>
                )}
              </div>
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="country"
                >
                  Country
                </label>
                <select
                  id="country"
                  value={country}
                  onChange={handleInputChange}
                  className={`shadow appearance-none border ${
                    errors.country ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                >
                  <option value="">Select Country</option>
                  {countryOptions.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <span className="text-red-500 text-xs">{errors.country}</span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="bvn"
                >
                  BVN
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.bvn ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="bvn"
                  type="text"
                  value={bvn}
                  onChange={(e) => setBvn(e.target.value)}
                />
                {errors.bvn && (
                  <span className="text-red-500 text-xs">{errors.bvn}</span>
                )}
              </div>

              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="nin"
                >
                  NIN
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.nin ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="nin"
                  type="text"
                  value={nin}
                  onChange={(e) => setNin(e.target.value)}
                />
                {errors.nin && (
                  <span className="text-red-500 text-xs">{errors.nin}</span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="rcNumber"
                >
                  RC Number
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.rcNumber ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="rcNumber"
                  type="text"
                  value={rcNumber}
                  onChange={(e) => setRcNumber(e.target.value)}
                />
                {errors.rcNumber && (
                  <span className="text-red-500 text-xs">
                    {errors.rcNumber}
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="businessType"
                >
                  Business Type
                </label>
                <select
                  id="businessType"
                  value={businessType}
                  onChange={handleInputChange}
                  className={`shadow appearance-none border ${
                    errors.businessType ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                >
                  <option value="">Select Business Type</option>
                  {businessTypeOptions.map((businessType) => (
                    <option key={businessType.id} value={businessType.id}>
                      {businessType.name}
                    </option>
                  ))}
                </select>
                {errors.businessType && (
                  <span className="text-red-500 text-xs">
                    {errors.businessType}
                  </span>
                )}
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="businessName"
                >
                  Business Name
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.email ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="businessName"
                  type="text"
                  value={businessName}
                  onChange={(e) => setBusinessName(e.target.value)}
                />
                {errors.businessName && (
                  <span className="text-red-500 text-xs">
                    {errors.businessName}
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="businessAddress"
                >
                  Business Address
                </label>
                <input
                  className={`shadow appearance-none border ${
                    errors.phoneNumber ? "border-red-500" : "border-gray-400"
                  } rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                  id="businessAddress"
                  type="text"
                  value={businessAddress}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                />
                {errors.businessAddress && (
                  <span className="text-red-500 text-xs">
                    {errors.businessAddress}
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="documentType"
                >
                  Select Document Type:
                </label>
                <select
                  id="documentType"
                  onChange={(e) => setSelectedDocumentType(e.target.value)} 
                >
                  <option value="">-- Select a document --</option>
                  {firstCAC && (
                    <option value={firstCAC.id}>{firstCAC.name}</option>
                  )}
                  {firstProofOfAddress && (
                    <option value={firstProofOfAddress.id}>
                      {firstProofOfAddress.name}
                    </option>
                  )}
                </select>
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 font-bold mb-2 text-xs"
                  htmlFor="uploadDocument"
                >
                  Document
                </label>
                <input type="file" onChange={handleFileChange} />
              </div>
            </div>

            <ReCAPTCHA sitekey="6LcZeZQlAAAAALt0WrQiWXfH5M6DAypgMx9tSWxg" />
            <div className="flex items-center justify-between">
              <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  `Submit`
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default GetStarted;
