import React from "react";
import { useState } from "react";
import { AiFillEye, AiOutlineCaretDown } from "react-icons/ai";

const CreateVr = ({
  darkMode,
  dob,
  bank,
  handleBankChange,
  handlebvnChange,
  handleDobChange,
  handleSubmit,
  bvn,
  disco,
  handleDiscoChange,
  amount,
  handleAmountChange,
  meterNumber,
  handleMeterNumberChange,
  meterType,
  handleMeterTypeChange,
  meterName,
  handleMeterNameChange,
  address,
  handleAddressChange,
}) => {

  return (
    <div
      className={`p-4 md:px-4 shadow-md rounded-3xl ${
        darkMode ? "bg-[#212130] text-white" : "bg-white"
      }`}
    >
      <form>
        <section className="md:mx-12 md:p-12">
          <div className="flex">
            <div className="mb-2 w-1/2 mr-4">
              <label className="block text-gray-500 text-xs" htmlFor="disco">
                Disco
              </label>
              <input
                type="text"
                id="disco"
                name="disco"
                className="w-full border border-gray-600 text-xs p-3 rounded-lg"
                value={disco}
                onChange={handleDiscoChange}
              />
            </div>
            <div className="mb-2 w-1/2">
              <label className="block text-gray-500 text-xs" htmlFor="amount">
                Amount
              </label>
              <div className="flex items-center border border-gray-600 p-2 rounded-lg">
                <input
                  type="number"
                  id="amount"
                  className="w-full outline-none text-xs py-1"
                  name="amount"
                  value={amount}
                  onChange={handleAmountChange}
                />
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 w-1/2 mr-4">
              <label
                className="block text-gray-500 text-xs"
                htmlFor="meterNumber"
              >
                Meter Number
              </label>
              <div className="flex items-center border border-gray-600  p-2 rounded-lg">
                <input
                  type="text"
                  id="meterNumber"
                  className="w-full outline-none text-xs py-1"
                  name="meterNumber"
                  value={meterNumber}
                  onChange={handleMeterNumberChange}
                />
              </div>
            </div>

            <div className="mb-2 w-1/2">
              <label
                className="block text-gray-500 text-xs"
                htmlFor="meterType"
              >
                Meter Type
              </label>
              <div className="flex items-center border border-gray-600  p-2 rounded-lg">
                <input
                  type="text"
                  id="meterType"
                  className="w-full outline-none text-xs py-1"
                  name="meterType"
                  value={meterType}
                  onChange={handleMeterTypeChange}
                />
                <span className="mr-2 text-xs p-1">
                  <AiOutlineCaretDown />
                </span>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 w-1/2 mr-4">
              <label
                className="block text-gray-400 text-xs"
                htmlFor="meterName"
              >
                Meter Name
              </label>
              <div className="flex items-center border border-gray-400  p-2 rounded-lg">
                <input
                  type="text"
                  id="meterName"
                  className="w-full outline-none text-xs py-1"
                  name="meterName"
                  value={meterName}
                  onChange={handleMeterNameChange}
                />
              </div>
            </div>

            <div className="mb-2 w-1/2 ">
              <label className="block text-gray-400 text-xs" htmlFor="address">
                Address
              </label>
              <div className="flex items-center border border-gray-400  p-2 rounded-lg">
                <input
                  type="text"
                  id="address"
                  className="w-full outline-none text-xs py-1"
                  name="address"
                  value={address}
                  onChange={handleAddressChange}
                />
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="mb-2 w-1/2 mr-4">
              <label className="block text-gray-500 text-xs" htmlFor="dob">
                Date of Birth
              </label>
              <div className="flex items-center border border-gray-600  p-2 rounded-lg">
                <input
                  type="text"
                  id="dob"
                  className="w-full outline-none text-xs py-1"
                  name="dob"
                  value={dob}
                  onChange={handleDobChange}
                />
              </div>
            </div>

            <div className="mb-2 w-1/2 ">
              <label className="block text-gray-500 text-xs" htmlFor="bank">
                Select Bank
              </label>
              <div className="flex items-center border border-gray-600  p-2 rounded-lg">
                <input
                  type="text"
                  id="bank"
                  className="w-full outline-none text-xs py-1"
                  name="bank"
                  value={bank}
                  onChange={handleBankChange}
                />
                <span className="mr-2 text-xs p-1">
                  <AiOutlineCaretDown />
                </span>
              </div>
            </div>
          </div>

          <div className="mb-1">
            <label className="block text-gray-500 text-xs" htmlFor="bvn">
              BVN
            </label>
            <input
              type="bvn"
              id="bvn"
              name="bvn"
              className="w-full border border-gray-600  text-xs p-3 rounded-lg"
              value={bvn}
              onChange={handlebvnChange}
            />
          </div>

          <div className="flex mt-6 justify-center">
            <div className="mb-1 w-1/2">
              <button
                onClick={handleSubmit}
                type="button"
                className="w-full bg-green-700 py-3 mt-2 rounded-lg text-white"
              >
                Confirm
              </button>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
};

export default CreateVr;
