const AirtimeIcon = () => (
  <svg
    width="15"
    height="24"
    viewBox="0 0 15 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 3C0 2.20435 0.31607 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0L12 0C12.7956 0 13.5587 0.316071 14.1213 0.87868C14.6839 1.44129 15 2.20435 15 3V21C15 21.7956 14.6839 22.5587 14.1213 23.1213C13.5587 23.6839 12.7956 24 12 24H3C2.20435 24 1.44129 23.6839 0.87868 23.1213C0.31607 22.5587 0 21.7956 0 21V3ZM9 19.5C9 19.1022 8.84196 18.7206 8.56066 18.4393C8.27936 18.158 7.89782 18 7.5 18C7.10218 18 6.72064 18.158 6.43934 18.4393C6.15804 18.7206 6 19.1022 6 19.5C6 19.8978 6.15804 20.2794 6.43934 20.5607C6.72064 20.842 7.10218 21 7.5 21C7.89782 21 8.27936 20.842 8.56066 20.5607C8.84196 20.2794 9 19.8978 9 19.5Z"
      // fill="#A1A1AA"
    />
  </svg>
);

export default AirtimeIcon;
