import React from "react";
import { Link } from "react-router-dom";

const NotificationBtn = () => {
  return (
    <div className="flex flex-col shadow-lg font-bold  notification-btn">
      <ul className="flex flex-col gap-2 text-xs text-center">
        <li className="border-gray-400 pb-2 border-b">
          Your transaction was successful  <span className="ml-4">12:30AM</span>
        </li>
        <li className="border-gray-400 pb-2 border-b">
          Your transaction was successful  <span className="ml-4">12:30AM</span>
        </li>
        <li className="border-gray-400 pb-2 border-b">
          Your transaction was successful  <span className="ml-4">12:30AM</span>
        </li>
        <li className="border-gray-400 pb-2 border-b">
          Your transaction was successful  <span className="ml-4">12:30AM</span>
        </li>
        <li className="border-gray-400 pb-2 border-b">
          Your transaction was successful  <span className="ml-4">12:30AM</span>
        </li>
        <li className="border-gray-400 pb-2 border-b">
          Your transaction was successful  <span className="ml-4">12:30AM</span>
        </li>
      </ul>
    </div>
  );
};

export default NotificationBtn;
