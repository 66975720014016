import React from "react";
import dstv from "../assets/dstv logooooooooo 2.png";
import gotv from "../assets/gotv square 1.png";
import startimes from "../assets/startimes-logo newww 1.png";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { AiOutlineCaretDown } from "react-icons/ai";
import { useState } from "react";
import BillReviewCard from "./BillReviewCard";
import TransactionCard from "./TransactionCard";
import { Link } from "react-router-dom";

const NewCable = ({ darkMode }) => {
  const [iucNumber, setIUCNumber] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [requestType, setRequestType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [token, setToken] = useState("");

  const [cableReview, setCableReview] = useState(false);
  const [cableTransactionReview, setCableTransactionReview] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  const [active, setActive] = useState(false);

  const handleIUCNumberChange = (event) => {
    setIUCNumber(event.target.value);
  };

  const handlePackageChange = (event) => {
    setSelectedPackage(event.target.value);
  };

  const handleRequestTypeChange = (event) => {
    setRequestType(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCableReview(true);
    const formData = {
      iucNumber,
      selectedPackage,
      requestType,
      phoneNumber,
      email,
      customerName,
      token,
    };
    console.log(formData);
  };

  const makePayment = () => {
    setCableReview(false);
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 3000);
    setCableTransactionReview(true);
  };

  const handleDstvClick = () => {
    setActive("dstv");
  };
  const handleGotvClick = () => {
    setActive("gotv");
  };
  const handleStartimesClick = () => {
    setActive("startimes");
  };

  return (
    <div>
      {screenLoading && <div className="loading"></div>}

      {cableReview && (
        <BillReviewCard
          selectedAmount={selectedPackage}
          phoneNumber={phoneNumber}
          iucNumber={iucNumber}
          requestType={requestType}
          email={email}
          buttonAction={makePayment}
          backAction={() => setCableReview(false)}
        />
      )}
      {cableTransactionReview && (
        <TransactionCard
          selectedAmount={selectedPackage}
          phoneNumber={phoneNumber}
          iucNumber={iucNumber}
          onClose={() => setCableTransactionReview(false)}
          requestType={requestType}
          email={email}
          customerName={true}
          token={true}
        />
      )}
      <div className="text-gray-600 mb-6 my-12 md:mx-16">
        <Link to="/paybills">
          <IoArrowBackCircleOutline className="text-5xl text-green-400 mb-6 cursor-pointer" />
        </Link>
      </div>
      <div className="flex-1 pt-4 py-6 md:px-[3rem] mx-2">
        <div
          className={`p-4 md:px-16 shadow-md rounded-3xl ${
            darkMode ? "bg-[#212130] text-white" : "bg-gray-50"
          }`}
        >
          <div className=" flex justify-center md:mt-12 md:flex-row md:space-x-4 md:mb-4">
            <div
              className={`hover:bg-green-400 p-2 ${
                active === "dstv" ? "bg-gray-500" : ""
              }`}
              onClick={handleDstvClick}
            >
              <img src={dstv} alt="dstv-tv" className="h-16 md:h-[10rem]" />
            </div>
            <div
              className={`hover:bg-green-400 p-2 ${
                active === "gotv" ? "bg-gray-500" : ""
              }`}
              onClick={handleGotvClick}
            >
              <img src={gotv} alt="gotv-tv" className="h-16 md:h-[10rem]" />
            </div>
            <div
              className={`hover:bg-green-400 p-2 ${
                active ==="startimes" ? "bg-gray-500" : ""
              }`}
              onClick={handleStartimesClick}
            >
              <img src={startimes} alt="startimes-tv" className="h-16 md:h-[10rem]" />
            </div>
          </div>

          <form>
            <section className="md:mx-12 md:p-12 mt-4">
              <div className="flex">
                <div className="mb-2 w-1/2 mr-4">
                  <label className="block text-gray-500 text-xs" htmlFor="iuc">
                    IUC Number
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="text"
                      id="iuc"
                      className="w-full outline-none text-xs py-1"
                      name="iuc"
                      value={iucNumber}
                      onChange={handleIUCNumberChange}
                    />
                  </div>
                </div>

                <div className="mb-2 w-1/2">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="package"
                  >
                    Package
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="text"
                      id="package"
                      className="w-full outline-none text-xs py-1"
                      name="package"
                      value={selectedPackage}
                      onChange={handlePackageChange}
                    />
                    <span className="mr-2 text-xs p-1">
                      <AiOutlineCaretDown />
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="mb-2 w-1/2 mr-4">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="request-type"
                  >
                    Request Type
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="text"
                      id="request-type"
                      className="w-full outline-none text-xs py-1"
                      name="request-type"
                      placeholder="01111111"
                      value={requestType}
                      onChange={handleRequestTypeChange}
                    />
                    <span className="mr-2 text-xs p-1">
                      <AiOutlineCaretDown />
                    </span>
                  </div>
                </div>

                <div className="mb-2 w-1/2 ">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="phoneNumber"
                  >
                    Phone Number
                  </label>
                  <div className="flex items-center border border-black p-2 rounded-lg">
                    <input
                      type="number"
                      id="phoneNumber"
                      className="w-full outline-none text-xs py-1"
                      name="phoneNumber"
                      placeholder="080000000000"
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                  </div>
                </div>
              </div>

              <div className="mb-1">
                <label className="block text-gray-400 text-xs" htmlFor="email">
                  Email Address(optional)
                </label>
                <input
                  placeholder="Email Address"
                  type="email"
                  id="email"
                  name="email"
                  className="w-full border border-black text-xs p-3 rounded-lg"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className="flex mt-6 justify-center">
                <div className="mb-1 w-1/2">
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="w-full bg-green-700 py-3 mt-2 rounded-lg text-white"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewCable;
