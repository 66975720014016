import React, { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import {
  IoArrowBackCircleOutline,
  IoCheckmarkCircleSharp,
} from "react-icons/io5";

const ReviewCard = ({
  selectedAmount,
  phoneNumber,
  product,
  onClose,
  payment,
  customerName,
  selectedPackage,
  requestType,
  iuc,
  email,
  warning,
}) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="bg-white rounded-lg p-4 shadow-md">
          <section className="px-4 py-3">
            <div className="mb-4 flex">
              <div className="text-gray-600 mb-2 mr-6">
                <IoArrowBackCircleOutline
                  className="text-3xl text-green-500 mr-2 cursor-pointer"
                  onClick={onClose}
                />
              </div>
              <div>
                <h2 className="text-gray-800 flex text-2xl font-bold">
                  {phoneNumber}
                  <span className="text-gray-400 ml-6 mt-1">
                    <AiFillEdit />
                  </span>
                </h2>
              </div>
            </div>
          </section>
          <section className="px-4 py-3">
            {customerName && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">Customer name:</p>
                <p className="text-gray-800  font-bold">{customerName}</p>
              </div>
            )}
            {product && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">Product name:</p>
                <p className="text-gray-800  font-bold">{product}</p>
              </div>
            )}
            {selectedAmount && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">Amount:</p>
                <p className="text-gray-800  font-bold"> ₦{selectedAmount}</p>
              </div>
            )}
            {phoneNumber && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">Phone Number:</p>
                <p className="text-gray-800  font-bold">{phoneNumber}</p>
              </div>
            )}
            {email && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">email address:</p>
                <p className="text-gray-800  font-bold"> {email}</p>
              </div>
            )}
            {payment && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">Product name:</p>
                <p className="text-gray-800  font-bold">{payment}</p>
              </div>
            )}
            {selectedPackage && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">package:</p>
                <p className="text-gray-800  font-bold"> {selectedPackage}</p>
              </div>
            )}
            {requestType && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">request type:</p>
                <p className="text-gray-800  font-bold">{requestType}</p>
              </div>
            )}
            {iuc && (
              <div className="mb-4 flex justify-between">
                <p className="text-gray-600 mb-2 mr-2">IUC:</p>
                <p className="text-gray-800  font-bold"> {iuc}</p>
              </div>
            )}
            <div className="border-gray-200 border-b-2 my-4 border-dotted"></div>
            {warning && (
              <p className="text-red-500 text-xs">Please enter phone Number</p>
            )}
            <p className="text-gray-500 text-xs">
              Please review the payment amount before proceeding.
            </p>
          </section>
          <div className="flex justify-center ">
            <button
              onClick={payment}
              className="px-4 w-64 py-2 bg-green-700 text-white rounded"
            >
              Pay
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewCard;
