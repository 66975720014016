import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import {
  AiFillEdit,
  AiFillEye,
  AiFillEyeInvisible,
  AiFillSetting,
  AiOutlineCloseCircle,
} from "react-icons/ai";
import { BiBell, BiPencil } from "react-icons/bi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { VscSearch } from "react-icons/vsc";
import { RiDeleteBin5Fill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";

const Settings = ({ darkMode }) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmUpdate, setConfirmUpdate] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const deleteCard = () => {
    setConfirmDelete(true);
  };

  const updateCard = () => {
    setConfirmUpdate(true);
  };

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div>
      {confirmDelete && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 shadow-md">
            <section className="px-4 py-4">
              <div className="flex justify-end">
                <AiOutlineCloseCircle
                  onClick={() => setConfirmDelete(false)}
                  className="text-red-600"
                />
              </div>
              <div className="flex justify-center">
                <div className="text-gray-600">
                  <RiDeleteBin5Fill className="text-9xl text-red-600 mr-2 cursor-pointer" />
                </div>
              </div>
            </section>
            <section className="px-4 py-2 text-center">
              <h3 className="text-xs mb-2 text-3xl font-bold">Heads up! </h3>
              <p className="mb-2 text-xs">Are you sure you want to continue?</p>
            </section>
            <div className="text-center ">
              <button className="px-8 text-xs py-2 mr-2 bg-red-600 text-white rounded">
                confirm
              </button>
              <button
                onClick={() => setConfirmDelete(false)}
                className="px-8 text-xs py-2 bg-gray-400 text-white rounded"
              >
                close
              </button>
            </div>
          </div>
        </div>
      )}
      {confirmUpdate && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-4 shadow-md">
            <section className="px-4 py-8">
              {/* <div className="flex justify-end">
                <AiOutlineCloseCircle onClick={()=>setConfirmUpdate(false)} className="text-red-600" />
              </div> */}
              <div className="flex justify-center">
                <div className="text-gray-600">
                  <FaCheck className="text-8xl text-green-600 cursor-pointer" />
                </div>
              </div>
            </section>
            <section className="px-4 text-center">
              <h3 className="mb-6 text-2xl font-bold">Profile Updated</h3>
            </section>
            <div className="text-center ">
              <button
                onClick={() => setConfirmUpdate(false)}
                className="px-8 text-xs py-2  bg-green-600 text-white rounded"
              >
                Back to Settings
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="flex-1 pt-4 pb-1 md:px-[3rem] mx-2">
        <div className="flex  justify-between items-center md:pb-8">
          <div className="flex flex-col md:flex-row md:items-center">
            <h1 className="md:mr-64 mt-6 md:mt-0 mx-2 mb-4  md:text-2xl text-2xl font-bold">
              Settings
            </h1>
            <div className="flex mb-4 md:mb-0 md:mr-8">
              <div className="relative mr-8">
                <input
                  type="text"
                  placeholder="Search..."
                  className="md:p-3 p-2 rounded-full md:w-96 pr-10"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <VscSearch className="font-bold text-xl" />
                </div>
              </div>

              <div className="flex md:space-x-6 space-x-1 md:mr-12 justify-center items-center">
                <div className="w-8 h-8  rounded-full flex justify-center items-center">
                  <BiBell className="text-2xl hover:text-green-400" />
                </div>

                <div className="w-8 h-8  items-center justify-center flex rounded-full">
                  <AiFillSetting className="text-2xl hover:text-green-400" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" mb-6 mr-6">
          <IoArrowBackCircleOutline
            onClick={() => (window.location.href = "/dash")}
            className="md:text-5xl text-3xl text-green-400 mb-6 cursor-pointer"
          />
        </div>
        <h2 className="font-bold mb-3">Profile Settings</h2>
        <div
          className={`p-4 shadow-md rounded-3xl ${
            darkMode ? "bg-[#212130] text-white" : "bg-white"
          }`}
        >
          <form>
            <section className="md:mx-12 md:p-12">
              <div className="flex">
                <div className="mb-1 w-1/2 mr-4">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  <div className="flex items-center border border-gray-300 p-2 rounded-lg">
                    <input
                      type="text"
                      id="firstName"
                      className="w-full outline-none text-xs py-1"
                      name="firstName"
                      placeholder="John"
                    />
                  </div>
                </div>

                <div className="mb-1 w-1/2">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <div className="flex items-center border border-gray-300 p-2 rounded-lg">
                    <input
                      type="text"
                      id="lastName"
                      className="w-full outline-none text-xs py-1"
                      name="lastName"
                      placeholder="Doe"
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="mb-1 w-1/2 mr-4">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="phoneNumber"
                  >
                    Phone Number
                  </label>
                  <div className="flex items-center border border-gray-300 p-2 rounded-lg">
                    <input
                      type="text"
                      id="phoneNumber"
                      className="w-full outline-none text-xs py-1"
                      name="phoneNumber"
                      placeholder="080000000000"
                    />
                  </div>
                </div>

                <div className="mb-1 w-1/2">
                  <label className="block text-gray-500 text-xs" htmlFor="bvn">
                    BVN
                  </label>
                  <div className="flex items-center border border-gray-300 p-2 rounded-lg">
                    <input
                      type="text"
                      id="bvn"
                      className="w-full outline-none text-xs py-1"
                      name="bvn"
                      placeholder="01111111"
                    />
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="mb-1 w-1/2 mr-4">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div className="flex items-center border border-gray-300 p-2 rounded-lg">
                    <input
                      type={`${showPassword ? "password" : "text"}`}
                      id="password"
                      className="w-full outline-none text-xs py-1"
                      name="password"
                      placeholder="****"
                    />
                    <span onClick={handlePassword}  className="mr-2 text-xs cursor-pointer">
                      {showPassword ? (
                        <AiFillEyeInvisible className="text-xl" />
                      ) : (
                        <AiFillEye className="text-xl" />
                      )}{" "}
                    </span>
                  </div>
                </div>
                <div className="mb-1 w-1/2">
                  <label
                    className="block text-gray-400 text-xs"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    placeholder="Email Address"
                    type="email"
                    id="email"
                    name="email"
                    className="w-full border border-gray-300 text-xs p-3 rounded-lg"
                  />
                </div>
              </div>

              <div className="flex mt-6">
                <div className="mb-1 w-1/2 mr-4 justify-center items-center flex">
                  <button
                    onClick={deleteCard}
                    type="button"
                    className="w-full bg-red-700 hover:bg-red-500 py-3 mt-2 rounded-lg text-white"
                  >
                    Delete Account
                  </button>
                </div>

                <div className="mb-1 w-1/2">
                  <button
                    onClick={updateCard}
                    type="button"
                    className="w-full bg-green-700 hover:bg-green-500 py-3 mt-2 rounded-lg text-white"
                  >
                    Save
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
