import React from "react";
import { AiFillCaretDown, AiFillSetting, AiOutlineCalendar } from "react-icons/ai";
import { BiBell, BiPencil } from "react-icons/bi";
import { IoAlert, IoArrowBackCircleOutline } from "react-icons/io5";
import { VscSearch } from "react-icons/vsc";
import exam from "../assets/exam 1.svg";
import tv from "../assets/television (3) 5.svg";
import bulb from "../assets/idea 1.svg";
import bet from "../assets/bet 1.svg";
import { Link } from "react-router-dom";
import { useState } from "react";
import DropBtn from "./DropBtn";
import { TiArrowSortedUp } from "react-icons/ti";

const PayBills = ({ darkMode }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [newNotification, setNewNotification] = useState(false);


  const toggleNewNotification = () => {
    setNewNotification(!newNotification);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  return (
    <div>
      <div className="flex-1 pt-4 pb-1 md:px-[3rem] mx-2">
        <div className="flex  justify-between items-center pb-8">
        <div className="flex mt-6 md:mt-0 flex-col md:flex-row md:items-center">
            <h1 className="md:mr-20 mx-2 mb-4  md:text-3xl text-2xl font-bold">
              Pay Bills
            </h1>
            <div className="flex mb-4 md:mb-0 md:mr-8">
              <div className="relative mr-8">
                <input
                  type="text"
                  placeholder="Search..."
                  className="md:p-3 p-2 rounded-full md:w-96 pr-10"
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <VscSearch className="font-bold text-xl" />
                </div>
              </div>

              <div className="flex md:space-x-6 space-x-1 md:mr-12 justify-center items-center">
                <button
                  onClick={toggleNewNotification}
                  type="button"
                  class={`relative flex items-center justify-center w-12 h-12 text-gray-700 rounded-full icon-button ${
                    newNotification === true ? "bg-gray-200" : ""
                  }`}
                >
                  <span class="material-icons">
                    <BiBell className="text-2xl hover:text-green-400 hover:text-3xl" />
                  </span>
                  <span class="absolute top-0 right-0 w-6 h-6 bg-green-500 text-white flex justify-center items-center rounded-full icon-button__badge">
                    2
                  </span>
                </button>

                <Link to="/settings">
                  <button
                    type="button"
                    class="relative flex items-center justify-center w-12 h-12 text-gray-700 rounded-full icon-button"
                  >
                    <span class="material-icons">
                      <AiFillSetting className="text-2xl hover:text-green-400" />
                    </span>
                    <span class="absolute top-0 right-0 w-6 h-6 bg-gray-500 text-white flex justify-center items-center rounded-full icon-button__badge">
                      <IoAlert />
                    </span>
                  </button>
                </Link>
              </div>
            </div>

            <div>
              <button
                className="bg-green-700 hover:bg-green-500 text-white text-sm px-6 py-2 rounded-lg relative flex"
                onClick={handleDropdownToggle}
              >
                Virtual Account
                {isDropdownOpen ? (
                  <TiArrowSortedUp className="ml-2 mt-1" />
                ) : (
                  <AiFillCaretDown className="ml-2 mt-1" />
                )}
              </button>
              {isDropdownOpen && <DropBtn />}
            </div>
          </div>
        </div>

        <div
          className={`p-4 md:px-16 shadow-md rounded-3xl ${
            darkMode ? "bg-[#212130] text-white" : "bg-gray-50"
          }`}
        >
          <h1 className="font-bold text-2xl text-center py-6">
            Get Instant Value
          </h1>
          <div className=" flex flex-col md:flex-row md:space-x-4 md:mb-16">
            <div
              className={`mb-4 md:mb-0 p-8 md:mr-16  h-[250px] shadow-md rounded-3xl md:w-1/2 ${
                darkMode ? "bg-[#212130] text-white" : "bg-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <img src={tv} alt="cable-tv" />
              </div>
              <p className="text-2xl text-green-600 text-center my-6">Cable</p>
              <div className="justify-center flex">
                <Link to="newcable">
                <button className="bg-green-700 text-white py-2 px-12 rounded-full">
                  Buy
                </button>
                </Link>
              </div>
            </div>
            <div
              className={`mb-4 md:mb-0 p-8 md:mr-16  h-[250px] shadow-md rounded-3xl md:w-1/2 ${
                darkMode ? "bg-[#212130] text-white" : "bg-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <img src={bulb} alt="cable-tv" />
              </div>
              <p className="text-2xl text-green-600 text-center my-6">Energy</p>
              <div className="justify-center flex">
              <Link to="newenergy">
                <button className="bg-green-700 text-white py-2 px-12 rounded-full">
                  Buy
                </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:space-x-4 md:mb-16">
            <div
              className={`mb-4 md:mb-0 p-8 md:mr-16  h-[250px] shadow-md rounded-3xl md:w-1/2 ${
                darkMode ? "bg-[#212130] text-white" : "bg-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <img src={bet} alt="cable-tv" />
              </div>
              <p className="text-2xl text-green-600 text-center my-6">Bet</p>
              <div className="justify-center flex">
              <Link to="newbet">
                <button className="bg-green-700 text-white py-2 px-12 rounded-full">
                  Buy
                </button>
                </Link>
              </div>
            </div>
            <div
              className={`mb-4 md:mb-0 p-8 md:mr-16  h-[250px] shadow-md rounded-3xl md:w-1/2 ${
                darkMode ? "bg-[#212130] text-white" : "bg-white"
              }`}
            >
              <div className="flex justify-center items-center">
                <img src={exam} alt="cable-tv" />
              </div>
              <p className="text-2xl text-green-600 text-center my-6">Exams</p>
              <div className="justify-center flex">
              <Link to="newexams">
                <button className="bg-green-700 text-white py-2 px-12 rounded-full">
                  Buy
                </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayBills;
