import { toast } from "react-toastify";

export const handleChange = (e, setErrors, setFormData) => {
  setErrors({});
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

export const handleNumberChange = (e, setErrors, setFormData) => {
  setErrors({});
  const { name, value } = e.target;
  if (!/^\d+$/.test(value) && value !== "") {
    return;
  }
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

export const handleKeyPress = (e, setFormData) => {
  let input = e.target.value;

  if (input.trim() === "") {
    return;
  }

  if (input.includes(",")) {
    input = input.replace(/,/g, "");
  }

  let num = isNaN(input) ? 0 : parseFloat(input);

  const parse = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  const splittedValue = parse.split(".")[0];
  setFormData((prevData) => {
    return {
      ...prevData,
      amount: splittedValue,
      parsedAmount: parseInt(splittedValue.replace(/,/g, "")),
    };
  });
};

export function ensureIsArray(responseData) {
  if (Array.isArray(responseData)) {
    return responseData;
  } else {
    return Object.keys(responseData).map((key) => {
      return { ...responseData[key] };
    });
  }
}

export function handle422(e, { formStep, back }) {
  let r = e.response;
  console.log("hello", r);
  if (r.status === 422) {
    if (formStep === 1) {
      back();
    }
    let error_bag = r.data.data;

    const keys = Object.keys(error_bag);
    let first_error_name = keys[0];
    let first_error_value = error_bag[first_error_name];

    toastError(first_error_value[0]);
  }
}

export function handle400(e, { formStep, back }) {
  let r = e.response;
  if (r.status === 400) {
    if (formStep === 1) {
      back();
    }

    var str = r.data.message;

    // Customize specific error message
    if (str.includes("Invalid Account number")) {
      str = "Invalid meter number";
    }

    str = str.replace(/_/g, " ");
    toastError(str);
  }
}

export function handle500(e, { formStep, back }) {
  let r = e.response;
  console.log("hello", r);
  if (r.status === 500) {
    if (formStep === 1) {
      back();
    }
    toastError("An error occurred on the server. Please try again later.");
  }
}

function toastError(message) {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: "light",
  });
}
