import React from "react";
import { useReducer } from "react";
import { createContext, useContext } from "react";

const ModalContext = createContext();

export const init = {
  isSignUpModal: "",
  isLoginModal: "",
  isForgotModal: "",
};

export const authreducer = (state, action) => {
  switch (action.type) {
    case "Modal":
      return {
        ...state,
        isSignUpModal: action.isSignUpModal,
        isLoginModal: action.isLoginModal,
        isForgotModal: action.isForgotModal,
      };
    default:
      return state;
  }
};

export const ModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authreducer, init);

  return (
    <ModalContext.Provider value={{ ...state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
