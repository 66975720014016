import { BrowserRouter, Routes, Route } from "react-router-dom";

import Business from "../pages/Business";
import Home from "../pages/Home";
import Personal from "../pages/Personal";

import Energy from "../pages/electricity/Energy";
import Airtime from "../pages/airtime/Airtime";
import Data from "../pages/data/Data";
import Cable from "../pages/TVsub/Cable";
import Bet from "../pages/Bet/Bet";
import Education from "../pages/Education/Education";
import PaymentVerification from "../pages/PaymentVerification";
import GetStarted from "../pages/GetStarted";
import ResetAccount from "../pages/ResetAccount";
import { useState } from "react";
import { IoCloseCircle } from "react-icons/io5";
import { BiHide, BiShow } from "react-icons/bi";
import { useModal } from "../ModalContext";
import Layout from "../pages/Layout";


const AppRoutes = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Signup form submitted");
    console.log("Email:", email);
    console.log("Password:", password);
    console.log("Referral Email:", referralEmail);
    console.log("Terms Checked:", termsChecked);
  };
  const { isSignUpModal, isLoginModal, isForgotModal, dispatch } = useModal();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referralEmail, setReferralEmail] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/personal" element={<Personal />} />
          <Route path="/business" element={<Business />} />
          <Route path="/airtime" element={<Airtime />} />
          <Route path="/energy" element={<Energy />} />
          <Route path="/data" element={<Data />} />
          <Route path="/cable" element={<Cable />} />
          <Route path="/bet" element={<Bet />} />
          <Route path="/education" element={<Education />} />
          <Route path="/payment/verify" element={<PaymentVerification />} />
          <Route path="/resetaccount" element={<ResetAccount />} />
          <Route path="/getstarted" element={<GetStarted />} />

          <Route path="/*" exact element={<Layout />} />
        </Routes>
      </BrowserRouter>
      {isForgotModal && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[#162300CC] bg-opacity-50">
          <button
            onClick={() => dispatch({ type: "Modal", isForgotModal: false })}
            className="text-white absolute top-12 text-4xl right-10"
          >
            <IoCloseCircle />
          </button>
          <div className="flex justify-center mt-24 items-start h-full">
            <div className="p-2 rounded">
              <div className="flex justify-center items-center">
                <div className="w-96 p-2 text-white">
                  <h2 className="text-center text-2xl font-bold py-8">
                    Forget Password
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="block text-xs mb-2 font-semibold"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                        placeholder="Email Address"
                      />
                      <div className="mt-4 text-center justify-center flex text-xs">
                        <p className="pr-1">I remember now?</p>
                        <p
                          onClick={() =>
                            dispatch({ type: "Modal", isLoginModal: true })
                          }
                          className="text-green-500 cursor-pointer hover:text-green-700"
                        >
                          Login
                        </p>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="w-full py-2 px-4 font-semibold bg-green-700 text-white rounded-md hover:bg-green-500 mt-2"
                    >
                      Continue
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isSignUpModal && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[#162300CC] bg-opacity-50">
          <button
            onClick={() => dispatch({ type: "Modal", isSignUpModal: false })}
            className="text-white absolute top-12 text-4xl right-10"
          >
            <IoCloseCircle />
          </button>
          <div className="flex justify-center mt-14 md:mt-8 items-start h-full">
            <div className="p-2 rounded">
              <div className="flex justify-center items-center">
                <div className="w-96 p-2 text-white">
                  <h2 className="text-center text-2xl md:text-3xl font-semibold py-2">
                    Welcome to BillerHQ
                  </h2>
                  <p className="text-center text-sm mb-8">
                    Sign up and start making payments
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-2 flex">
                      <div className="mr-2 w-1/2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="firstName"
                            className="block mb-2 text-xs font-semibold"
                          >
                            First Name
                          </label>
                        </div>
                        <input
                          type="text"
                          id="firstName"
                          className="w-full p-3 text-xs border-gray-300 text-black border rounded"
                          placeholder="First Name"
                        />
                      </div>
                      <div className="w-1/2">
                        <div className="flex justify-between">
                          <label
                            htmlFor="lastName"
                            className="block mb-2 text-xs font-semibold"
                          >
                            Last Name
                          </label>
                        </div>
                        <input
                          type="text"
                          id="lastName"
                          className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>

                    <div className="mb-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="phoneNumber"
                          className="block mb-2 text-xs font-semibold"
                        >
                          Phone Number
                        </label>
                      </div>

                      <input
                        type="text"
                        id="phoneNumber"
                        className="w-full p-3 text-black text-xs border-gray-300 border rounded"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="block text-xs mb-2 font-semibold"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="password"
                          className="block mb-2 text-xs font-semibold"
                        >
                          Password
                        </label>
                      </div>
                      <div className="flex relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                          placeholder="Password"
                        />
                        <button
                          className="text-xs absolute right-4 top-3 ml-1 text-gray-500 hover:text-green-500"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <BiHide className="text-xl" />
                          ) : (
                            <BiShow className="text-xl" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mb-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="confirmPassword"
                          className="block mb-2 text-xs font-semibold"
                        >
                          Confirm Password
                        </label>
                      </div>

                      <div className="flex relative">
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                          placeholder="Confirm Password"
                        />
                        <button
                          className="text-xs absolute right-4 top-3 ml-1 text-gray-500 hover:text-green-500"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <BiHide className="text-xl" />
                          ) : (
                            <BiShow className="text-xl" />
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="text-xs flex my-4">
                      <label htmlFor="agree" className="flex items-center mr-2">
                        <input
                          type="checkbox"
                          className="mr-1 hover:cursor-pointer"
                        />
                        <span>By signing up I agree with</span>
                      </label>
                      <div className="ml-auto">
                        <a
                          href="#"
                          className="text-green-500 hover:text-green-600 underline"
                        >
                          Terms & Condition
                        </a>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="w-full py-2 px-4 font-semibold bg-green-700 text-white rounded-md hover:bg-green-500 mt-2"
                      onClick={() => {}}
                    >
                      Sign Up
                    </button>
                  </form>

                  <p className="mt-8 text-center flex justify-center text-xs">
                    <span className="pr-1">Already have an account?</span>
                    <div
                      onClick={() =>
                        dispatch({ type: "Modal", isLoginModal: true })
                      }
                      className="text-green-500 cursor-pointer hover:text-green-600"
                    >
                      Login
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isLoginModal && (
        <div className="fixed top-0 left-0 w-screen h-screen bg-[#162300CC] bg-opacity-50">
          <button
            onClick={() => dispatch({ type: "Modal", isLoginModal: false })}
            className="text-white absolute top-12 text-4xl right-10"
          >
            <IoCloseCircle />
          </button>
          <div className="flex justify-center mt-24 items-start h-full">
            <div className="p-2 rounded">
              <div className="flex justify-center items-center">
                <div className="w-96 p-2 text-white">
                  <h2 className="text-center text-2xl font-bold py-4">
                    Sign In
                  </h2>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                      <label
                        htmlFor="email"
                        className="block text-xs mb-2 font-semibold"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                        placeholder="Email Address"
                      />
                    </div>
                    <div className="mb-2">
                      <div className="flex justify-between">
                        <label
                          htmlFor="password"
                          className="block mb-2 text-xs font-semibold"
                        >
                          Password
                        </label>
                        <p
                          onClick={() =>
                            dispatch({ type: "Modal", isForgotModal: true })
                          }
                          className="text-xs cursor-pointer hover:text-green-500"
                        >
                          Forgot password?
                        </p>
                      </div>
                      <div className="flex relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                          placeholder="Password"
                        />
                        <button
                          className="text-xs absolute right-4 top-3 ml-1 text-gray-500 hover:text-green-500"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <BiHide className="text-xl" />
                          ) : (
                            <BiShow className="text-xl" />
                          )}
                        </button>
                      </div>
                    </div>

                    <button
                      type="button"
                      className="w-full py-2 px-4 font-semibold bg-green-700 text-white rounded-md hover:bg-green-500 mt-2"
                    >
                      Sign In
                    </button>
                  </form>

                  <div className="mt-8 text-center justify-center flex text-xs">
                    <p className="pr-1">New to BillerHQ?</p>
                    <p
                      onClick={() =>
                        dispatch({ type: "Modal", isSignUpModal: true })
                      }
                      className="cursor-pointer text-green-500 hover:text-green-600"
                    >
                      Sign up here
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppRoutes;
