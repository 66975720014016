const CableIcon = () => (
  <svg
    width="20"
    height="29"
    viewBox="0 0 28 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.75 8.79321C24.0815 8.79321 24.3995 8.66152 24.6339 8.4271C24.8683 8.19268 25 7.87473 25 7.54321C25 7.21169 24.8683 6.89375 24.6339 6.65933C24.3995 6.42491 24.0815 6.29321 23.75 6.29321C23.4185 6.29321 23.1005 6.42491 22.8661 6.65933C22.6317 6.89375 22.5 7.21169 22.5 7.54321C22.5 7.87473 22.6317 8.19268 22.8661 8.4271C23.1005 8.66152 23.4185 8.79321 23.75 8.79321ZM23.75 12.7932C24.0815 12.7932 24.3995 12.6615 24.6339 12.4271C24.8683 12.1927 25 11.8747 25 11.5432C25 11.2117 24.8683 10.8937 24.6339 10.6593C24.3995 10.4249 24.0815 10.2932 23.75 10.2932C23.4185 10.2932 23.1005 10.4249 22.8661 10.6593C22.6317 10.8937 22.5 11.2117 22.5 11.5432C22.5 11.8747 22.6317 12.1927 22.8661 12.4271C23.1005 12.6615 23.4185 12.7932 23.75 12.7932ZM4 9.29321C4 8.76278 4.21071 8.25407 4.58579 7.879C4.96086 7.50393 5.46957 7.29321 6 7.29321H19C19.5304 7.29321 20.0391 7.50393 20.4142 7.879C20.7893 8.25407 21 8.76278 21 9.29321V18.2932C21 18.8236 20.7893 19.3324 20.4142 19.7074C20.0391 20.0825 19.5304 20.2932 19 20.2932H6C5.46957 20.2932 4.96086 20.0825 4.58579 19.7074C4.21071 19.3324 4 18.8236 4 18.2932V9.29321Z"
      // fill="#A1A1AA"
    />
    <path
      d="M17.95 0.293287C17.8571 0.20031 17.7468 0.126551 17.6254 0.0762269C17.504 0.0259027 17.3739 0 17.2425 0C17.1111 0 16.981 0.0259027 16.8596 0.0762269C16.7382 0.126551 16.6279 0.20031 16.535 0.293287L13.535 3.29329H12.415L9.415 0.293287C9.22749 0.105646 8.97312 0.000177927 8.70785 8.41618e-05C8.44258 -9.60301e-06 8.18814 0.105279 8.0005 0.292787C7.81286 0.480295 7.70739 0.734663 7.7073 0.999933C7.7072 1.2652 7.81249 1.51965 8 1.70729L9.586 3.29329H4C2.93913 3.29329 1.92172 3.71471 1.17157 4.46486C0.421427 5.215 0 6.23242 0 7.29329V20.2933C0 21.3542 0.421427 22.3716 1.17157 23.1217C1.92172 23.8719 2.93913 24.2933 4 24.2933H4.678L3.982 26.8923C3.87838 27.2764 3.92448 27.6856 4.11097 28.0371C4.29746 28.3885 4.61044 28.6561 4.98664 28.7856C5.36283 28.9152 5.77418 28.897 6.13753 28.7349C6.50088 28.5728 6.78913 28.2788 6.944 27.9123L8.48 24.2933H19.52L21.057 27.9123C21.2119 28.2788 21.5001 28.5728 21.8635 28.7349C22.2268 28.897 22.6382 28.9152 23.0144 28.7856C23.3906 28.6561 23.7035 28.3885 23.89 28.0371C24.0765 27.6856 24.1226 27.2764 24.019 26.8923L23.32 24.2933H24C25.0609 24.2933 26.0783 23.8719 26.8284 23.1217C27.5786 22.3716 28 21.3542 28 20.2933V7.29329C28 6.23242 27.5786 5.215 26.8284 4.46486C26.0783 3.71471 25.0609 3.29329 24 3.29329H16.364L17.95 1.70729C18.1375 1.51976 18.2428 1.26545 18.2428 1.00029C18.2428 0.735123 18.1375 0.480814 17.95 0.293287ZM4 5.29329H24C24.5304 5.29329 25.0391 5.504 25.4142 5.87907C25.7893 6.25415 26 6.76285 26 7.29329V20.2933C26 20.8237 25.7893 21.3324 25.4142 21.7075C25.0391 22.0826 24.5304 22.2933 24 22.2933H4C3.46957 22.2933 2.96086 22.0826 2.58579 21.7075C2.21071 21.3324 2 20.8237 2 20.2933V7.29329C2 6.76285 2.21071 6.25415 2.58579 5.87907C2.96086 5.504 3.46957 5.29329 4 5.29329Z"
      // fill="#A1A1AA"
    />
  </svg>
);

export default CableIcon;
