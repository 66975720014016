const EnergyIcon = () => (
    <svg
      width="17"
      height="25"
      viewBox="0 0 17 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86374 13.5307C2.44783 14.2447 3.93418 16.8902 4.24991 18.215H12.7502C13.066 16.8914 14.5505 14.246 15.1346 13.5325C16.1327 12.315 16.761 10.8369 16.9452 9.27326C17.1268 7.71267 16.8551 6.13279 16.1625 4.72254C15.4634 3.30183 14.3763 2.10805 13.0271 1.2793C11.666 0.442128 10.0992 -0.000749784 8.50129 9.52864e-07C6.90019 9.52864e-07 5.33127 0.443233 3.97547 1.27809C2.6259 2.10652 1.53843 3.30009 0.838843 4.72072C0.145883 6.13042 -0.126455 7.70986 0.054384 9.27022C0.235927 10.8288 0.86313 12.3061 1.86374 13.5307ZM9.10724 4.85733L5.46424 10.3218H7.89291V13.9648L11.5359 8.50033H9.10724V4.85733Z"
      />
      <path
        d="M4.25 20.0361C4.25 19.8751 4.31397 19.7207 4.42783 19.6068C4.5417 19.4929 4.69614 19.429 4.85717 19.429H12.1432C12.3042 19.429 12.4586 19.4929 12.5725 19.6068C12.6864 19.7207 12.7503 19.8751 12.7503 20.0361C12.7503 20.1972 12.6864 20.3516 12.5725 20.4655C12.4586 20.5793 12.3042 20.6433 12.1432 20.6433H4.85717C4.69614 20.6433 4.5417 20.5793 4.42783 20.4655C4.31397 20.3516 4.25 20.1972 4.25 20.0361ZM12.7503 21.8576H4.25V23.072C4.25 23.394 4.37794 23.7029 4.60567 23.9306C4.8334 24.1584 5.14227 24.2863 5.46433 24.2863H11.536C11.8581 24.2863 12.1669 24.1584 12.3947 23.9306C12.6224 23.7029 12.7503 23.394 12.7503 23.072V21.8576Z"
      />
    </svg>
  );
  
  export default EnergyIcon;
  