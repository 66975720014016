import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import { FaEllipsisV, FaMoon, FaSun } from "react-icons/fa";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./Dashboard";
import Dash from "../components/Dash";
import TransactionHistory from "../components/TransactionHistory";
import Settings from "../components/Settings";
import Recurring from "../components/Recurring";
import AirtimeData from "../components/AirtimeData";
import PayBills from "../components/PayBills";
import NewCable from "../components/NewCable";
import NewEnergy from "./NewEnergy";
import NewBet from "./NewBet";
import NewExams from "./NewExams";
import { GiHamburgerMenu } from "react-icons/gi";
import VirtualEnergy from "../components/VirtualEnergy";
import VirtualCable from "../components/VirtualCable";

const Layout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark-mode");
    } else {
      document.documentElement.classList.remove("dark-mode");
    }
  }, [darkMode]);

  useEffect(() => {
    const currentHour = new Date().getHours();
    if (currentHour >= 19 || currentHour < 7) {
      setDarkMode(true);
    }
  }, []);

  return (
    <>
      <button onClick={toggleDarkMode} className="mode-toggle z-40">
        {darkMode ? <FaSun /> : <FaMoon />}
      </button>
      <div className="mobile-menu-icon z-40 md:hidden m-2" onClick={toggleSidebar}>
        <GiHamburgerMenu className="text-2xl " />
      </div>
      <div className="dashboard-contain md:w-[1500px] mx-auto">
        <div
          className={`flex justify-center py-4 ${
            darkMode ? "bg-[#171622]" : "bg-[#F4F7F7]"
          }`}
        >
          <div
            className={`sidebar md:w-1/5 ${
              sidebarOpen ? "open" : "hidden md:block"
            }`}
          >
            <Sidebar sidebarOpen={sidebarOpen} darkMode={darkMode} />
          </div>

          <div className="main-content md:w-4/5">
            <Routes>
              <Route
                path="dash"
                index
                exact
                element={<Dash darkMode={darkMode} />}
              />
              <Route
                path="airtimedata"
                exact
                element={<AirtimeData darkMode={darkMode} />}
              />
              <Route
                path="paybills"
                exact
                element={<PayBills darkMode={darkMode} />}
              />
              <Route
                path="recurringpayment"
                exact
                element={<Recurring darkMode={darkMode} />}
              />
              <Route
                path="transactionhistory"
                exact
                element={<TransactionHistory darkMode={darkMode} />}
              />
              <Route
                path="settings"
                exact
                element={<Settings darkMode={darkMode} />}
              />

              <Route
                path="paybills/newcable"
                exact
                element={<NewCable darkMode={darkMode} />}
              />
              <Route
                path="paybills/newenergy"
                exact
                element={<NewEnergy darkMode={darkMode} />}
              />
              <Route
                path="paybills/newbet"
                exact
                element={<NewBet darkMode={darkMode} />}
              />
              <Route
                path="paybills/newexams"
                exact
                element={<NewExams darkMode={darkMode} />}
              />

              <Route
                path="virtualaccounts/energy"
                exact
                element={<VirtualEnergy darkMode={darkMode} />}
              />
                <Route
                path="virtualaccounts/cable"
                exact
                element={<VirtualCable darkMode={darkMode} />}
              />
            </Routes>
          </div>
          {sidebarOpen && (
            <>
              <div className="md:hidden fixed inset-0 z-30 bg-gray-700">
                <Sidebar toggleSidebar sidebarOpen={sidebarOpen} darkMode={darkMode} />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Layout;
