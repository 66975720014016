import { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./PaymentVerification.css";
import { IoCloseCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { GiCancel } from "react-icons/gi";
import { FcCancel } from "react-icons/fc";
import { BiShow, BiHide } from "react-icons/bi";

const baseURL = process.env.REACT_APP_BASE_URL;

const ResetAccount = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [error, setError] = useState(false);
  const [ref, setRef] = useState(false);
  const [msg, setMsg] = useState("");
  const [amount, setAmount] = useState("");
  const [token, setToken] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [transactionType, setTransactionType] = useState("");
  const getRef = useRef(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onClose = (e) => {
    e.preventDefault();
    window.location.href = "/";
  };

  useEffect(() => {
    if (getRef.current) {
      getRef.current = false;

      const urlParams = new URLSearchParams(window.location.search);

      const reference = urlParams.get("reference");

      setRef(reference);

      if (!reference) {
        setIsLoading(false);
      } else {
        axios
          .post(`${baseURL}/payments/verify`, {
            reference,
          })
          .then((response) => {
            const message = response.data.message;
            const responseData = response.data.data;

            setMsg(message);

            if (response.data.status === "success") {
              // window.location.href = `/personal?payment=true&service_type=${responseData.service_type || ""}&transaction_type=${responseData.transaction_type || ""}&amount=${responseData.amount || ""}&token=${responseData.token || ""}&reference=${reference || ""}&message=${message || ""}`;
              setSuccess(true);
              setServiceType(responseData?.service_type || "");
              setTransactionType(responseData?.transaction_type || "");
              setAmount(responseData?.amount || "");
              setToken(responseData?.token || "");
            } else {
              // window.location.href = `/personal?payment=true&service_type=${responseData.service_type || ""}&transaction_type=${responseData.transaction_type || ""}&amount=${responseData.amount || ""}&token=${responseData.token || ""}&reference=${reference || ""}&message=${message || ""}`;
              setIsDuplicate(true);
            }
          })
          .catch((error) => {
            const errorStatus = error.response.data.status;
            const message = error.response.data.message;
            setMsg(message);
            setError(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }, []);

  return (
    <div className="fixed top-0 left-0 w-screen h-screen bg-[#162300CC] bg-opacity-50">
      <button onClick={onClose} className="text-white absolute top-12 text-4xl right-10">
        <IoCloseCircle />
      </button>
      <div className="flex justify-center mt-24 items-start h-full">
        <div className="p-2 rounded">
          <div className="flex justify-center items-center">
            <div className="w-96 p-2 text-white">
              <h2 className="text-center text-2xl font-semibold py-8">
                Reset Your Account
              </h2>
              <form>
                <div className="mb-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="new-password"
                      className="block mb-2 text-xs font-semibold"
                    >
                      New Password
                    </label>
                  </div>
                  <div className="flex relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="new-password"
                      className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                      placeholder="New Password"
                    />
                    <button
                      type="button"
                      className="text-xs absolute right-4 top-3 ml-1 text-gray-500 hover:text-green-500"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <BiHide className="text-xl" />
                      ) : (
                        <BiShow className="text-xl" />
                      )}
                    </button>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="flex justify-between">
                    <label
                      htmlFor="retype-password"
                      className="block mb-2 text-xs font-semibold"
                    >
                      Re-type Password
                    </label>
                  </div>
                  <div className="flex relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="retype-password"
                      className="w-full p-3 text-xs text-black border-gray-300 border rounded"
                      placeholder="Re-type Password"
                    />
                    <button
                      type="button"
                      className="text-xs absolute right-4 top-3 ml-1 text-gray-500 hover:text-green-500"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <BiHide className="text-xl" />
                      ) : (
                        <BiShow className="text-xl" />
                      )}
                    </button>
                  </div>
                </div>

                <button
                  type="button"
                  className="w-full py-2 px-4 font-semibold bg-green-700 text-white rounded-md hover:bg-green-500 mt-4"
                >
                  Continue
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div>
    //   {isLoading && <div className="loading"></div>}

    //   {success && (
    //     <div className="flex items-center justify-center h-screen bg-black bg-opacity-50 ">
    //       <div className="card">
    //         <section>
    //           <Link to="/personal">
    //             <button className="cancel-button">
    //               <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M3.293 3.293a1 1 0 011.414 0L12 10.586l7.293-7.293a1 1 0 011.414 1.414L13.414 12l7.293 7.293a1 1 0 01-1.414 1.414L12 13.414l-7.293 7.293a1 1 0 01-1.414-1.414L10.586 12 3.293 4.707a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 />
    //               </svg>
    //             </button>
    //           </Link>
    //           <div className="flex justify-center">
    //             <div className="w-[60px] h-[60px] rounded-full bg-green-100 flex justify-center items-center">
    //               <IoCheckmarkCircleSharp className="w-10 h-10 text-green-700" />
    //             </div>
    //           </div>
    //           {/* <h1 className="text-center mt-2 mb-2"> {msg}</h1> */}
    //           <h1 className="text-center text-xl mt-2 mb-2">
    //             {" "}
    //             Payment succesful!
    //           </h1>

    //           {amount && (
    //             <p className="font-bold text-2xl mb-2 text-center">
    //               ₦{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //             </p>
    //           )}
    //         </section>

    //         <section className="px-4 py-3">
    //           {transactionType && (
    //             <div className="mb-4 flex justify-between">
    //               <p className="text-gray-800 mb-2 mr-4">Service: </p>
    //               <p className="text-gray-800  font-bold"> {transactionType}</p>
    //             </div>
    //           )}
    //           {serviceType && (
    //             <div className="mb-4 flex justify-between">
    //               <p className="text-gray-600 mb-2 mr-4">Service Provider: </p>
    //               <p className="text-gray-800  font-bold"> {serviceType}</p>
    //             </div>
    //           )}

    //           {token && (
    //             <div className="mb-4 flex justify-between">
    //               <p className="text-gray-600 mb-2 mr-4">Token:</p>
    //               <p className="text-gray-800  font-bold">{token}</p>
    //             </div>
    //           )}

    //           <div className="mb-4 flex justify-between">
    //             <p className="text-gray-600 mb-2 mr-2">Ref number:</p>
    //             <p className="text-gray-800  font-bold">{ref}</p>
    //           </div>
    //           <div className="border-gray-200 border-b-2 my-4 border-dotted"></div>
    //           {amount && (
    //             <div className="mb-4 mt-2 flex justify-between">
    //               <p className="text-gray-600 mb-2 mr-4">Amount:</p>
    //               <p className="text-gray-800  font-bold">
    //                 ₦{amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
    //               </p>
    //             </div>
    //           )}
    //         </section>
    //       </div>
    //     </div>
    //   )}

    //   {isDuplicate && (
    //     <div className="flex items-center justify-center h-screen bg-black bg-opacity-50 ">
    //       <div className="card">
    //         <section>
    //           <Link to="/personal">
    //             <button className="cancel-button">
    //               <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M3.293 3.293a1 1 0 011.414 0L12 10.586l7.293-7.293a1 1 0 011.414 1.414L13.414 12l7.293 7.293a1 1 0 01-1.414 1.414L12 13.414l-7.293 7.293a1 1 0 01-1.414-1.414L10.586 12 3.293 4.707a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 />
    //               </svg>
    //             </button>
    //           </Link>
    //           <div className="flex justify-center">
    //             <div className="w-[60px] h-[60px] rounded-full bg-red-100 flex justify-center items-center">
    //               <FcCancel className="w-10 h-10 text-red-700" />
    //             </div>
    //           </div>
    //           <h1 className="text-center mt-2 mb-2"> {msg}</h1>
    //         </section>

    //         <section className="px-4 py-3">
    //           <div className="mb-4 flex justify-between">
    //             <p className="text-gray-600 mb-2 mr-2">Ref number:</p>
    //             <p className="text-gray-800  font-bold">{ref}</p>
    //           </div>
    //           <div className="border-gray-200 border-b-2 my-4 border-dotted"></div>
    //         </section>
    //       </div>
    //     </div>
    //   )}

    //   {error && (
    //     <div className="flex items-center justify-center h-screen bg-black bg-opacity-50 ">
    //       <div className="card">
    //         <section>
    //           <Link to="/personal">
    //             <button className="cancel-button">
    //               <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
    //                 <path
    //                   fillRule="evenodd"
    //                   d="M3.293 3.293a1 1 0 011.414 0L12 10.586l7.293-7.293a1 1 0 011.414 1.414L13.414 12l7.293 7.293a1 1 0 01-1.414 1.414L12 13.414l-7.293 7.293a1 1 0 01-1.414-1.414L10.586 12 3.293 4.707a1 1 0 010-1.414z"
    //                   clipRule="evenodd"
    //                 />
    //               </svg>
    //             </button>
    //           </Link>
    //           <div className="flex justify-center">
    //             <div className="w-[60px] h-[60px] rounded-full bg-red-100 flex justify-center items-center">
    //               <GiCancel className="w-10 h-10 text-red-700" />
    //             </div>
    //           </div>
    //           <h1 className="text-center mt-2 mb-2"> {msg}</h1>
    //         </section>

    //         <section className="px-4 py-3">
    //           <div className="mb-4 flex justify-between">
    //             <p className="text-gray-600 mb-2 mr-2">Ref number:</p>
    //             <p className="text-gray-800  font-bold">{ref}</p>
    //           </div>
    //           <div className="border-gray-200 border-b-2 my-4 border-dotted"></div>
    //         </section>
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default ResetAccount;
