import React, { useState } from "react";
import { AiFillSetting, AiOutlineCalendar } from "react-icons/ai";
import { BiBell, BiInfoCircle } from "react-icons/bi";
import { FaEllipsisV } from "react-icons/fa";
import { VscSearch } from "react-icons/vsc";
import DateRangeComp from "./DateRangeComp";
import Details from "./Details";

const TransactionHistory = ({ darkMode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [selectedChoice, setSelectedChoice] = useState("all");

  const [showInfo, setShowInfo] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSelectedChoice = (event, choice) => {
    setSelectedChoice(choice);
  };

  const handleInfo = () => {
    setShowInfo(!showInfo)
  }
  const handleDetails = () => {
    setShowDetails(!showDetails)
  }

  const close = () => {
    setShowDetails(false)
  }

  const renderTable = () => {
    if (selectedChoice === "all") {
      return (
        <table className="min-w-full ">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#12345</td>
              <td className="py-2 px-4 text-sm">2023-08-15</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">123456789</td>
              <td className="py-2 px-4 text-sm">₦10000</td>
              <td className="py-2 px-4 flex relative">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div onClick={handleInfo} className="ml-auto">
                  <FaEllipsisV className="mt-1 cursor-pointer" />
                { showInfo && <div onClick={handleDetails} className="bg-gray-200 p-2 cursor-pointer rounded-lg absolute right-3 text-xs flex">
                    <span><BiInfoCircle className="mr-1 text-lg" /></span>View Details
                  </div>}
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#67890</td>
              <td className="py-2 px-4 text-sm">2023-08-16</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Airtime</td>
              <td className="py-2 px-4 text-sm">Airtel</td>
              <td className="py-2 px-4 text-sm">987654321</td>
              <td className="py-2 px-4 text-sm">₦7500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#13579</td>
              <td className="py-2 px-4 text-sm">2023-08-17</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Airtime</td>
              <td className="py-2 px-4 text-sm">Glo</td>
              <td className="py-2 px-4 text-sm">246813579</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#24680</td>
              <td className="py-2 px-4 text-sm">2023-08-18</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Betting</td>
              <td className="py-2 px-4 text-sm">betNaija</td>
              <td className="py-2 px-4 text-sm">135792468</td>
              <td className="py-2 px-4 text-sm">₦5000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 text-xs text-white px-4 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#35791</td>
              <td className="py-2 px-4 text-sm">2023-08-19</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Data</td>
              <td className="py-2 px-4 text-sm">MTN</td>
              <td className="py-2 px-4 text-sm">579135791</td>
              <td className="py-2 px-4 text-sm">₦8500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#46802</td>
              <td className="py-2 px-4 text-sm">2023-08-20</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">246802468</td>
              <td className="py-2 px-4 text-sm">₦9500</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#57913</td>
              <td className="py-2 px-4 text-sm">2023-08-21</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Cable</td>
              <td className="py-2 px-4 text-sm">DSTV </td>
              <td className="py-2 px-4 text-sm">357913579</td>
              <td className="py-2 px-4 text-sm">₦6000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 text-xs text-white px-4 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#68024</td>
              <td className="py-2 px-4 text-sm">2023-08-22</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">802468024</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#79135</td>
              <td className="py-2 px-4 text-sm">2023-08-23</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Airtime</td>
              <td className="py-2 px-4 text-sm">Airtel</td>
              <td className="py-2 px-4 text-sm">913579135</td>
              <td className="py-2 px-4 text-sm">₦15000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#80246</td>
              <td className="py-2 px-4 text-sm">2023-08-24</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Cable</td>
              <td className="py-2 px-4 text-sm">DSTV</td>
              <td className="py-2 px-4 text-sm">135791357</td>
              <td className="py-2 px-4 text-sm">₦8000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else if (selectedChoice === "completed") {
      return (
        <table className="min-w-full ">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#12345</td>
              <td className="py-2 px-4 text-sm">2023-08-15</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">123456789</td>
              <td className="py-2 px-4 text-sm">₦10000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#13579</td>
              <td className="py-2 px-4 text-sm">2023-08-17</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">246813579</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#46802</td>
              <td className="py-2 px-4 text-sm">2023-08-20</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">246802468</td>
              <td className="py-2 px-4 text-sm">₦9500</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#79135</td>
              <td className="py-2 px-4 text-sm">2023-08-23</td>
              <td className="py-2 px-4 text-sm">Bank Transfer</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">913579135</td>
              <td className="py-2 px-4 text-sm">₦15000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-green-500 text-xs text-white px-2 py-1 rounded-full">
                  Success
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else if (selectedChoice === "pending") {
      return (
        <table className="min-w-full ">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#67890</td>
              <td className="py-2 px-4 text-sm">2023-08-16</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">987654321</td>
              <td className="py-2 px-4 text-sm">₦7500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#35791</td>
              <td className="py-2 px-4 text-sm">2023-08-19</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">579135791</td>
              <td className="py-2 px-4 text-sm">₦8500</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#68024</td>
              <td className="py-2 px-4 text-sm">2023-08-22</td>
              <td className="py-2 px-4 text-sm">PayPal</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">802468024</td>
              <td className="py-2 px-4 text-sm">₦12000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#80246</td>
              <td className="py-2 px-4 text-sm">2023-08-24</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">135791357</td>
              <td className="py-2 px-4 text-sm">₦8000</td>
              <td className="py-2 px-4 flex items-center">
                <span className="bg-yellow-500 text-xs text-white px-2 py-1 rounded-full">
                  Pending
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    } else if (selectedChoice === "cancelled") {
      return (
        <table className="min-w-full ">
          <thead
            className={`${darkMode ? "bg-[#212130] text-white" : "bg-white"}`}
          >
            <tr>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Tran ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Date
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Payment Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Product
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Type
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Recipient ID
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Amount
              </th>
              <th className="py-3 px-4 text-sm font-semibold text-left">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#24680</td>
              <td className="py-2 px-4 text-sm">2023-08-18</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">135792468</td>
              <td className="py-2 px-4 text-sm">₦5000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 text-xs text-white px-2 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>

            <tr className="border-t">
              <td className="py-2 px-4 text-sm">#57913</td>
              <td className="py-2 px-4 text-sm">2023-08-21</td>
              <td className="py-2 px-4 text-sm">Credit Card</td>
              <td className="py-2 px-4 text-sm">Energy</td>
              <td className="py-2 px-4 text-sm">Eko Disco</td>
              <td className="py-2 px-4 text-sm">357913579</td>
              <td className="py-2 px-4 text-sm">₦6000</td>
              <td className="py-2 px-4 flex">
                <span className="bg-red-500 text-xs text-white px-2 py-1 rounded-full">
                  failed
                </span>
                <div className="ml-auto">
                  <FaEllipsisV className="mt-1" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }
  };

  return (
    <div className="md:p-8 px-1 py-2">
      {showDetails &&
      <Details close={close} />}
      <div className="flex  justify-between items-center mb-8 md:pb-8">
        <div className="flex flex-col md:flex-row md:items-center">
          <h1 className=" mb-4 mt-6 md:mt-0 md:mr-16 md:text-4xl text-2xl font-bold">
            Transaction History
          </h1>
          <div className="flex mb-4 md:mb-0">
            <div className="relative mr-8">
              <input
                type="text"
                placeholder="Search..."
                className="md:p-3 p-2 rounded-full md:w-96 pr-10"
              />
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <VscSearch className="font-bold text-xl" />
              </div>
            </div>

            <div className="flex md:space-x-6 space-x-1 md:mr-12 justify-center items-center">
              <div className="w-8 h-8  rounded-full flex justify-center items-center">
                <BiBell className="text-2xl hover:text-green-400" />
              </div>

              <div className="w-8 h-8  items-center justify-center flex rounded-full">
                <AiFillSetting className="text-2xl hover:text-green-400" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row  md:justify-between">
        <div className="md:mr-4 mb-4 md:mb-0">
          <div
            className={`flex items-center justify-center mb-2 rounded-3xl md:px-3 py-2 ${
              darkMode ? "bg-[#212130] text-white" : "bg-white "
            }`}
          >
            <button
              onClick={(event) => handleSelectedChoice(event, "all")}
              className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                selectedChoice === "all" ? "bg-green-700 text-white" : ""
              }`}
            >
              All Transactions
            </button>
            <button
              onClick={(event) => handleSelectedChoice(event, "completed")}
              className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                selectedChoice === "completed" ? "bg-green-700 text-white" : ""
              }`}
            >
              Completed
            </button>
            <button
              onClick={(event) => handleSelectedChoice(event, "pending")}
              className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                selectedChoice === "pending" ? "bg-green-700 text-white" : ""
              }`}
            >
              Pending
            </button>
            <button
              onClick={(event) => handleSelectedChoice(event, "cancelled")}
              className={`md:px-4 px-2 py-1 md:py-2  text-xs rounded-lg hover:bg-green-500 hover:text-gray-200 ${
                selectedChoice === "cancelled" ? "bg-green-700 text-white" : ""
              }`}
            >
              Failed
            </button>
          </div>
        </div>
        <div className="md:justify-end justify-center md:items-center mb-4 flex md:w-1/2">
          <button
            className={`flex items-center text-xs px-4 py-2 md:py-3 rounded-3xl  mr-2 ${
              darkMode
                ? "bg-[#212130] text-white hover:bg-gray-700"
                : "bg-white hover:bg-gray-100"
            }`}
          >            <span className="flex-grow">
              Filter By Products
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2 text-green-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <button
          onClick={() => setOpenDate(!openDate)}
            className={`flex items-center text-xs px-4 md:py-3 py-2 rounded-3xl   ${
              darkMode
                ? "bg-[#212130] text-white hover:bg-gray-700"
                : "bg-white hover:bg-gray-100"
            }`}
          >
            <AiOutlineCalendar className="mr-2" />
            <span className="flex-grow" >
              Filter By Date
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 ml-2 text-green-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6.293 7.293a1 1 0 011.414 0L10 9.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {/* <CalendarComp /> */}
          {openDate && <DateRangeComp />}
        </div>
      </div>

      <div
        className={`w-[370px] overflow-y-auto max-h-[600px] md:w-full p-4 pb-12 shadow-md rounded-3xl ${
          darkMode ? "bg-[#212130] text-white" : "bg-white"
        }`}
      >
        <div className="overflow-auto">{renderTable()}</div>
        <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="flex justify-between">
          <div>
            <p>10 Rows per page</p>
          </div>

          <nav aria-label="Page navigation example">
            <ul class="flex items-center -space-x-px h-8 text-sm">
              <li>
                <a
                  href="#"
                  class="flex items-center justify-center px-3 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span class="sr-only">Previous</span>
                  <svg
                    class="w-2.5 h-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 1 1 5l4 4"
                    />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  1
                </a>
              </li>
              <li>
                <a
                  href="#"
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  2
                </a>
              </li>
              <li>
                <a
                  href="#"
                  aria-current="page"
                  class="z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                >
                  3
                </a>
              </li>

              <li>
                <a
                  href="#"
                  class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span class="sr-only">Next</span>
                  <svg
                    class="w-2.5 h-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 9 4-4-4-4"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default TransactionHistory;
