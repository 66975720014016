import React, { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import {
  IoArrowBackCircleOutline,
  IoCheckmarkCircleSharp,
} from "react-icons/io5";
import { Link } from "react-router-dom";

const TransactionCard = ({
  selectedAmount,
  phoneNumber,
  product,
  onClose,
  payment,
  transactionType,
  token,
  customerName
}) => {
  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
        <div className="card2 mx-12">
          <section>
            {/* <Link to="/personal">
                <button className="cancel-button">
                  <svg className="h-6 w-6 fill-current" viewBox="0 0 24 24">
                    <path
                      fillRule="evenodd"
                      d="M3.293 3.293a1 1 0 011.414 0L12 10.586l7.293-7.293a1 1 0 011.414 1.414L13.414 12l7.293 7.293a1 1 0 01-1.414 1.414L12 13.414l-7.293 7.293a1 1 0 01-1.414-1.414L10.586 12 3.293 4.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </Link> */}
            <div className="flex justify-center">
              <div className="w-[80px] h-[80px] rounded-full bg-green-100 flex justify-center items-center">
                <IoCheckmarkCircleSharp className="text-6xl text-green-700" />
              </div>
            </div>
            {/* <h1 className="text-center mt-2 mb-2"> {msg}</h1> */}
            <h1 className="text-center font-semibold mt-2 mb-2">
              Payment succesful
            </h1>
          </section>

          <section className="px-4 py-3 w-[350px]">
            {customerName && (
            <div className="mb-4 flex justify-between">
              <p className="text-gray-800 mb-2 mr-4">Customer Name: </p>
              <p className="text-gray-800  font-bold"> ******</p>
            </div>
            )} 
               {/* {transactionId && ( */}
               <div className="mb-4 flex justify-between">
              <p className="text-gray-800 mb-2 mr-4">Transaction ID: </p>
              <p className="text-gray-800  font-bold"> ******</p>
            </div>
            {/* )} */}
              {/* {date && ( */}
              <div className="mb-4 flex justify-between">
              <p className="text-gray-800 mb-2 mr-4">Date: </p>
              <p className="text-gray-800  font-bold"> *******</p>
            </div>
            {/* )} */}
               {/* {time && ( */}
               <div className="mb-4 flex justify-between">
              <p className="text-gray-800 mb-2 mr-4">Time: </p>
              <p className="text-gray-800  font-bold"> ******</p>
            </div>
            {/* )} */}
               {/* {paymentMethod && ( */}
               <div className="mb-4 flex justify-between">
              <p className="text-gray-800 mb-2 mr-4">Payment Method: </p>
              <p className="text-gray-800  font-bold"> *****</p>
            </div>
            {/* )} */}
               {/* {transactionType && (
               <div className="mb-4 flex justify-between">
              <p className="text-gray-800 mb-2 mr-4">Service: </p>
              <p className="text-gray-800  font-bold"> transactionType</p>
            </div>
             )} 
            */}

            {/* {serviceType && ( */}
            {/* <div className="mb-4 flex justify-between">
              <p className="text-gray-600 mb-2 mr-4">Service Provider: </p>
              <p className="text-gray-800  font-bold"> serviceType</p>
            </div> */}
            {/* )} */}

            {token && (
            <div className="mb-4 flex justify-between">
              <p className="text-gray-600 mb-2 mr-4">Token:</p>
              <p className="text-gray-800  font-bold">token</p>
            </div>
            )}

            {/* <div className="mb-4 flex justify-between">
              <p className="text-gray-600 mb-2 mr-2">Ref number:</p>
              <p className="text-gray-800  font-bold">ref</p>
            </div> */}
            <div className="border-gray-200 border-b-2 my-4 border-dotted"></div>
            {/* {selectedAmount && ( */}
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800 mb-2 mr-4">Amount: </p>
                <p className="text-gray-800  font-bold">
                  
                  ₦
                  {selectedAmount
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </p>
              </div>
            {/* )} */}
             {/* {product && ( */}
              <div className="mb-4 flex justify-between">
                <p className="text-gray-800 mb-2 mr-4">Product: </p>
                <p className="text-gray-800  font-bold">
                  ******
                </p>
              </div>
            {/* )} */}
          </section>
          <div className="flex justify-center">
            <Link to="/dash">
              <button
                type="button"
                className="px-4 w-64 py-2 bg-green-700 text-white rounded"
              >
                Dashboard
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionCard;
