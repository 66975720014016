import { useState, useEffect } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { InputField, SelectField } from "../../components/ui/input";
import {
  handleChange,
  handleNumberChange,
  handleKeyPress,
  ensureIsArray,
  handle400,
  handle422,
  handle500,
} from "../../lib/util";

const baseURL = process.env.REACT_APP_BASE_URL;
const serviceUrl = `${baseURL}/services?type=Bet`;
const verifyRequestUrl = `${baseURL}/bills-payment/bet/verify`;
const vendUrl = `${baseURL}/bills-payment/bet/vend`;

const PlaceBet = () => {
  const [formStep, setFormStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [customerSpinner, setCustomerSpinner] = useState(false);

  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};

    if (!formData.service) {
      errors.service = "Service provider is required";
    }

    if (!formData.customerId) {
      errors.customerId = "Customer ID number is required";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.amount) {
      errors.amount = "Amount is required";
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\d{11}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be 11 digits";
    }

    return errors;
  };

  const back = () => {
    setFormStep((currentStep) => currentStep - 1);
  };

  function resetProductTree() {
    setState((state) => {
      return { ...state, products: [] };
    });
  }

  const [formData, setFormData] = useState({
    serviceName: "",
    service: "",
    customerId: "",
    amount: "",
    parsedAmount: "",
    email: "",
    phoneNumber: "",
  });

  const [customerInformation, setCustomerInformation] = useState({
    customerName: "",
    minimumPayableAmount: "",
  });

  const [state, setState] = useState({
    providers: [],
  });

  const onSubmit = (e) => {
    e.preventDefault();

    setErrors({});
    const errors = validate();

    if (Object.keys(errors).length === 0) {
      setCustomerSpinner(true);
      axios
        .post(verifyRequestUrl, {
          service_id: formData.service,
          customer_id: formData.customerId,
        })
        .then((response) => {
          const customerData = response.data;
          const customerInformation = customerData.data.customer_information;
          const name = customerInformation.name;
          const minPayAmount = customerInformation.minimumPayableAmount;
          setCustomerInformation({
            ...customerInformation,
            customerName: name,
            minimumPayableAmount: minPayAmount,
          });
          setFormStep(1);
          setCustomerSpinner(false);
        })
        .catch((error) => {
          const r = error.response.data.message;
          toast.error(r, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: 0,
            theme: "light",
          });
          handle422(error, { formStep, back });
          handle400(error, { formStep, back });
          handle500(error, { formStep, back });
          setCustomerSpinner(false);
        });
    } else {
      setErrors(errors);
    }
  };

  useEffect(() => {
    axios
      .get(serviceUrl)
      .then((response) => {
        const providersData = response.data.data;
        setState((state) => {
          return { ...state, providers: ensureIsArray(providersData) };
        });
      })
      .catch((error) => {
        console.error(error);
        handle422(error, { formStep, back });
        handle400(error, { formStep, back });
        handle500(error, { formStep, back });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleServiceChange = (e) => {
    setErrors({});
    let serviceId = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const service = selectedOption.getAttribute("data-provider-name");
    resetProductTree();
    setFormData((previousData) => {
      return {
        ...previousData,
        service: serviceId,
        serviceName: service,
      };
    });
  };

  const submitToBackend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const serviceId = formData.service;
    axios
      .post(vendUrl, {
        service_id: serviceId,
        customer_id: formData.customerId,
        amount: formData.amount,
        email: formData.email,
      })
      .then((response) => {
        const redirectUrl = response.data.data.url;
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        handle422(error, { formStep, back });
        handle400(error, { formStep, back });
        handle500(error, { formStep, back });
      })
      .finally(() => {
        setIsLoading(false);
      });
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "place bet",
    });
  };

  const renderButton = () => {
    if (formStep === 0) {
      return (
        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            Submit
          </button>
        </div>
      );
    } else if (formStep === 1) {
      return (
        <div className="flex flex-col items-center">
          <button
            disabled={isLoading}
            onClick={submitToBackend}
            type="button"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              `Pay   ₦ ${formData.amount}`
            )}
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full sm:w-4/5 md:4/5 lg:w-2/5 mx-auto">
        <div className="w-[25rem] flex justify-around mt-8 mx-auto">
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "green" : formStep === 1 ? "green" : "green",
            }}
            onClick={() => setFormStep(0)}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "green" : "green",
            }}
            onClick={onSubmit}
            disabled={formStep !== 0}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "gray" : "green",
            }}
          ></button>
        </div>
        <div className="w-[25rem] flex justify-around mx-auto">
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Enter Information
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Make Payment
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            View Receipt
          </p>
        </div>
        <div className="w-[25rem] mx-auto px-16 mb-16 -mt-9">
          <div
            className="w-full h-[1px] bg-green-500"
            style={{
              width: formStep === 0 ? "0%" : formStep === 1 ? "50%" : "100%",
            }}
          ></div>
          <div className="w-full h-[1px] bg-gray-200"></div>
        </div>

        <div className="max-w-xl w-full mt-4 mb-4 rounded-lg bg-white mx-auto overflow-hidden z-10">
          <form onSubmit={onSubmit}>
            {formStep === 0 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">Bet</h2>
                <p className="text-black font-semibold text-sm text-center mb-6 mt-2">
                  Place bet here
                </p>

                <div>
                  <SelectField
                    label="Service Provider"
                    id="service"
                    name="service"
                    placeholder="Service Provider"
                    onChange={handleServiceChange}
                    value={formData.service}
                  >
                    <option disabled value="">
                      Select provider
                    </option>
                    {state.providers.map((provider) => (
                      <option
                        key={provider.id}
                        data-provider-name={provider.provider}
                        value={provider.id}
                      >
                        {provider.provider}
                      </option>
                    ))}
                  </SelectField>
                  {errors.service && (
                    <span className="text-red-500 text-sm">
                      {errors.service}
                    </span>
                  )}
                </div>

                <div className="flex gap-2">
                  <div className="w-1/2">
                    <InputField
                      label="Amount"
                      type="text"
                      id="amount"
                      name="amount"
                      className="w-full outline-none text-xs"
                      onKeyUp={(e) => handleKeyPress(e, setFormData)}
                      onChange={(e) => handleChange(e, setErrors, setFormData)}
                      value={formData.amount}
                    />
                    {errors.amount && (
                      <span className="text-red-500 text-sm">
                        {errors.amount}
                      </span>
                    )}
                  </div>
                  <div className="w-1/2">
                    <InputField
                      label="Phone Number"
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="w-full border border-gray-300 p-3 rounded-lg text-xs"
                      value={formData.phoneNumber}
                      onChange={(e) =>
                        handleNumberChange(e, setErrors, setFormData)
                      }
                      maxLength={11}
                    />
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>

                <div className="mb-3">
                  <label
                    className="block text-gray-500 text-xs"
                    htmlFor="customerId"
                  >
                    Customer ID
                  </label>
                  <div className="">
                    <input
                      type="text"
                      id="customerId"
                      name="customerId"
                      className="w-full border border-gray-300 p-3 rounded-lg text-xs"
                      value={formData.customerId}
                      onChange={(e) => {
                        setFormData((previousData) => {
                          return {
                            ...previousData,
                            customerId: e.target.value,
                          };
                        });
                        setErrors({});
                      }}
                    />
                  </div>
                  {errors.customerId && (
                    <span className="text-red-500 text-sm">
                      {errors.customerId}
                    </span>
                  )}
                </div>

                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-xs"
                    htmlFor="email"
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    className="w-full border text-xs border-gray-300 p-3 rounded-lg"
                    value={formData.email}
                    onChange={(e) => {
                      setFormData((previousData) => {
                        return { ...previousData, email: e.target.value };
                      });
                      setErrors({});
                    }}
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">{errors.email}</span>
                  )}
                </div>
              </section>
            )}

            {customerSpinner && (
              <div className="fixed inset-0 z-50 flex justify-center items-center bg-gray-900 bg-opacity-70">
                <FontAwesomeIcon
                  icon={faSpinner}
                  size="3x"
                  spin
                  className="text-white"
                />
              </div>
            )}

            {formStep === 1 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Review Information
                </h2>
                <p className="text-black font-semibold text-sm text-center mt-2">
                  Review information and make payment
                </p>
                <div className="bg-white rounded mx-auto p-1 w-full h-full flex items-center align-items-center">
                  <div className="border bg-gray-100 mx-auto border-green-400 rounded-xl shadow-md px-12 mt-8 py-6">
                    <div className="text-center">
                      <p className="font-bold text-xs mt-2">NAME:</p>
                      <p className="text-xs">
                        {customerInformation.customerName}
                      </p>
                      <p className="font-bold text-xs mt-2">
                        SERVICE PROVIDER:
                      </p>
                      <p className="text-xs">{formData.serviceName}</p>
                      <p className="font-bold text-xs mt-2">CUSTOMER ID:</p>
                      <p className="text-xs">{formData.customerId}</p>
                      <p className="font-bold text-xs mt-2">AMOUNT:</p>
                      <p className="text-xs">₦ {formData.amount}</p>
                      <p className="font-bold text-xs mt-2">EMAIL:</p>
                      <p className="text-xs">{formData.email}</p>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {renderButton()}
          </form>
        </div>
      </div>
    </>
  );
};

export default PlaceBet;
