import { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga4";
import { InputField, SelectField } from "../../components/ui/input";
import {
  handleChange,
  handleNumberChange,
  ensureIsArray,
  handle400,
  handle422,
  handle500,
} from "../../lib/util";

const baseURL = process.env.REACT_APP_BASE_URL;

const serviceUrl = `${baseURL}/services?type=Data`;
const vendUrl = `${baseURL}/bills-payment/data/vend`;

const BuyData = () => {
  const [formStep, setFormStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [bundleLoading, setBundleLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    networkProvider: "",
    networkName: "",
    product: "",
    productName: "",
    productPrice: "",
    phoneNumber: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const validate = () => {
    let errors = {};

    if (!formData.networkProvider) {
      errors.networkProvider = "Provider is required";
    }

    if (!formData.product) {
      errors.product = "Bundle is required";
    }

    if (!formData.phoneNumber) {
      errors.phoneNumber = "Phone number is required";
    } else if (!/^\d{11}$/.test(formData.phoneNumber)) {
      errors.phoneNumber = "Phone number must be 11 digits";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }

    if (!formData.productPrice) {
      errors.productPrice = "Amount is required";
    }

    return errors;
  };

  function formatNumber(number) {
    return parseInt(number).toLocaleString();
  }

  const back = () => {
    setFormStep((currentStep) => currentStep - 1);
  };

  function resetProductTree() {
    setState((state) => {
      return { ...state, products: [] };
    });
  }

  const [state, setState] = useState({
    providers: [],
    products: [],
    productPrice: "",
  });

  useEffect(() => {
    axios
      .get(serviceUrl)
      .then((response) => {
        const providersData = response.data.data;
        setState((state) => {
          return { ...state, providers: ensureIsArray(providersData) };
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleServiceChange = (e) => {
    setErrors({});
    const serviceId = e.target.value;
    const selectedOption = e.target.options[e.target.selectedIndex];
    const serviceName = selectedOption.getAttribute("data-provider-name");
    resetProductTree();
    setFormData((prevData) => ({
      ...prevData,
      networkProvider: serviceId,
      networkName: serviceName,
    }));
    setBundleLoading(true);

    axios
      .get(`${baseURL}/services/${serviceId}/products`)
      .then((response) => {
        let data = response.data;
        if (data.data) {
          if (data.status === "success") {
            setState((state) => {
              return { ...state, products: ensureIsArray(data.data) };
            });
            setBundleLoading(false);
          }
          return;
        }
        console.log("An error occured while fetching product.");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleProductChange = (e) => {
    setErrors({});
    const productId = e.target.value;

    let product = state.products.find((product) => {
      return product.id === productId;
    });

    if (product) {
      setFormData((prevData) => ({
        ...prevData,
        product: productId,
        productPrice: product.amount,
      }));
    } else {
      console.log(`Product with ID ${productId} not found`);
    }
  };

  const renderButton = () => {
    if (formStep === 0) {
      return (
        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            Submit
          </button>
        </div>
      );
    } else if (formStep === 1) {
      return (
        <div className="flex flex-col items-center">
          <button
            disabled={isLoading}
            onClick={submitToBackend}
            type="button"
            className="mt-2 px-4 py-2 w-3/4 disabled:bg-gray-400 disabled:cursor-not-allowed bg-green-700 hover:bg-green-600 rounded-sm text-white font-bold text-xl"
          >
            {isLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              `Pay   ₦ ${formatNumber(formData.productPrice)}`
            )}
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);

    const errors = validate();

    if (Object.keys(errors).length === 0) {
      setFormStep(1);
    } else {
      setErrors(errors);
    }
  };

  const submitToBackend = (e) => {
    e.preventDefault();
    setIsLoading(true);

    axios
      .post(`${vendUrl}`, {
        recipient_number: formData.phoneNumber,
        email: formData.email,
        service_id: formData.networkProvider,
        product_id: formData.product,
        amount: formData.productPrice,
      })
      .then((response) => {
        const redirectUrl = response.data.data.url;
        window.location.href = redirectUrl;
      })
      .catch((error) => {
        handle422(error, { formStep, back });
        handle400(error, { formStep, back });
        handle500(error, { formStep, back });
      })
      .finally(() => {
        setIsLoading(false);
      });
    ReactGA.event({
      category: "Form",
      action: "Submit",
      label: "buy data",
    });
  };

  return (
    <>
      <ToastContainer />
      <div className="w-full sm:w-4/5 md:4/5 lg:w-2/5 mx-auto">
        <div className="w-[25rem] flex justify-around mt-8 mx-auto">
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "green" : formStep === 1 ? "green" : "green",
            }}
            onClick={() => setFormStep(0)}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "green" : "green",
            }}
            onClick={onSubmit}
            disabled={formStep !== 0 || isButtonDisabled}
          ></button>
          <button
            className="w-6 h-6 bg-gray-300 rounded-full text-white transition duration-300 hover:bg-green-500 disabled:bg-grey-500"
            style={{
              backgroundColor:
                formStep === 0 ? "gray" : formStep === 1 ? "gray" : "green",
            }}
          ></button>
        </div>

        <div className="w-[25rem] flex justify-around mx-auto">
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Enter Information
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            Make Payment
          </p>
          <p className=" text-gray-500 text-xs rounded-full transition duration-300 mt-2">
            View Receipt
          </p>
        </div>
        <div className="w-[25rem] mx-auto px-16 mb-16 -mt-9">
          <div
            className="w-full h-[1px] bg-green-500"
            style={{
              width: formStep === 0 ? "0%" : formStep === 1 ? "50%" : "100%",
            }}
          ></div>
          <div className="w-full h-[1px] bg-gray-200"></div>
        </div>

        <div className="max-w-xl w-full mb-4 rounded-lg bg-white mx-auto overflow-hidden z-10">
          <form onSubmit={onSubmit}>
            {formStep === 0 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Buy Data
                </h2>
                <p className="text-black font-semibold text-sm text-center mb-6 mt-2">
                  Renew your data subscription here
                </p>

                <div>
                  <SelectField
                    label="Network Provider"
                    id="networkProvider"
                    name="networkProvider"
                    value={formData.networkProvider}
                    onChange={handleServiceChange}
                  >
                    <option disabled value="">
                      Select a provider
                    </option>
                    {state.providers.map((provider) => (
                      <option
                        key={provider.id}
                        data-provider-name={provider.provider}
                        value={provider.id}
                      >
                        {provider.provider}
                      </option>
                    ))}
                  </SelectField>
                  {bundleLoading && (
                    <div className="absolute right-8 top-7 text-black">
                      <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                  )}
                  {errors.networkProvider && (
                    <span className="text-red-500 text-sm">
                      {errors.networkProvider}
                    </span>
                  )}
                </div>

                <div>
                  <SelectField
                    label="Bundle"
                    id="product"
                    name="product"
                    onChange={handleProductChange}
                  >
                    {" "}
                    <option>Select bundle</option>
                    {state.products.length > 0 &&
                      state.products.map((product, index) => (
                        <option key={product.id + index} value={product.id}>
                          {product.description}
                        </option>
                      ))}
                  </SelectField>
                  {errors.product && (
                    <span className="text-red-500 text-sm">
                      {errors.product}
                    </span>
                  )}
                </div>

                <div className="flex gap-2">
                  <div className="w-1/2">
                    <InputField
                      label="Phone number"
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={(e) =>
                        handleNumberChange(e, setErrors, setFormData)
                      }
                      maxLength={11}
                    />
                    {errors.phoneNumber && (
                      <span className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </span>
                    )}
                  </div>

                  <div className="w-1/2">
                    <InputField
                      label="Amount"
                      value={
                        formData.productPrice
                          ? `₦${formatNumber(formData.productPrice)}`
                          : "-"
                      }
                    />
                    {errors.productPrice && (
                      <span className="text-red-500 text-sm">
                        {errors.productPrice}
                      </span>
                    )}
                  </div>
                </div>

                <div className="">
                  <InputField
                    label="Email Address"
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e, setErrors, setFormData)}
                    placeholder="Email Address"
                  />
                  {errors.email && (
                    <span className="text-red-500 text-sm">{errors.email}</span>
                  )}
                </div>
              </section>
            )}

            {formStep === 1 && (
              <section>
                <h2 className="font-bold text-center text-2xl mb-3">
                  Review Information
                </h2>
                <p className="text-black font-semibold text-sm text-center mt-2">
                  Review information and make payment
                </p>
                <div className="rounded mx-auto py-1 px-8 w-full h-full flex items-center align-items-center">
                  <div className="border mx-auto border-green-400 rounded-xl shadow-md px-24 py-6">
                    <div className="text-center">
                      <p className="font-bold text-xs mt-2">PHONE NUMBER:</p>
                      <p className="text-xs">{formData.phoneNumber}</p>
                      <p className="font-bold text-xs mt-2">
                        NETWORK PROVIDER:
                      </p>
                      <p className="text-xs">{formData.networkName}</p>
                      <p className="font-bold text-xs mt-2">AMOUNT:</p>
                      <p className="text-xs">
                        ₦ {formatNumber(formData.productPrice)}
                      </p>
                      <p className="font-bold text-xs mt-2">EMAIL:</p>
                      <p className="text-xs">{formData.email}</p>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {renderButton()}
          </form>
        </div>
      </div>
    </>
  );
};

export default BuyData;
