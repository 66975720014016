import React, { useState } from "react";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "./calendar.css";
import { addDays } from "date-fns";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file;

const DateRangeComp = () => {
  const [range, setRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [open, setOpen] = useState(false);

  const handleRangeChange = (newRange) => {
    setRange([newRange.selection]);
    console.log(
      `${format(newRange.selection.startDate, "dd/MM/yyyy")} - ${format(
        newRange.selection.endDate,
        "dd/MM/yyyy"
      )}`
    );
  };

  return (
    <div className="calendarWrap">
      {/* <input
        value={`${format(range[0].startDate, "dd/MM/yyyy")} - ${format(
          range[0].endDate,
          "dd/MM/yyyy"
        )}`}
        readOnly
        className="inputBox"
        onClick={() => setOpen(!open)}
      /> */}
      <DateRange
        ranges={range}
        onChange={handleRangeChange}
        editableDateInputs={true}
        moveRangeOnFirstSelection={false}
        months={2}
        direction="horizontal"
        className="calendarElement"
      />
    </div>
  );
};

export default DateRangeComp;
