import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/billerHQ-04.png";
import { useModal } from "../ModalContext";
import Sidenav from "./Sidenav";

const Nav = ({ toggleLogin, toggleLogin2, toggleSignup }) => {
  let location = useLocation();
  let currentLocation = location.pathname;
  currentLocation = currentLocation.slice(1);
  const {dispatch } = useModal();


  const [navOpen, setNavOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(currentLocation);

  const handleNav = () => setNavOpen(!navOpen);

  return (
    <>
      <nav className="bg-[#ffffff] max-w-[2140] top-6 left-0 h-12 z-40 flex items-center border-b-2 justify-between px-8">
        <Link
          to="/"
          className="px-2 py-2 flex items-center"
          onClick={() => {
            setActiveTab("");
          }}
        >
          <img src={Logo} alt="Logo" className="h-8 sm:ml-6" />
        </Link>
        <div className="hidden md:flex">
          <Link
            to="/personal"
            className={`px-4 py-1 text-black hover:text-green-600 font-medium rounded-md ${
              activeTab === "personal" ? "bg-green-100" : ""
            }`}
            onClick={() => {
              setActiveTab("personal");
            }}
          >
            Personal
          </Link>
          <Link
            to="/business"
            className={`px-4 py-1 text-black hover:text-green-600 font-medium rounded-md ${
              activeTab === "business" ? "bg-green-100" : ""
            }`}
            onClick={() => {
              setActiveTab("business");
            }}
          >
            Business
          </Link>
        </div>
        <button
          className="px-4 py-2 text-green-700 font-medium block md:hidden"
          onClick={handleNav}
        >
          {navOpen ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={30} />}
        </button>
        <div className="hidden md:flex md:mr-4">
          {/* <Link
            to="/personal"
            className="bg-green-700 hover:bg-green-500 w-[100px] rounded-lg text-white px-2 my-2 mx-2 py-1 $"
            onClick={() => {
              setActiveTab("personal");
            }}
          >
            Get Started
          </Link> */}
          

          <button
            className="border border-green-500 text-green-700 px-4 py-1 rounded hover:bg-green-500 hover:text-white mr-4 $"
            onClick={() => dispatch({type: 'Modal', isLoginModal: true})}
          >
            Login
          </button>
          <button
            className="bg-green-700 text-white px-4 py-1 rounded hover:bg-green-500 $"
            onClick={() => dispatch({type: 'Modal', isSignUpModal: true})}
          >
            Sign Up
          </button>
        </div>
      </nav>

      <div
        className={`fixed top-10 left-0 bg-white w-[100%] h-screen overflow-y-auto ${
          navOpen ? "block" : "hidden"
        }`}
      >
        <div className="px-2 py-6 w-1/2 z-40">
          {/* <Link to="/" className="px-4 py-2 flex items-center">
          <img src={Logo} alt="Logo" className="h-8 ml-6"  onClick={handleNav} />
        </Link> */}
          <Link
            to="/personal"
            className={`block p-2 text-black font-medium rounded-md ${
              activeTab === "personal" ? "bg-green-200" : ""
            }`}
            onClick={() => {
              setActiveTab("personal");
              handleNav();
            }}
          >
            Personal
          </Link>
          <Link
            to="/business"
            className={`block p-2 text-black font-medium rounded-md ${
              activeTab === "business" ? "bg-green-200" : ""
            }`}
            onClick={() => {
              setActiveTab("business");
              handleNav();
            }}
          >
            Business
          </Link>

          <div className="">
            {/* <Link
              to="/personal"
              className={`block p-2 text-black font-medium rounded-md text-center bg-green-500 hover:bg-green-700 w-full] px-2 my-2 mx-2 py-2 ${
                activeTab === "business" ? "bg-green-200" : ""
              }`}
              onClick={() => {
                setActiveTab("personal");
                handleNav();
              }}
            >
              Get Started
            </Link> */}
            <button
              className={`block border border-green-500 text-green-500 px-4 py-2 w-full rounded hover:bg-green-500 hover:text-white mb-4 ${
                activeTab === "login" ? "bg-green-200" : ""
              }`}
              onClick={() => {
                setActiveTab("login");
                dispatch({type: 'Modal', isLoginModal: true})  ;
                handleNav();
              }}
            >
              Login
            </button>
            <button
              className={`block border border-green-500 text-green-500 px-4 py-2 w-full rounded hover:bg-green-500 hover:text-white mb-4 ${
                activeTab === "login" ? "bg-green-200" : ""
              }`}
              onClick={() => {
                setActiveTab("signup");
                dispatch({type: 'Modal', isSignUpModal: true});
                handleNav();
              }}
            >
              Sign Up
            </button>
          </div>
          <Sidenav />

        </div>
      </div>

    </>
  );
};

export default Nav;
