import React from "react";
import { AiFillSetting } from "react-icons/ai";
import { BiBell } from "react-icons/bi";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { VscSearch } from "react-icons/vsc";
import NoAccount from "./NoAccount";
import { useState } from "react";
import CreateVr from "./CreateVr";
import VrSuccess from "./VrSuccess";
import ShareCard from "./ShareCard";
import { Navigate } from "react-router-dom";
import CreateVrCable from "./CreateVrCable";

const VirtualCable = ({ darkMode }) => {
  const [vrAccount, setVrAccount] = useState(true);
  const [createAccount, setCreateAccount] = useState(false);
  const [createSuccess, setCreateSuccess] = useState(false);

  const collectDetails = () => {
    setVrAccount(false);
    setCreateAccount(true);
  };

  const [disco, setDisco] = useState("");
  const [meterNumber, setMeterNumber] = useState("");
  const [meterType, setMeterType] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [bvn, setbvn] = useState("");
  const [meterName, setMeterName] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [bank, setBank] = useState("");

  const [shareDetails, setShareDetails] = useState(false);
  const [energyTransactionReview, setEnergyTransactionReview] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  const handleDiscoChange = (event) => {
    setDisco(event.target.value);
  };

  const handleMeterNumberChange = (event) => {
    setMeterNumber(event.target.value);
  };

  const handleMeterTypeChange = (event) => {
    setMeterType(event.target.value);
  };

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleMeterNameChange = (event) => {
    setMeterName(event.target.value);
  };

  const handlebvnChange = (event) => {
    setbvn(event.target.value);
  };

  const handleDobChange = (event) => {
    setDob(event.target.value);
  };

  const handleBankChange = (event) => {
    setBank(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setCreateAccount(false);
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
      setCreateSuccess(true);
    }, 3000);

    const formData = {
      disco,
      meterNumber,
      meterType,
      amount,
      phoneNumber,
      bvn,
      meterName,
      address,
      dob,
      bank,
    };
    console.log(formData);
  };

  const handleShare = (info) => {
    // setInfo(info);
    setShareDetails(true);
    console.log("hi");
  };

  const handleBack = () => {
    if (createSuccess) {
      setCreateSuccess(false);
      setCreateAccount(true);
    } else if (createAccount) {
      setCreateAccount(false);
      setVrAccount(true);
    } else {
      // <Navigate to="/dashboard" />;
      window.location.href='/dash';
    }
  };

  return (
    <div className="">
      {screenLoading && <div className="loading"></div>}

      <div className="flex-1 pt-4 pb-1 md:px-[3rem] mx-4">
        
        <div className="flex justify-between mt-6 md:mt-0">
          <div>
            <h1 className=" mb-4  md:text-3xl text-2xl font-bold">
              Virtual Account
            </h1>
          </div>

          <div className="flex md:space-x-6 space-x-1 md:mr-12 justify-center items-center">
            <div className="w-8 h-8  rounded-full flex justify-center items-center">
              <BiBell className="text-2xl hover:text-green-400" />
            </div>

            <div className="w-8 h-8  items-center justify-center flex rounded-full">
              <AiFillSetting className="text-2xl hover:text-green-400" />
            </div>
          </div>
        </div>
        <div className="my-6">
          {!createSuccess && (
            <IoArrowBackCircleOutline
              onClick={handleBack}
              className="md:text-5xl text-3xl text-green-400 mb-6 cursor-pointer"
            />
          )}{" "}
        </div>
        {vrAccount && (
          <NoAccount darkMode={darkMode} collectDetails={collectDetails} />
        )}
        {createAccount && (
          <CreateVrCable
            darkMode={darkMode}
            collectDetails={collectDetails}
            handleSubmit={handleSubmit}
          />
        )}
        {createSuccess && (
          <VrSuccess
            darkMode={darkMode}
            handleShare={() => setShareDetails(true)}
          />
        )}

        {shareDetails && (
          <ShareCard handleShare={() => setShareDetails(false)} />
        )}
      </div>
    </div>
  );
};

export default VirtualCable;
