import React from "react";

const NoAccount = ({ darkMode, collectDetails }) => {
  return (
    <div
      className={`p-4 md:px-4 shadow-md rounded-3xl ${
        darkMode ? "bg-[#212130] text-white" : "bg-white"
      }`}
    >
      <div className="flex flex-col">
        <div className="flex justify-center my-6">
          <svg
            width="127"
            height="127"
            viewBox="0 0 127 127"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M83.69 56.7694C84.96 53.9754 85.722 50.9274 85.722 47.6254C85.722 35.3699 75.7525 25.4004 63.497 25.4004C60.195 25.4004 57.147 26.1624 54.353 27.4324L83.69 56.7694Z"
              fill="#D50000"
            />
            <path
              d="M63.5 0C28.448 0 0 28.448 0 63.5C0 98.552 28.448 127 63.5 127C98.552 127 127 98.552 127 63.5C127 28.448 98.552 0 63.5 0ZM63.5 82.55C48.768 82.55 35.2425 87.63 24.511 96.012C16.8714 86.9018 12.6892 75.3894 12.7 63.5C12.7 51.7525 16.7005 40.9575 23.4315 32.385L41.5925 50.546C42.2231 55.3605 44.4262 59.8319 47.8597 63.2653C51.2931 66.6988 55.7645 68.9019 60.579 69.5325L74.549 83.5025C70.9295 82.8675 67.2465 82.55 63.5 82.55ZM103.568 94.615L32.385 23.4315C41.2551 16.4577 52.2168 12.6771 63.5 12.7C91.567 12.7 114.3 35.433 114.3 63.5C114.3 75.2475 110.299 85.979 103.568 94.615Z"
              fill="#D50000"
            />
          </svg>
        </div>

        <p className="text-xl font-semibold text-center my-4">
          You Don’t Have a Virtual Account!
        </p>
        <p className="text-sm text-center my-4">
          Money Transfers sent to this bank account number will automatically
          top up your Energy unit.
        </p>
        <div className="mb-1 flex justify-center my-4">
          <button
            onClick={collectDetails}
            type="button"
            className="w-42 text-sm bg-green-700 py-3 px-2 mt-2 rounded-lg text-white"
          >
            Create Account
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoAccount;
